/**
 * sharedb client code
 */
import { NCBoard, UserFieldName } from '../types';
import ReconnectingWebSocket from 'reconnecting-websocket';
import * as sharedb from 'sharedb/lib/client';
import { Graph, InspireGraph, IdeateGraph, MoodboardGraph } from './graphs';
import { startLoading, finishLoading, closeAllPanels, handleHashRoute } from './ui/uiUtils';
// @ts-expect-error types not available
import richText from 'rich-text';
import { Socket, Type } from 'sharedb/lib/sharedb';
import { openHeartedList } from './ui/ideaBox';
import { isNotepadOpen, openNotepad } from './ui/notepad';
import { getAllUserSelections, getLabels } from './graphs/graphUtils';
import {
	extractGraphData,
	getGraphName,
	getInspireDataForIdeate,
	makeSaveClustersFunctions,
	makeSaveGraphFn,
	makeSaveGroupsFunctions,
	makeSaveLinksFunctions,
	makeSaveNodesFunctions,
	makeSaveSingleUserFn,
	makeSaveUserAnalyticsFn,
} from './shareDbUtils';
import { AvailableColour, GraphMode, UserSelection } from '../../../src/commonTypes';
import { setActiveStage, setStageCompletionStatuses } from './ui/toolsUI';
import { closeAllMenus } from './ui/menuUi';
import { makeOpListener } from './shareDbListener';

// Global variables
globalThis.neuroCreate = {
	graph: undefined,
	saveUsers: undefined,
	saveSingleUser: undefined,
	saveGraph: undefined,
	saveNodes: undefined,
	saveLinks: undefined,
	saveGroups: undefined,
	saveClusters: undefined,
	saveUserAnalytics: undefined,
	switchToMode: undefined,
	doc: undefined,
	gapiLoaded: () => {
		console.log('will be replaced by slides.ts');
	},
	gisLoaded: () => {
		console.log('will be replaced by slides.ts');
	},
	automateTutorial: false,
};

const getColourForCurrentUser = (): [colourClass: AvailableColour, hex: string] => {
	const classes = Array.from(
		document.getElementById('current-user-icon')!.classList.values()
	).filter((c) => c !== 'header-menu-icon');
	const colourClass = classes[0] as AvailableColour;
	return [colourClass, getComputedStyle(document.body).getPropertyValue(`--${colourClass}`).trim()];
};

if (document.getElementById('top-menu-wrapper')) {
	sharedb.types.register((richText as { type: Type }).type);

	const authToken = document.body.dataset.authToken as string;

	/**
	 * Open WebSocket connection to ShareDB server
	 */
	const socket: ReconnectingWebSocket = new ReconnectingWebSocket(
		`${window.location.protocol === 'https:' ? 'wss:' : 'ws:'}//${
			window.location.host
		}?token=${authToken}`
	);
	const connection = new sharedb.Connection(socket as Socket);
	connection.on('receive', function (request) {
		// Custom message passing
		// @see https://github.com/share/sharedb/issues/104#issuecomment-240596912
		const data = request.data as { loggedOut?: boolean };
		if (data.loggedOut) {
			console.log('Logged out or token expired, reloading page');
			window.location.reload();
		}
	});
	connection.on('connected', () => {
		finishLoading();
	});

	let isReconnecting = true,
		isConnectionLost = false;
	socket.addEventListener('close', (closeEvent) => {
		console.log('[socket close]', closeEvent);
		if (isReconnecting) {
			isConnectionLost = true;
			setTimeout(() => {
				if (isConnectionLost) {
					startLoading('Lost connection. Attempting to reconnect...');
				}
			}, 5000);
		}
	});
	socket.addEventListener('open', (event) => {
		console.log('[socket open]', event);
		isConnectionLost = false;
		startLoading('Connecting...');
	});
	socket.addEventListener('error', (err) => {
		console.log('[socket error]', err);
	});
	// socket.addEventListener('message', (event) => {
	// 	console.log(`[socket message]`, event);
	// });

	window.onbeforeunload = () => {
		// Ensure we do not show "Lost connection" message when navigating away
		isReconnecting = false;
	};

	// Loading indicator within notepad
	const notePadLoadingIndicator = document.querySelector('#notepad-container .loading')!;
	/**
	 * Create local Doc instances mapped to collection documents in database
	 */
	let doc: sharedb.Doc<NCBoard>, notesDoc: undefined | sharedb.Doc;

	const loadMode = (
		graphMode: GraphMode,
		isCollaborative = true,
		automation = false
	): Promise<void> => {
		return new Promise((resolve) => {
			const { boardId, projectionName, userId } = document.body.dataset;
			if (!boardId || !projectionName || !userId) {
				return;
			}

			doc = connection.get(projectionName, boardId) as sharedb.Doc<NCBoard>;
			globalThis.neuroCreate.doc = doc;
			startLoading(`Loading ${getGraphName(graphMode)}`);

			if (globalThis.neuroCreate.graph) {
				globalThis.neuroCreate.graph.cleanUp();
			}

			const userFieldName = projectionName.replace('boardsUser', 'user') as UserFieldName;
			/**
			 * Get initial value of document and subscribe to changes.
			 * Set up graph with document data.
			 */
			doc.subscribe(function (err) {
				if (err) {
					throw err;
				}

				finishLoading();

				globalThis.neuroCreate.saveSingleUser = makeSaveSingleUserFn(doc);
				const graphData = extractGraphData(doc, userFieldName, graphMode, isCollaborative);
				const [colourClass, colourHex] = getColourForCurrentUser();

				console.log(`Loading ${graphMode} graph with following data...`, graphData);

				const boardUsers = doc.data.users;

				let userSelections: Array<UserSelection>;
				if (isCollaborative && graphMode === 'ideate') {
					userSelections = getAllUserSelections(doc.data);
				} else {
					userSelections = doc.data[userFieldName]?.userSelections || [];
				}

				let graph: Graph;
				notesDoc = undefined;
				if (graphMode === 'inspire') {
					graph = new InspireGraph(
						graphData,
						boardUsers,
						userSelections,
						colourClass,
						colourHex,
						automation
					);
				} else if (graphMode === 'ideate') {
					const { inspireNodesToAnalyse, activeAnalysis } = getInspireDataForIdeate(
						doc,
						userFieldName,
						isCollaborative,
						colourClass,
						colourHex
					);
					graph = new IdeateGraph(
						graphData,
						boardUsers,
						userSelections,
						colourClass,
						getLabels(inspireNodesToAnalyse),
						activeAnalysis,
						isCollaborative,
						automation
					);
				} else {
					notesDoc = isCollaborative
						? connection.get('teamNotes', boardId)
						: connection.get('personalNotes', `${boardId}_${userId}`);
					graph = new MoodboardGraph(
						graphData,
						boardUsers,
						userSelections,
						colourClass,
						colourHex,
						isCollaborative,
						automation,
						notesDoc
					);
				}
				globalThis.neuroCreate.graph = graph;

				// Hide loading indicator when notepad is ready
				setTimeout(() => {
					notePadLoadingIndicator.classList.add('hidden');
				}, 500);

				setStageCompletionStatuses();

				// Update graph when new data comes in from ShareDB
				// We process all ops from a batch at once to avoid unnecessary re-renders
				// (if we process ops separately there may be as many re-renders as there are nodes on the board)
				doc.on('op batch', makeOpListener(graph, userFieldName, isCollaborative));
				doc.on('load', () => {
					console.log('[ShareDB] Document loaded');
				});
				doc.on('create', () => {
					console.log('[ShareDB] Document create');
				});
				doc.on('del', () => {
					console.log('[ShareDB] Document deleted');
				});
				doc.on('error', (error) => {
					console.log('[ShareDB] error', error);
				});

				globalThis.neuroCreate.saveGraph = makeSaveGraphFn(doc, userFieldName, isCollaborative);
				globalThis.neuroCreate.saveNodes = makeSaveNodesFunctions(
					doc,
					userFieldName,
					isCollaborative,
					graphMode
				);
				globalThis.neuroCreate.saveLinks = makeSaveLinksFunctions(
					doc,
					userFieldName,
					isCollaborative,
					graphMode
				);
				globalThis.neuroCreate.saveGroups = makeSaveGroupsFunctions(
					doc,
					userFieldName,
					isCollaborative,
					graphMode
				);
				globalThis.neuroCreate.saveClusters = makeSaveClustersFunctions(
					doc,
					userFieldName,
					isCollaborative,
					graphMode
				);
				globalThis.neuroCreate.saveUserAnalytics = makeSaveUserAnalyticsFn(doc, userFieldName);

				if (notesDoc) {
					notesDoc.once('load', () => {
						resolve();
					});
				} else {
					resolve();
				}
			});
		});
	};

	const synthesiseSwitch = document.querySelector<HTMLDivElement>('#synthesise-switch')!;
	synthesiseSwitch.querySelectorAll<HTMLInputElement>('input').forEach((radio) => {
		radio.onchange = (event: Event) => {
			if (globalThis.neuroCreate.graph) {
				const graphMode = globalThis.neuroCreate.graph.graphMode;
				const isCollaborative = (event.target as HTMLInputElement).value === 'team';
				if (graphMode === 'ideate') {
					window.location.hash = isCollaborative ? '#ideate-team' : '#ideate';
				} else if (graphMode === 'moodboard') {
					// Show loading indicator when user switches team or personal notepad
					notePadLoadingIndicator.classList.remove('hidden');
					window.location.hash = isCollaborative ? '#synthesise-team' : '#synthesise';
				}
			} else {
				event.preventDefault();
			}
		};
	});

	// const modeSwitcherSelect = document.querySelector<HTMLSelectElement>(
	// 	'#board-page-switcher select'
	// )!;

	/**
	 * Switch to a specific mode, either for personal or team
	 */
	globalThis.neuroCreate.switchToMode = (
		graphMode: GraphMode,
		isCollaborative: boolean,
		automation: boolean,
		afterLoad: (() => void | Promise<void>) | null = null
	): void => {
		// Before graph loads:
		// modeSwitcherSelect.value = graphMode;
		setActiveStage(graphMode);
		closeAllMenus();

		if (graphMode !== 'ideate') {
			document.querySelector('#analyses-container')?.classList.add('hidden');
		} else {
			document.querySelector<HTMLInputElement>('input#ideate-use-ai-results')!.checked = true;
		}

		const inspireProgress = document.querySelector('#inspire-progress')!;
		if (graphMode === 'moodboard' || graphMode === 'ideate') {
			synthesiseSwitch.classList.remove('hidden');
			synthesiseSwitch.querySelectorAll('input')[isCollaborative ? 1 : 0].checked = true;
			inspireProgress.classList.add('hidden');
		} else {
			synthesiseSwitch.classList.add('hidden');
			inspireProgress.classList.remove('hidden');
		}

		const generatePanel = document.querySelector<HTMLDivElement>('#generate-container');
		const searchPanel = document.querySelector<HTMLDivElement>('#search-container');
		if (graphMode === 'inspire') {
			generatePanel?.classList.add('inspire-mode');
			searchPanel?.classList.add('inspire-mode');
		} else {
			generatePanel?.classList.remove('inspire-mode');
			searchPanel?.classList.remove('inspire-mode');
		}

		const switchToModeImpl = async () => {
			await loadMode(graphMode, isCollaborative, automation);
			const notePadButton = document.querySelector<HTMLButtonElement>('#notepad-button')!;
			if (graphMode === 'moodboard') {
				notePadButton.classList.remove('hidden');
				if (!isNotepadOpen()) {
					openHeartedList(() => {
						console.log('opened hearted list');
						if (globalThis.neuroCreate.graph) {
							globalThis.neuroCreate.graph.validate();
						}
					});
				} else {
					// Already open, but call again to ensure other panels are shut
					openNotepad();
				}
			} else {
				closeAllPanels();
				notePadButton.classList.add('hidden');
			}

			const groupControls = document.querySelectorAll<HTMLButtonElement>('.group-control');
			const undoControls = document.querySelectorAll<HTMLButtonElement>('.undo-control');
			if (graphMode === 'ideate') {
				[...groupControls, ...undoControls].forEach((button) => {
					button.classList.add('hidden');
				});
			} else {
				[...groupControls, ...undoControls].forEach((button) => {
					button.classList.remove('hidden');
				});
			}

			if (afterLoad) {
				afterLoad();
			}
		};

		if (doc) {
			doc.destroy(() => {
				console.log('doc destroyed');
				switchToModeImpl();
			});
			if (notesDoc) {
				notesDoc.destroy(() => {
					console.log('notes destroyed');
				});
			}
		} else {
			switchToModeImpl();
		}
	};

	// modeSwitcherSelect.selectedIndex = 0; // Reset to first value (in case browser has saved older state)
	// modeSwitcherSelect.onchange = (event) => {
	// 	const newMode = modeSwitcherSelect.value as GraphMode;
	// 	if (globalThis.neuroCreate.switchToMode) {
	// 		globalThis.neuroCreate.switchToMode(newMode, newMode === 'inspire', false);
	// 	} else {
	// 		event.preventDefault();
	// 	}
	// };

	// Load initial graph mode based on hash segment of URL
	handleHashRoute();

	// Listen for future hash changes
	window.addEventListener('hashchange', () => {
		handleHashRoute();
	});
}
