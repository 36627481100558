import { closeAllPanels, showAlert } from '../ui/uiUtils';
import { exportAll, PageName } from './pdfContent';
import * as tutorialEvents from '../tutorials/tutorialEvents';

globalThis.neuroCreate.exportBoard = exportAll;

const exportButton = document.querySelector<HTMLButtonElement>('#exportButton');
if (exportButton) {
	const getSelectedPages = (): Array<PageName> => {
		const pages = [] as Array<PageName>;
		if (exportContainer.querySelector<HTMLInputElement>('#pdf-inspire')!.checked) {
			pages.push('inspire');
		}
		if (exportContainer.querySelector<HTMLInputElement>('#pdf-ideate')!.checked) {
			pages.push('ideate-personal', 'ideate-team');
		}
		if (exportContainer.querySelector<HTMLInputElement>('#pdf-moodboard')!.checked) {
			pages.push('moodboard-personal', 'moodboard-team');
		}
		if (exportContainer.querySelector<HTMLInputElement>('#pdf-ideabox')!.checked) {
			pages.push('ideabox');
		}
		if (exportContainer.querySelector<HTMLInputElement>('#pdf-notepad')!.checked) {
			pages.push('notepad-personal', 'notepad-team');
		}
		return pages;
	};

	const getSecondsMultiplier = (): number => {
		switch (navigator.hardwareConcurrency) {
			case 1:
				return 20;
			case 2:
				return 15;
			case 3:
			case 4:
				return 10;
			default:
				return 5;
		}
	};

	const estimateExportSeconds = (): number => {
		const pages = getSelectedPages();
		let seconds = 0;
		if (pages.includes('inspire')) {
			seconds += 1;
		}
		if (pages.includes('ideate-personal')) {
			seconds += 2;
		}
		if (pages.includes('moodboard-personal')) {
			seconds += 2;
		}
		if (pages.includes('ideabox')) {
			seconds += 1;
		}
		if (pages.includes('notepad-personal')) {
			seconds += 2;
		}

		return seconds * getSecondsMultiplier();
	};

	const exportContainer = document.getElementById('export-container')!;
	exportButton.addEventListener('click', () => {
		closeAllPanels();
		exportButton.classList.add('buttonActive');
		exportContainer.classList.toggle('hidden');
		tutorialEvents.openedExport();
	});

	exportContainer.querySelector('.finish')!.addEventListener('click', (event) => {
		event.preventDefault();

		const pages = getSelectedPages();
		if (pages.length === 0) {
			showAlert('Please select pages to export');
		} else {
			exportAll(pages).catch((err) => {
				console.error('Export failed');
				console.error(err);
			});
		}
	});

	const checkboxes = exportContainer.querySelectorAll<HTMLInputElement>('input[type=checkbox]');
	checkboxes.forEach((checkbox) => {
		checkbox.addEventListener('change', () => {
			const seconds = estimateExportSeconds();
			document.querySelector<HTMLDivElement>('#export-estimate')!.innerText = `Export time: ${
				seconds ? `${seconds} seconds (estimated)` : 'N/A'
			}`;
		});
	});

	exportContainer.querySelector('.reset')!.addEventListener('click', () => {
		checkboxes.forEach((checkbox) => {
			checkbox.checked = false;
		});
	});

	const seconds = estimateExportSeconds();
	document.querySelector<HTMLDivElement>('#export-estimate')!.innerText = `Export time: ${
		seconds ? `${seconds} seconds (estimated)` : 'N/A'
	}`;
}
