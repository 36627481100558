import './components';
import { initGlobalUI } from './lib/ui/globalUi';

// Pages
if (document.querySelector('.create-project-button')) {
	console.log('Route: dashboard');
	require('./pages/dashboard');
} else if (document.querySelector('body.project')) {
	console.log('Route: project');
	require('./pages/project');
} else if (document.querySelector('body.board-no-access')) {
	console.log('Route: board no access');
	require('./pages/boardNoAccess');
} else if (document.querySelector('.new-form #board-form')) {
	console.log('Route: new board');
	require('./pages/newBoard');
} else if (document.querySelector('#board-content')) {
	console.log('Route: board');
	require('./pages/board');
} else if (document.querySelector('.login')) {
	require('./pages/registerAndLogin');
} else if (document.querySelector('.register')) {
	require('./pages/registerAndLogin');
} else if (document.querySelector('.settings.billing')) {
	require('./pages/settings/billing');
} else if (document.querySelector('.settings.general')) {
	require('./pages/settings/general');
} else if (document.querySelector('.settings.team')) {
	require('./pages/settings/team');
} else if (document.querySelector('.settings.quotas')) {
	require('./pages/settings/quotas');
}

initGlobalUI();
