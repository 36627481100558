import * as d3 from 'd3';
import {
	NCGroup,
	NCGroupMinimal,
	NCLink,
	NCLinkMinimal,
	NCNode,
	Point,
	UserSelection,
} from '../../../../src/commonTypes';
import { getDistanceBetweenPoints } from './geometryUtils';
import { GraphProps, NCBoard } from '../../types';

export const getRadialMenuButtonArc = ({
	target,
}: Event): d3.Selection<SVGGElement, NCNode, d3.BaseType, undefined> => {
	const node = d3.select<SVGGElement, NCNode>(target as SVGGElement);
	if (node.classed('arc')) {
		return node;
	}
	return node.select<SVGGElement>(function () {
		return this.parentNode as SVGGElement;
	});
};

/**
 * Helper function that returns the parent node of an element
 */
export const getParent = ({
	target,
}: Event): d3.Selection<SVGGElement, NCNode, d3.BaseType, undefined> => {
	const node = d3.select<SVGGElement, NCNode>(target as SVGGElement);
	return node.select<SVGGElement>(function () {
		return this.parentNode as SVGGElement;
	});
};

const MIN_DISTANCE = 64;

const MOVEMENT_MIN_THRESHOLD = 1;
export const hasMovedNoticably = (start: Point, end: Point) => {
	return (
		Math.abs(start.x - end.x) > MOVEMENT_MIN_THRESHOLD ||
		Math.abs(start.y - end.y) > MOVEMENT_MIN_THRESHOLD
	);
};

export const getEvenlySpacedPoints = (numberOfPoints: number): Array<Point> => {
	const gridSize = Math.ceil(Math.sqrt(numberOfPoints));
	return [...Array(numberOfPoints).keys()].map((i) => {
		const y = (i / gridSize) * MIN_DISTANCE;
		const x = (i % gridSize) * MIN_DISTANCE;
		return { x, y };
	});
};

/**
 * Helper function that calculates whether a node is in range to connect
 */
export const getDistanceForConnection = (
	point1: Point,
	point2: Point,
	radius: number
): { isInRange: boolean; isTooClose: boolean; distance: number } => {
	const distance = getDistanceBetweenPoints(point1, point2);
	// const angleInDegrees = Math.atan2(point2.y - point1.y, point2.x - point1.x) * 180 / Math.PI;
	return {
		distance,
		isInRange: distance <= radius + MIN_DISTANCE * 4, // && distance > radius * 1.5 + MIN_DISTANCE,
		// && angleInDegrees <= 0,
		isTooClose: false, //distance <= radius + MIN_DISTANCE,
	};
};

export const hexOrNamedColour = (colour: string) =>
	colour?.charAt(0) === '#' ? colour : colour && `var(--${colour})`;

// the line that is drawn when a user wants to connect a new node to a previous node
export const createMouseLink = (
	baseElement: d3.Selection<SVGGElement, null, HTMLElement, null>
): d3.Selection<SVGGElement, NCNode, SVGGElement, null> =>
	baseElement.append('g').attr('class', 'mouselink').selectAll('line');

// the circle that follows the user's mouse coordinates
export const createCustomCursor = (
	baseElement: d3.Selection<SVGGElement, null, HTMLElement, null>,
	colourHex: string
): d3.Selection<SVGCircleElement, null, HTMLElement, null> =>
	baseElement.append('circle').attr('class', 'cursor').attr('r', 10).style('stroke', colourHex);

export const createDebugMarker = (
	baseElement: d3.Selection<SVGGElement, null, HTMLElement, null>
): d3.Selection<SVGCircleElement, null, HTMLElement, null> =>
	baseElement
		.append('circle')
		.attr('class', 'debug-marker')
		.attr('r', 5)
		.style('stroke', '#f0f')
		.style('stroke-width', 5);

export const createSvg = (
	parentElement: d3.Selection<HTMLDivElement, null, HTMLElement, null>,
	width: number,
	height: number
): d3.Selection<SVGSVGElement, null, HTMLElement, null> => {
	// Remove any old SVG
	parentElement.select<SVGSVGElement>('svg').remove();

	// Add the new SVG and return
	return parentElement
		.append('svg')
		.attr('width', width)
		.attr('height', height)
		.attr('viewBox', [-width / 2, -height / 2, width, height])
		.attr('id', 'graph-background');
};

/**
 * Function to use with .join to remove an element
 */
export const removeEl = <GElement extends d3.BaseType, Datum, PElement extends d3.BaseType>(
	elem: d3.Selection<GElement, Datum, PElement, unknown>
): void => {
	elem
		// Filter as red, then fade out
		.style(
			'filter',
			'grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(600%) contrast(0.8)'
		)
		.transition()
		.style('opacity', 0)
		.remove();
};

export const removeNoDeleteAnimation = <
	GElement extends d3.BaseType,
	Datum,
	PElement extends d3.BaseType,
>(
	elem: d3.Selection<GElement, Datum, PElement, unknown>
): void => {
	elem.remove();
};

export const calcGraphWidthAndHeight = () => {
	const body = document.body,
		html = document.documentElement;

	const width = Math.max(body.scrollWidth, body.offsetWidth, html.clientWidth, html.offsetWidth);

	const height = Math.max(
		body.scrollHeight,
		body.offsetHeight,
		html.clientHeight,
		html.offsetHeight
	);

	return { width, height };
};

export const getLabels = (nodes: Array<NCNode>): Array<string> => {
	return nodes
		.map((node) => node.label)
		.filter((label): label is string => typeof label === 'string' && label !== '');
};

export const getAllUserSelections = (board: NCBoard): Array<UserSelection> => {
	return [
		...(board.userOne?.userSelections || []),
		...(board.userTwo?.userSelections || []),
		...(board.userThree?.userSelections || []),
		...(board.userFour?.userSelections || []),
		...(board.userFive?.userSelections || []),
		...(board.userSix?.userSelections || []),
		...(board.userSeven?.userSelections || []),
		...(board.userEight?.userSelections || []),
	];
};

export const getLinkedNodes = (node: NCNode, links?: Array<NCLink>): Array<NCNode> => {
	let linksToCheck = links;
	if (!linksToCheck && globalThis.neuroCreate.graph) {
		linksToCheck = globalThis.neuroCreate.graph.links;
	}
	if (!linksToCheck) {
		return [];
	}

	const linkedSet = new Set<NCNode>();
	linksToCheck
		.filter((l) => l.source.uid === node.uid)
		.forEach(({ target }) => {
			linkedSet.add(target);
		});
	linksToCheck
		.filter((l) => l.target.uid === node.uid)
		.forEach(({ source }) => {
			linkedSet.add(source);
		});
	return Array.from(linkedSet);
};

export const getAllLinkedNodes = (nodes: Array<NCNode>, links: Array<NCLink>): Array<NCNode> => {
	const groupSet = new Set<NCNode>(nodes);
	const sizeBefore = groupSet.size;
	for (const node of nodes) {
		getLinkedNodes(node, links).forEach((n) => {
			groupSet.add(n);
		});
	}
	if (sizeBefore === groupSet.size) {
		// Base case
		return Array.from(groupSet);
	} else {
		return getAllLinkedNodes(Array.from(groupSet), links);
	}
};

export const findClosestNodeToLinkTo = (
	newNode: NCNode,
	nodes: Array<NCNode>,
	nodeRadius: number
): NCNode | null => {
	// Create link to the closest node that is in range
	let closestExistingNode = null;
	let shortestDistance = null;
	for (const otherNode of nodes) {
		const { isInRange, distance } = getDistanceForConnection(newNode, otherNode, nodeRadius);
		if (
			isInRange &&
			(!closestExistingNode || shortestDistance === null || distance < shortestDistance)
		) {
			closestExistingNode = otherNode;
			shortestDistance = distance;
		}
	}
	return closestExistingNode;
};

export const getUpdatedNodesLinksGroups = (
	nodes: Array<NCNode>,
	links: Array<NCLinkMinimal>,
	oldLinks: Array<NCLink>,
	groups: Array<NCGroupMinimal>,
	oldGroups: Array<NCGroup>
): { updatedNodes: Array<NCNode>; updatedLinks: Array<NCLink>; updatedGroups: Array<NCGroup> } => {
	// Check for any nodes with the same position and separate them
	// If they have the same position, the collision physics can freeze up
	const updatedNodes = nodes.map((n, i) => {
		const samePositionNode = nodes.slice(i + 1).find((n2) => n2.x === n.x && n2.y === n.y);
		if (samePositionNode) {
			return {
				...n,
				x: n.x + Math.random(),
				y: n.y + Math.random(),
			};
		}
		return n;
	});

	// Reconstruct links using node objects. References to the source/target in each link must be updated as the nodes are
	const updatedLinks = links.flatMap((l) => {
		const updatedLink =
			oldLinks?.find(
				(l2) => l.source.uid === l2.source.uid && l2.target && l.target.uid === l2.target.uid
			) || ({} as { source?: string; target?: string });
		const source = updatedNodes.find((n) => n.uid === l.source.uid);
		const target = updatedNodes.find((n) => n.uid === l.target.uid);
		if (!source) {
			console.error(`Missing source node for ${l.source.uid}`);
			return [];
		} else if (!target) {
			console.error(`Missing target node for ${l.target.uid}`);
			return [];
		} else {
			Object.assign(updatedLink, {
				source,
				target,
				colour: l.colour,
			});
			return updatedLink.source && updatedLink.target ? [updatedLink as NCLink] : [];
		}
	});

	const updatedGroups = groups.map((g) => {
		const nodesForGroup = g.nodes
			.map((n) => updatedNodes.find((n2) => n2.uid === n.uid) || undefined)
			.filter((n): n is NCNode => !!n);
		const newGroup = oldGroups?.find((g2) => g2.uid === g.uid) || ({ ...g } as NCGroup);
		delete newGroup.active;
		delete newGroup.overrideColour;
		Object.assign(newGroup, g);
		newGroup.nodes = nodesForGroup;
		return newGroup;
	});

	return { updatedNodes, updatedLinks, updatedGroups };
};

export const getMouseTargetInfo = (event: MouseEvent) => {
	const element = event.target as HTMLElement;

	const targetId = element.id;
	const targetClasses =
		(element.className &&
			((element.className as unknown as { baseVal: string }).baseVal !== undefined
				? (element.className as unknown as { baseVal: string }).baseVal
				: element.className)) ||
		'';
	const targetClass = targetClasses ? targetClasses.split(' ', 1)[0] : '';

	return { targetClass, targetId, element };
};

const NEW_NODE_INDICATOR_OFFSET = 15;
export const updateCursorAndMouseLink = (
	cursor: undefined | d3.Selection<SVGCircleElement, null, HTMLElement, null>,
	mouse: null | (Point & { targetId?: string; targetClass?: string; element?: HTMLElement }),
	activeNode: NCNode | undefined
) => {
	/**
	 * changes the (x, y) coordinates of the cursor circle based on the position of the mouse
	 */
	if (cursor) {
		if (mouse) {
			cursor
				.attr('display', mouse.targetId === 'graph-background' && !activeNode ? null : 'none')
				.attr('cx', mouse.x + NEW_NODE_INDICATOR_OFFSET)
				.attr('cy', mouse.y + NEW_NODE_INDICATOR_OFFSET);
		} else {
			cursor.attr('display', 'none');
		}
	}
};

/**
 * connects the link to the nearest (in range) node and draws the line between the two nodes
 */
export const updateMouseLink = (
	mouseLink: undefined | d3.Selection<SVGGElement, NCNode, SVGGElement, null>,
	mouse: null | (Point & { targetId?: string; targetClass?: string; element?: HTMLElement }),
	graphProps: GraphProps,
	nodes: Array<NCNode>
) => {
	if (mouseLink) {
		return (
			mouseLink
				.data<NCNode>(() => {
					if (!mouse /*  || this.activeNode */) {
						return [];
					}
					if (mouse.targetId !== 'graph-background') {
						return [];
					}
					let closestExistingNode: null | NCNode = null;
					let shortestDistance: null | number = null;
					for (const otherNode of nodes) {
						const { isInRange, isTooClose, distance } = getDistanceForConnection(
							mouse,
							otherNode,
							graphProps.nodeRadius
						);
						if (isTooClose) {
							return []; // Don't allow placing a node here, too close to another
						}
						if (
							isInRange &&
							(!closestExistingNode || shortestDistance === null || distance < shortestDistance)
						) {
							closestExistingNode = otherNode;
							shortestDistance = distance;
						}
					}
					return closestExistingNode ? [closestExistingNode] : [];
				})
				.join('line')
				// Ensure sure the line does not draw under the cursor otherwise it will block clicks
				.attr('x1', (d) => mouse && mouse.x - (mouse.x - d.x) * 0.05)
				.attr('y1', (d) => mouse && mouse.y - (mouse.y - d.y) * 0.05)
				.attr('x2', (d) => d.x)
				.attr('y2', (d) => d.y)
		);
	}
	return undefined;
};
