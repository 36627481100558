import {
	closeExpandedMenuAfterTutorial,
	isTutorialActive,
	openCollapsedMenuForTutorial,
} from '../tutorials/tutorial';
import { WINDOW_BREAKPOINTS } from '../constants';
const localMenuContainer = document.querySelector('#local-menu') as HTMLDivElement;
const toolbarMenuContainer = document.querySelector('#toolbar-menu-wrapper') as HTMLDivElement;
const aiMenuContainer = document.querySelector('#ai-menu-wrapper') as HTMLDivElement;
const topMenuContainer = document.getElementById('top-menu-wrapper') as HTMLDivElement;
const stageContainer = document.querySelector('.stage-wrapper') as HTMLDivElement;

// set collapse / expand menu when window is loaded
window.addEventListener('load', () => {
	addCollapseButton();
	removeDropdown();
});

// set collapse / expand menu when window is resized
window.addEventListener('resize', () => {
	addCollapseButton();
	removeDropdown();
});

const addCollapseButton = () => {
	expandVerticalMenuItems(toolbarMenuContainer);
	expandVerticalMenuItems(aiMenuContainer);

	if (window.innerWidth < WINDOW_BREAKPOINTS.desktop) {
		collapseVerticalMenuItems(toolbarMenuContainer);
		expandVerticalMenuItems(localMenuContainer);
	}

	if (window.innerWidth < WINDOW_BREAKPOINTS.laptop) {
		collapseVerticalMenuItems(localMenuContainer);
		expandVerticalMenuItems(aiMenuContainer);
	}

	if (window.innerWidth < WINDOW_BREAKPOINTS.tablet) {
		collapseVerticalMenuItems(aiMenuContainer);
		expandVerticalMenuItems(stageContainer);
	}

	if (window.innerWidth < WINDOW_BREAKPOINTS.mobile) {
		collapseVerticalMenuItems(stageContainer);
	}
};

let currentDropdownContainer: HTMLDivElement | null = null;

const addDropdown = (container: HTMLDivElement) => {
	removeDropdown();
	// reset currentDropdownContainer if user clicks on same menu
	if (currentDropdownContainer === container) {
		currentDropdownContainer = null;
		return;
	}

	container.querySelector('.expand-menu')?.classList.add('active');
	if (!container.classList.contains('stage-wrapper')) {
		container?.querySelector('.expand-menu .option')?.classList.add('hidden');
		container?.querySelector('.expand-menu .arrow-down')?.classList.remove('hidden');
	} else {
		(container?.querySelector('.expand-menu img') as HTMLImageElement).style.transform =
			'rotate(180deg)';
	}

	currentDropdownContainer = container;
	const dropdown = document.createElement('div');
	dropdown.classList.add('dropdown');
	// get items to add to dropdown (except for collapse-menu button)
	const menuItems = container.querySelectorAll(':scope > *:not(.collapse-menu).collapsed');

	// get container position
	const containerPosition = container.getBoundingClientRect();
	const left = containerPosition.left;
	const bottom = window.innerWidth < WINDOW_BREAKPOINTS.mobile ? 66 : 73;

	const aiMenuItems = aiMenuContainer.querySelectorAll(':scope > div');
	aiMenuItems.forEach((item) => {
		item.classList.add('ai-menu-item');
	});
	// if window is less than 950px, combine ai menu with toolbar menu in dropdown
	if (window.innerWidth < WINDOW_BREAKPOINTS.tablet && container.id === 'toolbar-menu-wrapper') {
		dropdown.append(...aiMenuItems, ...menuItems);
	} else {
		dropdown.append(...menuItems);
	}
	topMenuContainer.append(dropdown);

	// make sure dropdown is always within viewport
	const dropdownWidth = dropdown.offsetWidth;
	dropdown.style.position = 'absolute';
	dropdown.style.zIndex = '1000';
	dropdown.style.top = `${bottom}px`;
	dropdown.style.left = `${
		window.innerWidth - left < dropdownWidth ? window.innerWidth - dropdownWidth - 20 : left - 10
	}px`;

	// hide dropdown if user touches one of the menu items
	const dropdownItems = dropdown.querySelectorAll(':scope > *');
	dropdownItems.forEach((item) => {
		if (!item.className.includes('ai-menu-item')) {
			item.addEventListener('click', () => {
				setTimeout(() => {
					if (currentDropdownContainer) {
						toggleMenuItems(currentDropdownContainer);
					}
				}, 500);
			});
		}
	});
};

export const removeDropdown = () => {
	currentDropdownContainer;
	// reset expand menu button
	currentDropdownContainer?.querySelector('.expand-menu')?.classList.remove('active');
	currentDropdownContainer?.querySelector('.expand-menu')?.classList.remove('expanded');

	if (!currentDropdownContainer?.classList.contains('stage-wrapper')) {
		currentDropdownContainer?.querySelector('.expand-menu .option')?.classList.remove('hidden');
		currentDropdownContainer?.querySelector('.expand-menu .arrow-down')?.classList.add('hidden');
	} else {
		(
			currentDropdownContainer?.querySelector('.expand-menu img') as HTMLImageElement
		).style.transform = 'rotate(0deg)';
	}

	const existingDropdown = document.querySelector('.dropdown');
	const allItems = existingDropdown?.querySelectorAll(':scope > *');
	const aiMenuItems = existingDropdown?.querySelectorAll('.ai-menu-item');
	const toolbarCollapseButton =
		currentDropdownContainer?.id === 'toolbar-menu-wrapper'
			? currentDropdownContainer.querySelector('.collapse-right')
			: null;

	allItems ? currentDropdownContainer?.append(...allItems) : null;
	toolbarCollapseButton ? currentDropdownContainer?.append(toolbarCollapseButton) : null;
	aiMenuItems ? aiMenuContainer.append(...aiMenuItems) : null;
	existingDropdown ? existingDropdown.remove() : null;
};

function toggleMenuItems(mainMenu: HTMLDivElement) {
	const button = mainMenu.querySelector('button.expand-menu') as HTMLButtonElement;
	button.classList.add('expanded');
	if (button.classList.contains('expanded')) {
		addDropdown(mainMenu);
		button.classList.remove('expanded');
	} else {
		removeDropdown();
	}
}

function collapseVerticalMenuItems(container: HTMLDivElement) {
	const menuItems = container.querySelectorAll(':scope > *');
	menuItems.forEach((element) => {
		if (element.classList.contains('expand-menu')) {
			element.classList.remove('hidden');
		} else {
			element.classList.add('collapsed');
		}
	});
}

function expandVerticalMenuItems(container: HTMLDivElement) {
	const menuItems = container.querySelectorAll(':scope > *');
	menuItems.forEach((element) => {
		if (element.classList.contains('expand-menu')) {
			element.classList.add('hidden');
		}

		element.classList.remove('collapsed');
	});
}

function toggleCollapsedMenu(container: HTMLDivElement) {
	const menuList = container.querySelectorAll('button, ul');
	menuList.forEach((menu, ms) => {
		if (!menu.classList.contains('expand-menu')) {
			ms = ms *= 10;
			setTimeout(() => {
				menu.classList.toggle('collapsed');
			}, ms);
		}
	});
}

const handleExpandEvent = (button: HTMLButtonElement, container: HTMLDivElement) => {
	if (window.innerWidth < WINDOW_BREAKPOINTS.laptop) {
		toggleMenuItems(container);
	} else {
		button.classList.add('hidden');
		toggleCollapsedMenu(container);
	}
};

const handleCollapseEvent = (button: HTMLButtonElement, container: HTMLDivElement) => {
	toggleCollapsedMenu(container);
	setTimeout(() => {
		button.classList.remove('hidden');
	}, 200);
};

const mainMenuExpandButton = stageContainer.querySelector('.expand-menu') as HTMLButtonElement;
const toolbarMenuExpandButton = toolbarMenuContainer.querySelector(
	'.expand-menu'
) as HTMLButtonElement;
const toolbarMenuCollapseButton = toolbarMenuContainer.querySelector(
	'.collapse-menu'
) as HTMLButtonElement;
const localMenuExpandButton = localMenuContainer.querySelector('.expand-menu') as HTMLButtonElement;
const localMenuCollapseButton = localMenuContainer.querySelector(
	'.collapse-menu'
) as HTMLButtonElement;

mainMenuExpandButton.addEventListener('click', () => {
	toggleMenuItems(stageContainer);
});

toolbarMenuExpandButton.addEventListener('click', () => {
	if (
		window.innerWidth >= WINDOW_BREAKPOINTS.laptop &&
		window.innerWidth < WINDOW_BREAKPOINTS.desktop &&
		!localMenuCollapseButton.classList.contains('collapsed')
	) {
		localMenuCollapseButton.click();
	}
	handleExpandEvent(toolbarMenuExpandButton, toolbarMenuContainer);
});

toolbarMenuCollapseButton.addEventListener('click', () => {
	handleCollapseEvent(toolbarMenuExpandButton, toolbarMenuContainer);
});

localMenuExpandButton.addEventListener('click', () => {
	if (
		window.innerWidth >= WINDOW_BREAKPOINTS.laptop &&
		window.innerWidth < WINDOW_BREAKPOINTS.desktop &&
		!toolbarMenuCollapseButton.classList.contains('collapsed')
	) {
		toolbarMenuCollapseButton.click();
	}
	handleExpandEvent(localMenuExpandButton, localMenuContainer);
});

localMenuCollapseButton.addEventListener('click', () => {
	handleCollapseEvent(localMenuExpandButton, localMenuContainer);
});

export const isHintsTutorialActive = (targetId?: string): boolean => {
	if (targetId === 'board-menu-hearted') {
		return true;
	} else {
		return false;
	}
};

// if dropdown exists and user click outside of it remove dropdown
document.addEventListener('click', (e) => {
	if (currentDropdownContainer && !currentDropdownContainer.contains(e.target as Node)) {
		// if tutorial is active, don't remove dropdown
		if (
			isTutorialActive('moodboard') ||
			isTutorialActive('inspire') ||
			isHintsTutorialActive('board-menu-hearted')
		) {
			return;
		}

		setTimeout(() => {
			removeDropdown();
		}, 200);
	}
});

export const closeAllMenus = () => {
	document
		.querySelectorAll<HTMLButtonElement>('#top-menu-wrapper .expand-menu.active')
		.forEach((menuButton) => {
			menuButton.click();
		});
};

export const ensureMenuIsOpen = (target: HTMLElement | null): boolean => {
	if (!target) {
		return true;
	}

	closeExpandedMenuAfterTutorial(document.querySelector('#toolbar-menu-wrapper .expand-menu')!);
	closeExpandedMenuAfterTutorial(document.querySelector('#local-menu .expand-menu')!);

	if (target.id === 'ai-toggle-button') {
		if (window.innerWidth < WINDOW_BREAKPOINTS.tablet) {
			openCollapsedMenuForTutorial(
				document.querySelector('#ai-menu-wrapper .board-icon')!,
				document.querySelector('#toolbar-menu-wrapper .expand-menu')!
			);
		}
	} else if (target.id === 'board-menu-hearted') {
		openCollapsedMenuForTutorial(
			document.querySelector('#board-menu-hearted')!,
			document.querySelector('#local-menu .expand-menu')!
		);
	} else if (target.id === 'notepad-button') {
		openCollapsedMenuForTutorial(
			document.querySelector('#notepad-button')!,
			document.querySelector('#local-menu .expand-menu')!
		);
	} else if (target.id === 'board-menu-invite') {
		openCollapsedMenuForTutorial(
			document.querySelector('#board-menu-invite')!,
			document.querySelector('#toolbar-menu-wrapper .expand-menu')!
		);
	} else if (target.id === 'board-stage-switcher') {
		openCollapsedMenuForTutorial(
			document.querySelector('#board-stage-switcher')!,
			document.querySelector('#main-menu-wrapper .expand-menu')!
		);
	} else if (target.id === 'exportButton') {
		openCollapsedMenuForTutorial(
			document.querySelector('#exportButton')!,
			document.querySelector('#toolbar-menu-wrapper .expand-menu')!
		);
	}

	return true;
};
