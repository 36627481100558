import '../lib/ui/timer';
import '../lib/ws';
import '../lib/ui/boardTitle';
import '../lib/ui/boardEngine';
import '../lib/ui/notepad';
import '../lib/ui/analysesRecommendations';
import '../lib/ui/inviteUser';
import '../lib/tutorials/tutorial';
import '../lib/pdfs/export';
import '../lib/ui/synthesiseAi';
import '../lib/ui/menuUi';
import {
	ButtonDefinition,
	clearNonBlockingMessage,
	componentToggle,
	isReadyForNonEssentialAlert,
	showAlert,
	showNonBlockingMessage,
	closeAllPanels,
} from '../lib/ui/uiUtils';
import { IdeateGraph } from '../lib/graphs';
import { getInspireProgressFactors, showCreateGroupPrompt } from '../lib/ui/toolsUI';
import { progressNudges } from '../../../src/strings';
import '../lib/slides/slides';
import { removeDropdown } from '../lib/ui/menuUi';

const aiSetting = document.querySelector<HTMLInputElement>('#ai-setting');
if (aiSetting) {
	aiSetting.onchange = (e) => {
		if (globalThis.neuroCreate.graph) {
			globalThis.neuroCreate.graph.toggleAi();
		} else {
			e.preventDefault();
		}
	};
}

/*
	show guide container
*/
const guideButton = document.querySelector<HTMLButtonElement>('#guideButton');
if (guideButton) {
	guideButton.addEventListener('click', () => {
		const guideContainer = document.querySelector<HTMLDivElement>('#guide');
		if (guideContainer?.classList.contains('hidden')) {
			closeAllPanels();
			removeDropdown();
			guideButton.classList.add('buttonActive');
			componentToggle(guideContainer);
		} else {
			guideButton.classList.remove('buttonActive');
			componentToggle(guideContainer!);
		}
	});
}

const undoButton = document.querySelector<HTMLButtonElement>('#undo-action');
if (undoButton) {
	undoButton.addEventListener('click', () => {
		if (globalThis.neuroCreate.graph) {
			globalThis.neuroCreate.graph.undo();
		}
	});
}

const createGroupButton = document.querySelector<HTMLButtonElement>('#create-group');
if (createGroupButton) {
	createGroupButton.addEventListener('click', () => {
		const graph = globalThis.neuroCreate.graph;
		if (graph) {
			graph.stopSelectingNodes();
			showCreateGroupPrompt();
			graph.removeActiveStatus();
		}
	});
}

const removeGroupButton = document.querySelector<HTMLButtonElement>('#remove-group');
if (removeGroupButton) {
	removeGroupButton.addEventListener('click', () => {
		const graph = globalThis.neuroCreate.graph;
		if (graph?.activeGroup) {
			graph.removeGroup(graph.activeGroup);
		} else {
			showAlert(
				'Please select a group (or a node within the group) before using the <strong>Ungroup</strong> button.'
			);
		}
	});
}

const editGroupButton = document.querySelector<HTMLButtonElement>('#edit-group');
if (editGroupButton) {
	editGroupButton.addEventListener('click', () => {
		const graph = globalThis.neuroCreate.graph;
		const activeGroup = graph?.activeGroup;
		if (activeGroup) {
			showNonBlockingMessage('How would you like to edit this group?', [
				{
					label: 'Add concept',
					onClick: () => {
						graph.startSelectingNodes('addToGroup', []);
						showNonBlockingMessage('Select a concept to add to the group.', [
							{
								label: 'Cancel',
								onClick: () => {
									graph.stopSelectingNodes();
								},
							},
						]);
					},
				},
				{
					label: 'Remove concept',
					onClick: () => {
						const graph = globalThis.neuroCreate.graph;
						if (graph) {
							graph.selections.groupingNodes = [...activeGroup.nodes];
							graph.startSelectingNodes('removeFromGroup', []);
							showNonBlockingMessage('Select a concept to remove from the group.', [
								{
									label: 'Cancel',
									onClick: () => {
										graph.stopSelectingNodes();
									},
								},
							]);
						}
					},
				},
				{
					label: 'Change colour',
					onClick: () => {
						clearNonBlockingMessage();
						const buttons: Array<ButtonDefinition> = [
							{
								label: 'Cancel',
							},
						];
						if (activeGroup.overrideColour) {
							buttons.push({
								label: 'Use default',
								onClick: () => {
									if (neuroCreate.saveGroups) {
										const updatedGroup = { ...activeGroup };
										delete updatedGroup.overrideColour;
										neuroCreate.saveGroups.update([updatedGroup]);
									}
								},
							});
						}
						buttons.push({
							label: 'Save',
							primary: true,
							onClick: () => {
								if (neuroCreate.saveGroups) {
									const updatedGroup = {
										...activeGroup,
										overrideColour:
											document.querySelector<HTMLInputElement>('#colour-selector')?.value ||
											undefined,
									};
									neuroCreate.saveGroups.update([updatedGroup]);
								}
							},
						});
						showAlert(
							'Select a custom colour: <input type="color" value="#ff0000" id="colour-selector" />',
							buttons
						);
					},
				},
				{
					label: 'Cancel',
					onClick: () => {
						clearNonBlockingMessage();
					},
				},
			]);
		} else {
			showAlert(
				'Please select a group (or a node within the group) before using the <strong>Edit Group</strong> button.'
			);
		}
	});
}

const ideateUseAiToolsCheck = document.querySelector('#ideate-use-ai-results')!;
ideateUseAiToolsCheck.addEventListener('click', () => {
	if (globalThis.neuroCreate.graph?.graphMode === 'ideate') {
		const ideateGraph = globalThis.neuroCreate.graph as IdeateGraph;
		ideateGraph.toggleIncludeAiToolResults();
	}
});

setInterval(() => {
	if (globalThis.neuroCreate.graph?.graphMode === 'inspire' && isReadyForNonEssentialAlert()) {
		const factors = getInspireProgressFactors(globalThis.neuroCreate.graph);

		const possibleMessages = [] as Array<string>;
		if (factors.exploration.score < 0.6) {
			possibleMessages.push(progressNudges.exploration);
		}
		if (factors.depth.score < 0.6) {
			possibleMessages.push(progressNudges.depth);
		}
		if (factors.toolsUsed.score < 1) {
			possibleMessages.push(progressNudges.aiTools);
		}
		if (factors.connectedness.score < 1) {
			possibleMessages.push(progressNudges.connectedness);
		}
		if (factors.nodes.score < 1) {
			possibleMessages.push(progressNudges.nodes);
		}

		if (possibleMessages.length > 0) {
			const randomIndex = Math.floor(Math.random() * possibleMessages.length);
			showNonBlockingMessage(possibleMessages[randomIndex], undefined, true);
		} else {
			clearNonBlockingMessage();
		}
	}
}, 3 * 60000);

const zoomInButton = document.getElementById('board-zoom-in');
if (zoomInButton) {
	zoomInButton.onclick = () => {
		globalThis.neuroCreate.graph?.zoomIn();
	};
	document.getElementById('board-zoom-out')!.onclick = () => {
		globalThis.neuroCreate.graph?.zoomOut();
	};
	document.getElementById('board-zoom-fit')!.onclick = () => {
		globalThis.neuroCreate.graph?.centerOnCentralPoint();
		// globalThis.neuroCreate.graph?.zoomToFit();
	};
	document.addEventListener('keydown', function (e) {
		if (document.activeElement === document.body && globalThis.neuroCreate.graph) {
			const graph = globalThis.neuroCreate.graph;
			switch (e.key) {
				case 'ArrowLeft':
					graph.panRight();
					break;
				case 'ArrowUp':
					graph.panDown();
					break;
				case 'ArrowRight':
					graph.panLeft();
					break;
				case 'ArrowDown':
					graph.panUp();
					break;
				case '+':
				case '=':
					graph.zoomIn();
					break;
				case '-':
				case '_':
					graph.zoomOut();
					break;
			}
		}
	});
	window.addEventListener('resize', () => {
		if (globalThis.neuroCreate.graph) {
			globalThis.neuroCreate.graph.sizeToWindow();
		}
	});
}
document.addEventListener('keydown', function (e) {
	if (document.activeElement === document.body && globalThis.neuroCreate.graph) {
		switch (e.key) {
			case 'Backspace':
			case 'Delete':
				if (globalThis.neuroCreate.graph) {
					if (globalThis.neuroCreate.graph.activeNode) {
						globalThis.neuroCreate.graph.delete(globalThis.neuroCreate.graph.activeNode);
					}
				}
				break;
			case 'Enter':
			case 'Return':
				if (globalThis.neuroCreate.graph?.graphMode === 'inspire') {
					globalThis.neuroCreate.graph.createNewEmptyNode();
				}
				break;
		}
	}
});

// Disable pinch to zoom except for board
document.addEventListener(
	'wheel',
	function (event) {
		globalThis.neuroCreate.graph?.wheel(event);
		const { ctrlKey } = event;
		if (ctrlKey) {
			event.preventDefault();
			return;
		}
	},
	{ passive: false }
);
