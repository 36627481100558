import { startTutorial } from './tutorial';

// IntroJS types are out-of-date so we need some overrides
/* eslint-disable @typescript-eslint/ban-ts-comment */

const dashboardTutorialButton = document.querySelector<HTMLButtonElement>(
	'#header-menu-help-button'
)!;
if (dashboardTutorialButton) {
	dashboardTutorialButton.addEventListener('click', () => {
		dashboardTutorialButton.classList.toggle('helpActive');
		dashboardTutorial();
	});
}

const dashboardTutorial = () => {
	if (dashboardTutorialButton.className === 'helpActive') {
		startTutorial(
			{
				steps: [
					{
						title: 'Welcome 👋',
						tooltipClass: 'medium-tooltip',
						intro: `Welcome to FlowCreate ✨
						<br/><br/>
						This tutorial will guide you through the basics of FlowCreate.
						<br/><br/>
						<small>(You can always access this tutorial by clicking the "?" button in the top right corner.)</small>`,
					},
					{
						title: 'Project',
						element: '#user-projects',
						intro: `A project holds a group of related boards. Create a new project by clicking "+" button and type its name.
							<br/><br/>
							You can share your projects with your team members.`,
					},
					{
						title: 'Create a board',
						element: '.create-new-board',
						intro:
							'You can click "+" to create a new board, and we will recommend you an AI suggestion engine depending on your task and function. Or click on a template to generate an example board that suits your purpose the most - you can springboard from our suggested pathway',
					},
					{
						title: 'All Boards',
						element: '#user-saved-boards',
						// @ts-ignore
						scrollTo: '#user-saved-boards h2',
						intro: 'Here you can see all boards that you have created or been invited to.',
					},
					{
						title: 'Settings',
						intro: 'You can check your account settings and subscription here.',
						element: '.header-settings-link',
					},
					{
						title: 'Complete 🎉',
						element: '#create-board',
						intro: `You have completed the dashboard tutorial.
							 <br/><br/>
							 Create a <strong>New Board</strong>, then we'll continue with the board tutorial.`,
					},
				],
			},
			{
				onexit: function () {
					dashboardTutorialButton.classList.remove('helpActive');
				},
			}
		);
	}
};
