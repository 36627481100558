import {
	componentToggle,
	removeChildren,
	showAlert,
	clearNonBlockingMessage,
	getBoardIdFromUrl,
	closeAllPanels,
	getProjectIdFromUrl,
	createUserIconElement,
} from './uiUtils';
import {
	searchForUser,
	removeUserFromBoard,
	removeUserFromProject,
	inviteWithNonSignedUpEmail,
	inviteUserWithUsername,
	decideAccessRequest,
} from '../apiRequests';
import * as tutorialEvents from '../tutorials/tutorialEvents';

const currentUserId = document.body.dataset.userId;

const isProjectPage = document.body.classList.contains('project');

const entityName = isProjectPage ? 'project' : 'board';

const getOwnerId = () =>
	isProjectPage
		? document.body.dataset.owner
		: globalThis.neuroCreate?.graph?.boardUsers.at(-1)!.id;

const getUserRole = (id: string) => {
	if (isProjectPage) {
		return getOwnerId() === id ? 'Owner' : 'Member';
	} else {
		return getOwnerId() === id ? 'Owner' : 'Collaborator';
	}
};

export const updateInvitePanelUserCount = () => {
	const usersInInvitePanel = document.querySelectorAll<HTMLLIElement>(
		'#user-with-access .board-component-user-item'
	);
	const numberOfUsers = usersInInvitePanel.length;
	const userNumber = document.querySelector<HTMLSpanElement>('#user-with-access .count')!;
	userNumber.innerText = numberOfUsers.toString();
};

const decideAccessHandler = (
	requestItem: HTMLLIElement,
	boardId: string,
	requestId: string,
	accept: boolean
) => {
	return (event: Event) => {
		event.preventDefault();
		const inviteResult = document.querySelector<HTMLDivElement>('.invite-result')!;
		console.log(1);
		decideAccessRequest(boardId, requestId, accept).then(({ success, message }) => {
			console.log(2);
			inviteResult.innerText = message;
			if (success) {
				inviteResult.classList.add('success');
				inviteResult.classList.remove('warning');
				requestItem.remove();
				if (document.querySelectorAll('#access-requests .board-component-user-item').length === 0) {
					document.querySelector('#access-requests')!.classList.add('hidden');
				}
			} else {
				inviteResult.classList.remove('success');
				inviteResult.classList.add('warning');
			}
		});
	};
};

const removeUserHandler = (e: Event) => {
	const removeButton = e.target as HTMLElement;

	if (currentUserId === getOwnerId()) {
		const userToRemove = removeButton.closest<HTMLLIElement>('.board-component-user-item')!;
		const userIdToRemove = userToRemove.dataset.uid!;
		if (currentUserId !== userIdToRemove) {
			hideAnyOpenUserMenu();
			showAlert(`Are you sure you would like to remove this user from this ${entityName}?`, [
				{
					label: 'Cancel',
				},
				{
					label: 'Remove',
					primary: true,
					onClick: () => {
						if (isProjectPage) {
							const usersInProject = document.querySelectorAll<HTMLLIElement>(
								'#project-nav-controls .board-component-user-item'
							);
							const removeUserFromUserList = () => {
								usersInProject.forEach((user) => {
									if (user.dataset.uid === userIdToRemove) {
										user.remove();
									}
								});
							};
							removeUserFromProject(getProjectIdFromUrl(), userIdToRemove).then(() => {
								userToRemove.remove();
								clearNonBlockingMessage();
								updateInvitePanelUserCount();
								removeUserFromUserList();
							});
						} else {
							removeUserFromBoard(getBoardIdFromUrl(), userIdToRemove).then(() => {
								userToRemove.remove();
								clearNonBlockingMessage();
								updateInvitePanelUserCount();
							});
						}
					},
				},
			]);
			removeButton.closest('.menu-list')?.previousElementSibling?.classList.remove('button-active');
		} else {
			showAlert(`The ${entityName} owner cannot be removed from this ${entityName}`, [
				{ label: 'OK' },
			]);
		}
	} else {
		showAlert(`Only the ${entityName} owner can remove others`, [{ label: 'OK' }]);
	}
};

const setMenuToCloseOnClickElsewhere = () => {
	const clickHandler = (event: Event) => {
		const target = event.target as HTMLElement;
		if (!target.className.includes) {
			document.removeEventListener('click', clickHandler);
		} else if (!target.className.includes('menu-button')) {
			hideAnyOpenUserMenu();
			document.removeEventListener('click', clickHandler);
		}
	};
	document.addEventListener('click', clickHandler);
};

const userMenuHandler = (e: Event) => {
	e.preventDefault();
	const userButton = e.target as HTMLElement;
	hideAnyOpenUserMenu();
	userButton.classList.add('button-active');
	userButton.nextElementSibling?.setAttribute('style', 'display:block');
	setMenuToCloseOnClickElsewhere();
};

export const addUserToCollaboratorsList = (
	username: string,
	userId: string,
	email: string,
	iconDiv: HTMLDivElement
) => {
	const userName = document.createElement('p');
	userName.innerText = username;

	const userEmail = document.createElement('p');
	userEmail.innerText = email;

	const userInfo = document.createElement('div');
	userInfo.classList.add('user-info');
	userInfo.append(userName, userEmail);

	const userRole = document.createElement('div');
	userRole.classList.add('user-role');
	userRole.innerText = getUserRole(userId);

	const buttonImage = document.createElement('img');
	buttonImage.src = '/assets/icon/dashboard/all.png';
	buttonImage.alt = 'Menu Icon';

	const userMenu = document.createElement('button');
	userMenu.type = 'button';
	userMenu.classList.add('menu-button');
	userMenu.append(buttonImage);
	userMenu.addEventListener('click', userMenuHandler);

	const removeUser = document.createElement('button');
	removeUser.innerText = 'Remove';
	removeUser.type = 'button';
	removeUser.classList.add('remove-user');
	removeUser.addEventListener('click', removeUserHandler);

	const userMenuList = document.createElement('li');
	userMenuList.append(removeUser);

	const menuListWrap = document.createElement('ul');
	menuListWrap.classList.add('menu-list');
	menuListWrap.append(userMenuList);

	const userMenuInner = document.createElement('div');
	userMenuInner.classList.add('user-menu');
	userMenuInner.append(userMenu, menuListWrap);

	const menuOuter = document.createElement('div');
	menuOuter.classList.add('menu-outer');
	menuOuter.append(userRole, userMenuInner);

	const newUserItemInInvite = document.createElement('li');
	newUserItemInInvite.dataset.uid = userId;
	newUserItemInInvite.className = 'board-component-user-item';
	newUserItemInInvite.append(iconDiv, userInfo, menuOuter);

	document
		.querySelector('#invite-container #user-with-access .user-list')!
		.append(newUserItemInInvite);
};

const hideAnyOpenUserMenu = () => {
	const menuList = document.querySelectorAll<HTMLUListElement>(
		'#user-with-access .user-menu .menu-list'
	);
	menuList.forEach((lists) => lists.setAttribute('style', 'display:none'));
	const userMenuButtons = document.querySelectorAll<HTMLElement>('#user-with-access .menu-button');
	userMenuButtons.forEach((menuButton) => menuButton.classList.remove('button-active'));
};

export const createUserSearch = (userSearchInput: HTMLInputElement) => {
	userSearchInput.oninput = async () => {
		const searchTerm = userSearchInput.value.trim();
		const resultsDiv = document.querySelector('#autocomplete-results')!;

		if (searchTerm.length >= 2) {
			const userData = await searchForUser(searchTerm);
			removeChildren(resultsDiv);

			const infoListItem = document.createElement('li');
			infoListItem.classList.add('general-info');

			const infoHolder = document.createElement('div');

			const heading = document.createElement('h4');
			const infoText = document.createElement('p');
			if (userData.filter((u) => !u.username.includes('@')).length === 0) {
				if (userData.length === 0) {
					heading.innerText = 'No matching user found';
					infoText.innerText =
						'However you can still type an email address to invite anyone you know! 🎉';
				}
			} else {
				heading.innerText = 'Invite anyone you know!';
				infoText.innerText =
					'Type any email address - we can send an email invite if not signed up.';
			}
			if (heading.innerText) {
				infoListItem.appendChild(heading);
				infoListItem.appendChild(infoText);
			}

			infoHolder.appendChild(infoListItem);
			resultsDiv.appendChild(infoHolder);
			resultsDiv.classList.add('boxShadow');

			userData.forEach((data) => {
				const userListItem = document.createElement('li');
				userListItem.classList.add('name-select');

				const userIcon = document.createElement('div');
				userIcon.classList.add('header-menu-icon', 'grey');

				const clickableList = document.createElement('a');
				userListItem.appendChild(clickableList);
				clickableList.appendChild(userIcon);

				const userInfo = document.createElement('div');
				clickableList.appendChild(userInfo);

				const userName = document.createElement('h4');
				userInfo.appendChild(userName);

				const userEmail = document.createElement('p');
				userInfo.appendChild(userEmail);

				userName.innerText = data.username;
				userEmail.innerText = data.username.includes('@')
					? `Not yet registered, will be invited to sign up`
					: data.email;
				userIcon.innerText = data.username[0];

				userListItem.onclick = (e) => {
					e.preventDefault();
					userSearchInput.value = data.username;
					userSearchInput.dataset.uid = data.id;
					removeChildren(resultsDiv);
					resultsDiv.classList.remove('boxShadow');
				};
				/**
				 * if user click outside of input / user list remove the list
				 */
				document.addEventListener('click', (e: Event) => {
					const target = e.target as HTMLElement;
					if (target.closest('.userNameInput') === null) {
						removeChildren(resultsDiv);
						resultsDiv.classList.remove('boxShadow');
					}
				});
				resultsDiv.appendChild(userListItem);
			});
		} else {
			removeChildren(resultsDiv);
			resultsDiv.classList.remove('boxShadow');
		}
	};
};

const inviteButton =
	document.querySelector<HTMLElement>('#board-menu-invite') ||
	document.querySelector<HTMLElement>('#invite-button');
if (inviteButton) {
	inviteButton.addEventListener('click', () => {
		closeAllPanels();
		inviteButton.classList.add('buttonActive');
		componentToggle(document.getElementById('invite-container')!);
		/**
		 * Autocompletes username in the invite component based on existing users in the database
		 */
		const userSearchInput = document.querySelector<HTMLInputElement>('#user-search-input')!;
		createUserSearch(userSearchInput);

		updateInvitePanelUserCount();

		const usersInInvitePanel = document.querySelectorAll<HTMLLIElement>(
			'#user-with-access .board-component-user-item'
		);
		usersInInvitePanel.forEach((user) => {
			const userRole = user.querySelector<HTMLElement>('.user-role')!;
			userRole.innerText = getUserRole(user.dataset.uid!);
		});

		const inviteResult = document.querySelector<HTMLDivElement>('.invite-result')!;
		const userInviteButton = document.querySelector<HTMLButtonElement>(
			'#invite-container button[type="submit"]'
		)!;

		const inviteForm = document.querySelector<HTMLFormElement>('#invite-container form')!;
		const inviteAction = inviteForm.action;

		const inviteUserAndUpdateList = function (e: Event) {
			e.preventDefault();
			const userQuery = userSearchInput.value.trim();
			const isEmail = userQuery.includes('@');
			const invitePromise = isEmail
				? inviteWithNonSignedUpEmail(inviteAction, userQuery)
				: inviteUserWithUsername(inviteAction, userQuery);
			invitePromise.then((data) => {
				const resultsDiv = document.querySelector('#autocomplete-results')!;
				removeChildren(resultsDiv);
				resultsDiv.classList.remove('boxShadow');

				const { success, message, email, id } = data;
				inviteResult.innerText = message;
				if (success) {
					inviteResult.classList.add('success');
					inviteResult.classList.remove('warning');
					inviteResult.classList.remove('copied');

					if (isProjectPage) {
						addUserToCollaboratorsList(
							userQuery,
							id,
							email,
							createUserIconElement(userQuery, id, 'dark')
						);

						updateInvitePanelUserCount();
					} else {
						// Collaborators list will be updated via ShareDB
						tutorialEvents.invitedCollaborator();
					}
				} else {
					inviteResult.classList.remove('success');
					inviteResult.classList.remove('copied');
					inviteResult.classList.add('warning');
				}
			});
		};

		const boardMenuInvite = document.querySelector<HTMLButtonElement>('#board-menu-invite');
		if (boardMenuInvite) {
			userInviteButton.onclick = inviteUserAndUpdateList;
		}

		const userMenuButtons = document.querySelectorAll<HTMLElement>(
			'#user-with-access .menu-button'
		);
		userMenuButtons.forEach((userButton) => {
			userButton.onclick = userMenuHandler;
		});

		// Add handlers for remove user menu options
		document
			.querySelectorAll<HTMLButtonElement>('#user-with-access .user-menu .menu-list .remove-user')
			.forEach((removeButton) => {
				removeButton.onclick = removeUserHandler;
			});
		console.log('invite');
		// Add handlers for board access requests
		document
			.querySelectorAll<HTMLLIElement>('#access-requests .board-component-user-item')
			.forEach((requestItem) => {
				console.log(0);
				const requestId = requestItem.dataset.uid!;
				const boardId = getBoardIdFromUrl();
				const acceptButton = requestItem.querySelector<HTMLButtonElement>('.accept')!;
				const declineButton = requestItem.querySelector<HTMLButtonElement>('.decline')!;
				console.log(acceptButton);
				acceptButton.addEventListener(
					'click',
					decideAccessHandler(requestItem, boardId, requestId, true)
				);
				declineButton.addEventListener(
					'click',
					decideAccessHandler(requestItem, boardId, requestId, false)
				);
			});

		const projectInviteButton = document.querySelector<HTMLButtonElement>(
			'#project-nav-controls #invite-button'
		);
		if (projectInviteButton) {
			projectInviteButton.onclick = () => {
				componentToggle(document.getElementById('invite-container')!);
			};
			userInviteButton.onclick = inviteUserAndUpdateList;
		}

		tutorialEvents.openedInvite();
	});
}
