import {
	activateCurrentSubscription,
	cancelCurrentSubscription,
	getFullSubscriptionPlan,
	suspendCurrentSubscription,
	updateSubscription,
	changeSubscription,
	createSubscription,
} from '../../lib/apiRequests';
import { loadScript, PayPalNamespace } from '@paypal/paypal-js';
import { finishLoading, removeChildren, showAlert, startLoading } from '../../lib/ui/uiUtils';
import { formatCurrency, formatPercent } from '../../../../src/commonMisc';
import { detectIncognito } from 'detectincognitojs';

let paypal: PayPalNamespace | null = null;
loadScript({
	clientId: document.body.dataset.paypalClientId!,
	vault: true,
	intent: 'subscription',
})
	.then((result) => (paypal = result))
	.catch((error) => {
		console.error('failed to load the PayPal JS SDK script', error);
	});

const levelInputs = document.querySelectorAll<HTMLInputElement>("input[name='level']");
const paymentPlanInputs = document.querySelectorAll<HTMLInputElement>("input[name='payment-plan']");
const teamMembersSelect = document.querySelector<HTMLSelectElement>('.team-members');

const subscriptionContainer = document.querySelector<HTMLDivElement>('#subscription-container')!;

const isUpgradingExistingSubscription = () => {
	const currentPlan = subscriptionContainer.dataset.currentPlan;
	const cancelationDate = subscriptionContainer.dataset.cancelDate;
	return Boolean(currentPlan) && !cancelationDate;
};

const getExistingSubscriptionSeats = () => {
	const currentSeats =
		document.querySelector<HTMLDivElement>('#subscription-container')!.dataset.currentSeats;
	return currentSeats ? parseInt(currentSeats) : 1;
};

const createPlanButton = (planId: string, quantity?: string) => {
	if (paypal?.Buttons) {
		removeChildren(document.querySelector('#paypal-button-container')!);
		if (isUpgradingExistingSubscription()) {
			paypal
				.Buttons({
					createSubscription: function () {
						return changeSubscription(planId, quantity)
							.then(({ approveLink, subscriptionId }) => {
								console.log(approveLink);
								return subscriptionId;
							})
							.catch((error) => {
								// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
								window.alert('Failed to create subscription: ' + error.message);
								return '';
							});
					},
					onApprove: async function (data) {
						console.log(data);
						startLoading();
						if (data.subscriptionID && data.orderID) {
							console.log(
								`You have successfully updated subscription ${data.subscriptionID} (order ${data.orderID})`
							);
							await updateSubscription(document.body.dataset.userId!, data.subscriptionID);
							window.location.reload();
						}
					},
				})
				.render(`#paypal-button-container`);
		} else {
			paypal
				.Buttons({
					createSubscription: function () {
						return createSubscription(planId, quantity);
					},
					onApprove: async function (data) {
						if (data.subscriptionID && data.orderID) {
							startLoading();
							console.log(
								`You have successfully created subscription ${data.subscriptionID} (order ${data.orderID})`
							);
							await updateSubscription(document.body.dataset.userId!, data.subscriptionID);
							window.location.reload();
						}
					},
				})
				.render(`#paypal-button-container`);
		}
	}
};

const update = () => {
	if (!teamMembersSelect) {
		return;
	}

	const paymentPreview = document.querySelector('#payment-preview')!;
	const aboveFirstNotice = document.querySelector('.above-first-notice')!;
	const loadingPlan = document.querySelector('.loading-plan')!;

	const hideCheckout = () => {
		aboveFirstNotice.classList.remove('hidden');
		paymentPreview.classList.add('hidden');
		removeChildren(document.querySelector('#paypal-button-container')!);
	};

	const selectedLevel = Array.from(levelInputs).find((i) => i.checked);
	const selectedPaymentPlan = Array.from(paymentPlanInputs).find((i) => i.checked);

	if (selectedLevel) {
		if (isUpgradingExistingSubscription()) {
			const seats = getExistingSubscriptionSeats();
			const tooFewSeats =
				seats > 1 && (selectedLevel.value !== 'paid' || seats > parseInt(teamMembersSelect.value));

			document.querySelector('#too-few-seats-notice')?.classList.toggle('hidden', !tooFewSeats);
		}
	}

	if (selectedLevel && selectedPaymentPlan) {
		const paymentPlanInputs = Array.from(
			document.querySelectorAll<HTMLInputElement>('.subscription-plan')
		);
		const activePlan = paymentPlanInputs.find(
			(plan) =>
				plan.dataset.role === selectedLevel.value &&
				plan.dataset.frequency === selectedPaymentPlan.value
		);

		if (activePlan) {
			hideCheckout();
			aboveFirstNotice.classList.add('hidden');
			loadingPlan.classList.remove('hidden');
			const quantity = activePlan.dataset.role === 'paid' ? parseInt(teamMembersSelect.value) : 1;
			const hasQuantity = activePlan.dataset.role === 'paid';

			getFullSubscriptionPlan(activePlan.value, quantity).then(({ price, discount, frequency }) => {
				const frequencyString =
					selectedPaymentPlan.value === 'DAY'
						? 'every two days'
						: selectedPaymentPlan.value === 'MONTH'
							? 'month'
							: 'year';
				let summaryMessage = `On this <strong>${activePlan.dataset
					.name!}</strong> plan you pay <strong>${formatCurrency(
					frequency === 'YEAR' ? price / 12 : price
				)}</strong>${hasQuantity ? ' per team member' : ''} per ${frequency === 'YEAR' ? 'month' : frequencyString}`;
				if (discount) {
					summaryMessage += `<br><br>This includes a <strong>multi-user discount of ${formatPercent(
						discount
					)}</strong>`;
					if (frequencyString === 'year') {
						summaryMessage += ', plus an extra discount for paying annually';
					}
					summaryMessage += '.';
				} else {
					if (frequencyString === 'year') {
						summaryMessage += `<br><br>This includes a discount for paying annually.`;
					}
				}
				if (hasQuantity) {
					summaryMessage += `<br><br>
	For <strong>${quantity} team members</strong>, the total cost will be <strong>${formatCurrency(
		quantity * (frequency === 'YEAR' ? price / 12 : price)
	)}</strong> per ${frequency === 'YEAR' ? 'month' : frequencyString}.
	After subscribing, you can pay for additional team members at any time. You can also reduce the number of team members on your plan, and your bill will be reduced at the beginning of the next billing ${frequencyString}.
	<br><br>
	<small>If you have a team of 12 or more members, please <contact-us></contact-us> to discuss our <strong>Enterprise plan</strong> which is best for larger teams.</small>
`;
				}
				paymentPreview.querySelector('p')!.innerHTML = summaryMessage;
				paymentPreview.classList.remove('hidden');
				createPlanButton(
					activePlan.value,
					activePlan.dataset.role === 'paid' ? teamMembersSelect.value : undefined
				);
				loadingPlan.classList.add('hidden');
			});
		}
	} else {
		hideCheckout();
	}
};

document.querySelector('.cancel-subscription')?.addEventListener('click', () => {
	const nextPaymentDate = document.querySelector<HTMLElement>('#next-payment-date');
	showAlert(
		`Are you sure you want to cancel your subscription?<br><br>
<small>${
			nextPaymentDate
				? `After cancellation, you will retain access to your current plan until the end of your current billing period (${nextPaymentDate.innerHTML}). You would then revert to a free plan with limited features.`
				: ''
		}</small>
`,
		[
			{
				label: 'Yes, cancel my subscription',
				onClick: () => {
					cancelCurrentSubscription(document.body.dataset.userId!).then(() => {
						startLoading();
						window.location.reload();
					});
				},
			},
			{
				primary: true,
				label: 'Not now',
			},
		]
	);
});

document.querySelector('.suspend-subscription')?.addEventListener('click', () => {
	showAlert(
		`Would you like to suspend your subscription for 1 month?<br><br>
<small>After suspending, you will not be billed next month, and revert to the free plan. Your subscription will automatically be re-activated after 1 month.</small>
`,
		[
			{
				label: 'Not now',
			},
			{
				primary: true,
				label: 'Yes, suspend for 1 month',
				onClick: () => {
					suspendCurrentSubscription(document.body.dataset.userId!).then(() => {
						startLoading();
						window.location.reload();
					});
				},
			},
		]
	);
});

document.querySelector('.reactivate-subscription')?.addEventListener('click', () => {
	showAlert(
		`Would you like to re-activate your subscription?<br><br>
<small>This will resume billing for your subscription</small>
`,
		[
			{
				label: 'Not now',
			},
			{
				primary: true,
				label: 'Yes, re-activate subscription',
				onClick: () => {
					activateCurrentSubscription(document.body.dataset.userId!).then(() => {
						startLoading();
						window.location.reload();
					});
				},
			},
		]
	);
});

const purchaseSubscriptionHandler = () => {
	document.querySelector('#subscription-container')!.classList.remove('hidden');
	document.querySelector('#backdrop')!.classList.remove('hidden');

	detectIncognito().then((result) => {
		if (result.isPrivate && document.querySelector('.private-browser-warning') === null) {
			const privateBrowsingWarning = document.createElement('p');
			privateBrowsingWarning.classList.add('system-warning');
			privateBrowsingWarning.classList.add('private-browser-warning');
			privateBrowsingWarning.innerText = `PayPal checkout may not work correctly in private browsing / incognito mode. We recommend opening this page in a normal browser tab to complete your purchase.`;
			document.querySelector('#subscription-container h2')!.after(privateBrowsingWarning);
		}
	});
};

document
	.querySelector('.purchase-subscription')
	?.addEventListener('click', purchaseSubscriptionHandler);
document
	.querySelector('.change-subscription')
	?.addEventListener('click', purchaseSubscriptionHandler);

[...levelInputs, ...paymentPlanInputs, teamMembersSelect].forEach((input) => {
	if (input) {
		input.addEventListener('change', update);
	}
});

const toggleFeaturesButton = document.querySelector<HTMLButtonElement>('#toggle-features');
toggleFeaturesButton?.addEventListener('click', () => {
	const plans = document.querySelector<HTMLDivElement>('.plans');
	if (plans) {
		if (plans.classList.contains('hidden')) {
			plans.classList.remove('hidden');
			toggleFeaturesButton.innerHTML = 'Hide features';
		} else {
			plans.classList.add('hidden');
			toggleFeaturesButton.innerHTML = 'Show features';
		}
	}
});

finishLoading();
