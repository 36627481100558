import { createRect, updateFontFamily } from './slideLayoutUtils';

const hexToRgb = (hex: string): { red: number; green: number; blue: number } => {
	const red = parseInt(hex.slice(1, 3), 16) / 255;
	const green = parseInt(hex.slice(3, 5), 16) / 255;
	const blue = parseInt(hex.slice(5, 7), 16) / 255;

	return {
		red,
		green,
		blue,
	};
};

export const setupFlowCreateTheme = (
	presentation: gapi.client.slides.Presentation
): Array<gapi.client.slides.Request> => {
	const requests: Array<gapi.client.slides.Request> = [];

	if (presentation.masters) {
		const masterSlide = presentation.masters[0];

		console.log(`Master theme: ${masterSlide.masterProperties?.displayName}`);

		if (
			!masterSlide.masterProperties?.displayName ||
			masterSlide.masterProperties?.displayName === 'Simple Light'
		) {
			requests.push({
				updatePageProperties: {
					objectId: masterSlide.objectId,
					pageProperties: {
						colorScheme: {
							colors: [
								{
									type: 'DARK1',
									color: hexToRgb('#ef6c00'),
								},
								{
									type: 'LIGHT1',
									color: hexToRgb('#ffffff'),
								},
								{
									type: 'DARK2',
									color: hexToRgb('#595959'),
								},
								{
									type: 'LIGHT2',
									color: hexToRgb('#eeeeee'),
								},
								{
									type: 'ACCENT1',
									color: hexToRgb('#a1e8d9'),
								},
								{
									type: 'ACCENT2',
									color: hexToRgb('#ce93d8'),
								},
								{
									type: 'ACCENT3',
									color: hexToRgb('#4db6ac'),
								},
								{
									type: 'ACCENT4',
									color: hexToRgb('#ff9800'),
								},
								{
									type: 'ACCENT5',
									color: hexToRgb('#009668'),
								},
								{
									type: 'ACCENT6',
									color: hexToRgb('#eeff41'),
								},
								{
									type: 'HYPERLINK',
									color: hexToRgb('#009668'),
								},
								{
									type: 'FOLLOWED_HYPERLINK',
									color: hexToRgb('#007648'),
								},
							],
						},
						// pageBackgroundFill: {
						// 	solidFill: {
						// 		color: {
						// 			rgbColor: {
						// 				red: 0.0,
						// 				green: 0.0,
						// 				blue: 0.0,
						// 			},
						// 		},
						// 	},
						// },
					},
					fields: 'colorScheme',
				},
			});

			requests.push(updateFontFamily(masterSlide.pageElements![0].objectId!, 'PT Sans Narrow'));

			requests.push(
				...createRect(masterSlide.objectId!, 0, 390, 720, 15, { themeColor: 'ACCENT1' })
			);
		}
	}

	return requests;
};
