import dialogPolyfill from 'dialog-polyfill';
import { EngineId, BoardUser, GraphMode, NCNode } from '../../../../src/commonTypes';
import { ENGINE_TYPES } from '../../../../src/commonConstants';
import InspireGraph from '../graphs/InspireGraph';
import { closeAiContainers } from './toolsUI';
import { IdeateGraph } from '../graphs';
import {
	addedWordsFromPanel,
	openedAddToBoardPanel,
	openedProjectVisionPanel,
} from '../tutorials/tutorialEvents';
import { isTutorialActive } from '../tutorials/tutorial';
import { sleep } from '../misc';
import tippy, { Placement, roundArrow } from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/svg-arrow.css';
import { synthesiseAi, updateBoardVisionAndSummary } from '../apiRequests';
import { addUserToCollaboratorsList, updateInvitePanelUserCount } from './inviteUser';

/**
 * Toggles the visibility of a component based on its class
 */
export const componentToggle = (component: Element) => {
	component.classList.toggle('hidden');
};

export const removeChildren = (element: Element) => {
	if (element.isConnected) {
		while (element.firstChild) {
			element.removeChild(element.firstChild);
		}
	}
};

const loadingIndicatorPanel = document.getElementById('loading-indicator');
let isStickyMessage = false;
export const startLoading = async (loadingMessage = 'Loading...', setStickyMessage = false) => {
	if (loadingIndicatorPanel) {
		if (loadingMessage) {
			if (!isStickyMessage || setStickyMessage) {
				loadingIndicatorPanel.querySelector('h3')!.innerText = loadingMessage;
			}
			if (setStickyMessage) {
				isStickyMessage = true;
			}
		}
		loadingIndicatorPanel.classList.remove('hidden');
		await sleep(0); // Give browser a chance to render the panel before moving on
	}
};

export const finishLoading = () => {
	isStickyMessage = false;
	loadingIndicatorPanel?.classList.add('hidden');
};

export const clearNonBlockingMessage = () => {
	const messageContainer = document.querySelector('.message-show');
	if (messageContainer) {
		const messageWindow = messageContainer.querySelector<HTMLDialogElement>('dialog')!;
		messageWindow.open = false;
		messageContainer.remove();
	}
};

export type ButtonDefinition = {
	label: string;
	onClick?: () => void | Promise<void>;
	primary?: boolean;
	iconUrl?: string;
	disabled?: boolean;
	minWidth?: string;
};

export const createButtons = (buttons: Array<ButtonDefinition> = [], isAlert = false) => {
	return buttons.map(({ label, onClick, primary, iconUrl, disabled, minWidth }) => {
		const button = document.createElement('button');
		if (iconUrl) {
			const img = document.createElement('img');
			img.src = iconUrl;
			img.height = 24;
			button.appendChild(img);
		}
		if (minWidth) {
			button.style.minWidth = minWidth;
		}
		button.appendChild(document.createTextNode(label));
		if (primary) {
			button.classList.add('primary');
		}
		if (disabled) {
			button.disabled = true;
		}
		button.addEventListener('click', () => {
			if (isAlert) {
				closeAlert();
			}
			if (onClick) {
				onClick();
			}
		});
		return button;
	});
};

function fadeOutEffect(fadeTarget: HTMLElement) {
	const fadeEffect = setInterval(function () {
		if (!fadeTarget.style.opacity) {
			fadeTarget.style.opacity = '1';
		}
		if (parseFloat(fadeTarget.style.opacity) > 0) {
			fadeTarget.style.opacity = `${parseFloat(fadeTarget.style.opacity) - 0.1}`;
		} else {
			clearInterval(fadeEffect);
			clearNonBlockingMessage();
		}
	}, 200);
}

export const showNonBlockingMessage = (
	message: string,
	buttons: Array<ButtonDefinition> = [],
	fading = false
) => {
	clearNonBlockingMessage();

	const messageWindow = document.createElement('dialog');
	dialogPolyfill.registerDialog(messageWindow);
	messageWindow.open = true;
	messageWindow.classList.add('message-window');

	const nonBlockingMessage = document.createElement('p');
	nonBlockingMessage.classList.add('message');
	nonBlockingMessage.innerHTML = message;
	messageWindow.append(nonBlockingMessage);

	if (buttons.length) {
		const alertButtons = document.createElement('div');
		alertButtons.classList.add('alert-buttons');
		alertButtons.append(...createButtons(buttons));
		messageWindow.append(alertButtons);
	}

	const messageContainer = document.createElement('div');
	messageContainer.classList.add('message-show');
	messageContainer.append(messageWindow);
	document.querySelector('main')!.prepend(messageContainer);

	if (fading) {
		setTimeout(() => {
			fadeOutEffect(messageContainer);
		}, 10000);
	}
};

export const closeAlert = () => {
	const alertContainer = document.querySelector('.alert-show');
	if (alertContainer) {
		const alertWindow = alertContainer.querySelector<HTMLDialogElement>('dialog')!;
		alertContainer.classList.remove('alert-show');
		alertContainer.classList.add('alert-hide');
		alertWindow.open = false;
	}
};

const getCloseButton = () => {
	const closeIcon = document.createElement('img');
	closeIcon.src = '/assets/icon/board/close.png';

	const closeButton = document.createElement('button');
	closeButton.classList.add('alert-close');
	closeButton.appendChild(closeIcon);
	closeButton.addEventListener('click', closeAlert);
	return closeButton;
};

export const isReadyForNonEssentialAlert = (): boolean => {
	const inviteContainer = document.querySelector('#invite-container')!;
	if (!inviteContainer.classList.contains('hidden')) {
		return false;
	}

	const exportContainer = document.querySelector('#export-container')!;
	if (!exportContainer.classList.contains('hidden')) {
		return false;
	}

	const guideContainer = document.querySelector('#guide')!;
	if (!guideContainer.classList.contains('hidden')) {
		return false;
	}

	const alertContainer = document.querySelector('.alert-show');
	const messageContainer = document.querySelector('.message-show');

	const graph = globalThis.neuroCreate.graph;

	return (
		!alertContainer && !messageContainer && (graph ? !isTutorialActive(graph.graphMode) : true)
	);
};

export const showAlert = (message: string, buttons: Array<ButtonDefinition> = []) => {
	const alertMessage = document.createElement('p');
	alertMessage.classList.add('alert-message');
	alertMessage.innerHTML = message;

	createDialog('', [alertMessage], createButtons(buttons, true));
};

export const showProjectVisionPanel = (input: string) => {
	// Show panel with project vision and project summary fields
	// We will fill these in automatically based on the user's prior input
	// Users will have the choice to edit these fields
	// They can also go back to the previous step to edit their input
	// Or they can hit 'Next' to proceed to the next step

	const messageP = document.createElement('p');
	messageP.classList.add('minimal-message');
	messageP.innerHTML =
		'Based on your previous input, we have generated a project vision and project summary for you. Feel free to edit these as you see fit.';

	const projectVisionHeading = document.createElement('h5');
	projectVisionHeading.innerText = 'Project vision';

	const projectVisionField = document.createElement('text-field');
	projectVisionField.rows = 4;

	const projectSummaryHeading = document.createElement('h5');
	projectSummaryHeading.innerText = 'Project summary';

	const projectSummaryField = document.createElement('text-field');

	setTimeout(() => {
		addToolTipWithTitle(projectVisionField._retryButton);
		addToolTipWithTitle(projectSummaryField._retryButton);
	}, 100);

	const buttons = createButtons(
		[
			{
				label: 'Back',
				disabled: true,
				onClick: () => {
					showWordInputPanel(input);
				},
			},
			{
				label: 'Next',
				onClick: async () => {
					showCentralNodeSelectPanel(
						input,
						projectVisionField._textField.value,
						projectSummaryField._textField.value
					);
					await updateBoardVisionAndSummary(
						document.body.dataset.boardId!,
						input,
						projectVisionField._textField.value,
						projectSummaryField._textField.value
					);
				},
				primary: true,
				disabled: true,
			},
		],
		true
	);

	projectVisionField.load = async () => {
		if (globalThis.neuroCreate.graph?.getTemplateId() === 'branding') {
			await sleep(1000);
			return Promise.resolve(
				'The project vision is to create a captivating and innovative experience that bridges the worlds of whiskey and music, catering to the needs of enthusiasts looking to explore the legendary connection between these two art forms. By intertwining the rich history of whiskey with the creativity of famous musicians, the project aims to inspire and engage audiences in a unique and memorable way.'
			);
		} else {
			return synthesiseAi(document.body.dataset.boardId!, 'Vision', input).then(({ result }) => {
				return result;
			});
		}
	};

	projectSummaryField.load = async () => {
		if (globalThis.neuroCreate.graph?.getTemplateId() === 'branding') {
			await sleep(1000);
			return Promise.resolve(
				'Create an innovative experience explore the legendary connection between whiskey and famous musicians.'
			);
		} else {
			return synthesiseAi(
				document.body.dataset.boardId!,
				'Extract',
				projectVisionField._textField.value
			).then(({ result }) => {
				return result;
			});
		}
	};

	setTimeout(() => {
		projectVisionField.reload().then(() => {
			projectSummaryField.reload().then(() => {
				buttons.forEach((button) => {
					button.removeAttribute('disabled');
				});
			});
		});
	}, 100);

	createDialog(
		'Project vision',
		[
			messageP,
			projectVisionHeading,
			projectVisionField,
			projectSummaryHeading,
			projectSummaryField,
			document.createElement('br'),
			document.createElement('br'),
			document.createElement('br'),
		],
		buttons
	);

	openedProjectVisionPanel();
};

export const createDialog = (
	title: string,
	content: Array<HTMLElement>,
	buttons: Array<HTMLButtonElement>
) => {
	const dialog = document.createElement('dialog');
	dialogPolyfill.registerDialog(dialog);
	dialog.open = true;
	dialog.classList.add('alert-window');
	dialog.append(getCloseButton());

	if (title) {
		const heading = document.createElement('h3');
		heading.innerHTML = title;
		dialog.append(heading);
	}

	dialog.append(...content);

	const alertButtons = document.createElement('div');
	alertButtons.classList.add('alert-buttons');
	alertButtons.append(...buttons);
	dialog.append(alertButtons);

	const windowContainer = document.createElement('div');
	windowContainer.classList.add('alert-show');
	windowContainer.classList.remove('alert-hide');
	windowContainer.append(dialog);

	document.querySelector('main')!.prepend(windowContainer);

	dialog.focus();
};

export const showCentralNodeSelectPanel = (input: string, vision: string, summary: string) => {
	const messageP = document.createElement('p');
	messageP.classList.add('minimal-message');
	messageP.innerHTML =
		'Please select one of the options from the previous steps. This will be added to the board along with some keywords from your input to get you started.';

	const projectInputRadio = document.createElement('input');
	projectInputRadio.type = 'radio';
	projectInputRadio.name = 'central-node';
	projectInputRadio.value = input;

	const projectInputLabel = document.createElement('label');
	projectInputLabel.append(projectInputRadio);
	projectInputLabel.append('Your initial input');
	projectInputLabel.classList.add('initial-input');

	const projectInputParagraph = document.createElement('p');
	projectInputParagraph.classList.add('text-preview-full-width');
	projectInputParagraph.innerText = input;

	const projectVisionRadio = document.createElement('input');
	projectVisionRadio.type = 'radio';
	projectVisionRadio.name = 'central-node';
	projectVisionRadio.value = vision;

	const projectVisionLabel = document.createElement('label');
	projectVisionLabel.append(projectVisionRadio);
	projectVisionLabel.append('Project vision');

	const projectVisionParagraph = document.createElement('p');
	projectVisionParagraph.classList.add('text-preview-full-width');
	projectVisionParagraph.innerText = vision;

	const projectSummaryRadio = document.createElement('input');
	projectSummaryRadio.type = 'radio';
	projectSummaryRadio.name = 'central-node';
	projectSummaryRadio.checked = true;
	projectSummaryRadio.value = summary;

	const projectSummaryLabel = document.createElement('label');
	projectSummaryLabel.append(projectSummaryRadio);
	projectSummaryLabel.append('Project summary');

	const projectSummaryParagraph = document.createElement('p');
	projectSummaryParagraph.classList.add('text-preview-full-width');
	projectSummaryParagraph.innerText = summary;

	const buttons = createButtons(
		[
			{
				label: 'Add to board',
				onClick: async () => {
					const selectedText = document.querySelector<HTMLInputElement>(
						'input[name="central-node"]:checked'
					)!.value;
					const inspireGraph = globalThis.neuroCreate.graph as InspireGraph;
					await inspireGraph.addWordsToBoard(selectedText, selectedText);
					addedWordsFromPanel(inspireGraph.nodes);
					inspireGraph.zoomToFit();
					setTimeout(() => {
						inspireGraph.zoomToFit();
					}, 500);
				},
				primary: true,
			},
		],
		true
	);

	createDialog(
		'Add to board',
		[
			messageP,
			projectSummaryLabel,
			projectSummaryParagraph,
			projectVisionLabel,
			projectVisionParagraph,
			projectInputLabel,
			projectInputParagraph,
		],
		buttons
	);

	openedAddToBoardPanel();
};

export const showWordInputPanel = (input?: string) => {
	const messageP = document.createElement('p');
	messageP.classList.add('minimal-message');
	messageP.innerHTML =
		"Write a paragraph of a minimum of 10 words describing your project, intention, strategy, content or initial ideas you'd like to expand on.";

	const summaryDetails = document.createElement('details');
	const summary = document.createElement('summary');
	summary.innerText = 'What should I write?';
	summaryDetails.innerHTML =
		'This can be a "stream-of-consciousness" activity, where you are making sure to put in the key terms and concepts you\'d like to explore.' +
		'<br><br>' +
		'It can also be more tailored where you can think of yourself as an artist or thematic curator where you can think of your inputs as prompts to shape the AI process, for a symbiotic output.';
	summaryDetails.appendChild(summary);

	const wordCountP = document.createElement('p');
	wordCountP.className = 'word-count';
	wordCountP.innerText = `0 / 10 words`;

	const wordsTextArea = document.createElement('textarea');
	wordsTextArea.rows = 3;
	wordsTextArea.autofocus = true;
	if (input) {
		wordsTextArea.value = input;
	}
	wordsTextArea.oninput = () => {
		const wordcount = wordsTextArea.value.split(' ').filter((s) => s).length;
		wordCountP.innerText = `${wordcount} / 10 words`;
	};

	const buttons = createButtons(
		[
			{
				label: 'Skip',
			},
			{
				label: 'Next',
				onClick: () => {
					showProjectVisionPanel(wordsTextArea.value);
				},
				primary: true,
			},
		],
		true
	);

	createDialog(
		isTutorialActive(globalThis.neuroCreate.graph?.graphMode || 'inspire')
			? `Tutorial: let's begin!`
			: `Let's begin`,
		[messageP, summaryDetails, wordsTextArea, wordCountP],
		buttons
	);
};

export const showTooltip = (message: string) => {
	showAlert(message);
};

export const closeAllPanels = (excludeId = '') => {
	document.querySelectorAll('#top-menu-wrapper button').forEach((button) => {
		if (button !== document.getElementById('timerButton')) {
			button.classList.remove('buttonActive');
		}
	});

	document.querySelectorAll('.panel').forEach((panel) => {
		if (!excludeId || panel.id !== excludeId) {
			panel.classList.add('hidden');
		}
	});
};

export const updateUsersOnlineStatus = (boardUsers: Array<BoardUser>) => {
	const boardUserList = document.querySelector<HTMLUListElement>('.board-user-icon-list')!;
	boardUsers.forEach((boardUser) => {
		const userListItem = document.querySelector<HTMLLIElement>(
			`.board-component-user-item[data-uid="${boardUser.id}"]`
		);
		if (userListItem) {
			const link = userListItem.querySelector<HTMLAnchorElement>('a');
			if (link) {
				if (boardUser.isOnline) {
					userListItem.classList.add('online');
					userListItem.classList.remove('offline');
					link.title = `${boardUser.username} (online)`;
				} else {
					userListItem.classList.add('offline');
					userListItem.classList.remove('online');
					link.title = `${boardUser.username} (offline)`;
				}
			}
		} else {
			const newUserDiv = createUserIconElement(boardUser.username, boardUser.id, boardUser.colour);

			const newUserItem = document.createElement('li');
			newUserItem.className = 'board-component-user-item';
			newUserItem.classList.add(boardUser.isOnline ? 'online' : 'offline');
			newUserItem.dataset.uid = boardUser.id;
			newUserItem.dataset.colour = boardUser.colour;
			newUserItem.dataset.username = boardUser.username;
			newUserItem.append(newUserDiv);

			boardUserList.append(newUserItem);

			addUserToCollaboratorsList(
				boardUser.username,
				boardUser.id,
				boardUser.email,
				createUserIconElement(boardUser.username, boardUser.id, boardUser.colour)
			);
		}
	});
	boardUserList.style.width = `${24 + boardUsers.length * 11}px`;

	// Remove any users that were removed from the board
	const userIds = boardUsers.map((u) => u.id);
	document
		.querySelectorAll<HTMLLIElement>(
			`#toolbar-menu-wrapper .board-component-user-item, #user-with-access li.board-component-user-item`
		)
		.forEach((li) => {
			if (li.dataset.uid && !userIds.includes(li.dataset.uid)) {
				li.remove();
			}
		});
	updateInvitePanelUserCount();
};

export const getUserByColour = (colour: NCNode['colour']) => {
	const userItems = document.querySelectorAll<HTMLLIElement>('.board-component-user-item');
	const ownerUserItem = Array.from(userItems).find((item) => item.dataset.colour === colour);
	if (ownerUserItem) {
		return {
			username: ownerUserItem.dataset.username!,
			uid: ownerUserItem.dataset.uid!,
		};
	}
	return undefined;
};

export const updatedBoardEngine = (engineId: EngineId) => {
	closeAiContainers();

	// Update Engine icon in board menu
	document.body.dataset.engine = engineId;
	const engineButton = document.querySelector<HTMLButtonElement>('#engine-select')!;
	const img = document.querySelector<HTMLImageElement>('#engine-select img')!;

	engineButton.classList.add(`border-${engineId.toLowerCase()}`);
	img.src = `/assets/icon/dashboard/${engineId.toLowerCase()}.png`;
	img.title = `${
		ENGINE_TYPES[engineId.toUpperCase() as keyof typeof ENGINE_TYPES].name
	} Engine. Click to change engine.`;

	const graph = globalThis.neuroCreate.graph;
	if (graph?.graphMode === 'ideate') {
		const ideateGraph = graph as IdeateGraph;
		ideateGraph.refreshCurrentAnalysis();
	}
};

export const updatedBoardTitle = (title: string) => {
	document.querySelector<HTMLInputElement>('#board-title-form input')!.value = title;
};

export const createUserIconElement = (
	username: string,
	userId: string,
	colour: string
): HTMLDivElement => {
	const newUserLink = document.createElement('a');
	newUserLink.classList.add('no-underline');
	newUserLink.title = username;
	newUserLink.innerHTML = username.substring(0, 1);

	const newUserDiv = document.createElement('div');
	newUserDiv.classList.add('header-menu-icon');
	newUserDiv.classList.add(colour);
	newUserDiv.append(newUserLink);

	return newUserDiv;
};

export const getGraphModeFromHash = (): [mode: GraphMode, isCollaborative: boolean] => {
	switch (window.location.hash) {
		case '#ideate':
			return ['ideate', false];
		case '#ideate-team':
			return ['ideate', true];
		case '#synthesise':
			return ['moodboard', false];
		case '#synthesise-team':
			return ['moodboard', true];
		case '#inspire':
		default:
			return ['inspire', true];
	}
};

export const handleHashRoute = () => {
	const [graphMode, isCollaborative] = getGraphModeFromHash();

	if (
		globalThis.neuroCreate.graph &&
		globalThis.neuroCreate.graph.graphMode === graphMode &&
		globalThis.neuroCreate.graph.isCollaborative === isCollaborative
	) {
		return;
	}

	if (globalThis.neuroCreate.switchToMode) {
		globalThis.neuroCreate.switchToMode(graphMode, isCollaborative, false);
	}

	if (window.location.hash === '#collaborators') {
		setTimeout(() => {
			document.querySelector<HTMLButtonElement>('#board-menu-invite')!.click();
		}, 500);
	}
};

export const getBoardIdFromUrl = (inputUrl?: string): string => {
	const url = (inputUrl || window.location.toString()).replace(/#.*$/, '');
	if (!url.includes('/board/')) {
		throw new Error('This is not a board page');
	}
	const boardId = url.substring(url.lastIndexOf('/') + 1);
	return boardId;
};

export const getProjectIdFromUrl = (inputUrl?: string): string => {
	const url = (inputUrl || window.location.toString()).replace(/#.*$/, '');
	if (!url.includes('/project/')) {
		throw new Error('This is not a project page');
	}
	const projectId = url.substring(url.lastIndexOf('/') + 1);
	return projectId;
};

export const addToolTipWithTitle = (
	element: string | HTMLButtonElement | HTMLLabelElement,
	placement: Placement = 'bottom'
): void => {
	tippy(typeof element === 'string' ? element : [element], {
		content: (reference) => {
			const title = reference.getAttribute('title') || '';
			reference.removeAttribute('title');

			return title;
		},
		delay: [300, 100],
		offset: placement === 'bottom' ? [0, 15] : [0, 5],
		duration: [1000, 200],
		placement,
		arrow: placement === 'bottom' ? roundArrow : false,
		theme: 'neuro',
		touch: ['hold', 200],
	});
};

/**
 * add tooltips to all elements which have a title attribute
 */
addToolTipWithTitle('[title]:not(#board-zoom-controls button)');
addToolTipWithTitle('#board-zoom-controls button', 'top');
