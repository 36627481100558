import Graph from './Graph';
import * as d3 from 'd3';
import { NCGroup, NCNode } from '../../../../src/commonTypes';
import { D3DragEvent } from 'd3';
import { ensurePhysicsAlphaIsHighEnough, resetPhysicsMovement } from './graphPhysics';

export const MIN_MOVE_DISTANCE = 10;

export const initGroupDragBehavior = (graph: Graph) => {
	let movedGroup: boolean | undefined;
	let moveStartX: number | undefined;
	let moveStartY: number | undefined;
	let lastX: number | undefined;
	let lastY: number | undefined;
	let nodesBefore: NCNode[] = [];

	const groupDragBehavior = d3
		.drag<SVGGElement, NCGroup>()
		.clickDistance(MIN_MOVE_DISTANCE)
		.on('start', (event: D3DragEvent<SVGGElement, NCGroup, NCGroup>, g) => {
			movedGroup = false;
			moveStartX = event.x;
			moveStartY = event.y;
			lastX = event.x;
			lastY = event.y;
			nodesBefore = graph.nodes.map((node) => ({ ...node }));
			// Unfix the nodes
			g.nodes.forEach((n) => {
				delete n.fx;
				delete n.fy;
				n.moving = true;
			});
		})
		.on('drag', (event: D3DragEvent<SVGGElement, NCGroup, NCGroup>, g) => {
			if (lastX === undefined || lastY === undefined) {
				return;
			}

			movedGroup = true;

			resetPhysicsMovement(graph);

			const diffX = event.x - lastX;
			const diffY = event.y - lastY;
			lastX = event.x;
			lastY = event.y;
			g.nodes.forEach((n) => {
				n.x += diffX;
				n.y += diffY;
			});
			graph.onTick();
		})
		.on('end', (event: D3DragEvent<SVGGElement, NCGroup, NCGroup>, g) => {
			if (movedGroup && typeof moveStartX !== 'undefined' && typeof moveStartY !== 'undefined') {
				const moveDistance = Math.hypot(event.x - moveStartX, event.y - moveStartY);
				if (moveDistance > MIN_MOVE_DISTANCE) {
					graph.activateGroup(g);
					if (neuroCreate.saveNodes) {
						neuroCreate.saveNodes.updateIfMoved(graph.nodes, nodesBefore);
					}
				} else {
					// Did not move much, so reset position
					g.nodes.forEach((n) => {
						n.x = moveStartX!;
						n.y = moveStartY!;
					});
				}
			}
			g.nodes.forEach((n) => {
				delete n.moving;
			});
		})
		.on('start.mouse', graph.onMouseLeave)
		.on('end.mouse', graph.onMouseMove);

	return groupDragBehavior;
};

export const initNodeDragBehavior = (graph: Graph) => {
	let isMovingConnectedNodes: boolean | undefined;
	let movedNode: boolean | undefined;
	let moveStartX: number;
	let moveStartY: number;
	let newX: number = 0;
	let newY: number = 0;
	let nodesBefore: NCNode[] = [];

	const nodeDragBehavior = d3
		.drag<SVGGElement, NCNode>()
		.clickDistance(MIN_MOVE_DISTANCE)
		.filter((event) => {
			// Only allow drag on nodes and move-connected elements
			// Not on the text or menu items
			const mouseEvent = event as MouseEvent;
			const mouseTarget = mouseEvent.target as SVGElement;
			if (
				mouseTarget.classList.contains('node') ||
				mouseTarget.classList.contains('img-div') ||
				mouseTarget.closest('.move-connected') !== null
			) {
				return !mouseEvent.ctrlKey && !mouseEvent.button;
			} else {
				return false;
			}
		})
		.on('start', (event: D3DragEvent<SVGGElement, NCNode, NCNode>, n) => {
			const mouseEvent = event.sourceEvent as MouseEvent;
			const mouseTarget = mouseEvent.target as SVGElement;
			isMovingConnectedNodes = mouseTarget.closest('.move-connected') !== null;
			movedNode = false;
			newX = n.x;
			newY = n.y;
			moveStartX = n.x;
			moveStartY = n.y;
			nodesBefore = graph.nodes.map((node) => ({ ...node }));
			// Unfix the node
			delete n.fx;
			delete n.fy;
			n.moving = true;
			if (isMovingConnectedNodes) {
				graph.getConnectedNodes(event.subject).forEach((n) => {
					delete n.fx;
					delete n.fy;
					n.moving = true;
				});
			}
		})
		.on('drag', (event: D3DragEvent<SVGGElement, NCNode, NCNode>) => {
			const diffX = event.x - event.subject.x;
			const diffY = event.y - event.subject.y;

			if (diffX !== 0 || diffY !== 0) {
				newX += diffX;
				newY += diffY;

				if (!movedNode) {
					const moveDistance = Math.hypot(newX - moveStartX, newY - moveStartY);
					if (moveDistance > MIN_MOVE_DISTANCE) {
						movedNode = true;
						resetPhysicsMovement(graph);
					}
				}

				if (movedNode) {
					ensurePhysicsAlphaIsHighEnough(graph);
					event.subject.x = newX;
					event.subject.y = newY;
					if (isMovingConnectedNodes) {
						graph.getConnectedNodes(event.subject).forEach((n) => {
							n.x += diffX;
							n.y += diffY;
						});
					}
					graph.onTick();
				}
			}
		})
		.on('end', (e, n) => {
			delete n.moving;
			if (movedNode && typeof moveStartX !== 'undefined' && typeof moveStartY !== 'undefined') {
				const moveDistance = Math.hypot(n.x - moveStartX, n.y - moveStartY);
				if (moveDistance > MIN_MOVE_DISTANCE) {
					if (neuroCreate.saveNodes) {
						neuroCreate.saveNodes.updateIfMoved(graph.nodes, nodesBefore);
						nodesBefore = [];
					}
				} else {
					// Did not move much, so reset position
					n.x = moveStartX;
					n.y = moveStartY;
				}
			}
			if (isMovingConnectedNodes) {
				graph.getConnectedNodes(n).forEach((n) => {
					delete n.moving;
				});
			}
		})
		.on('start.mouse', graph.onMouseLeave)
		.on('end.mouse', graph.onMouseMove);

	return nodeDragBehavior;
};
