import { html, css, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('contact-us')
export class ContactUs extends LitElement {
	static styles = css``;

	render() {
		return html`<a
			href="mailto:NeuroCreate%20Customer%20Support<shama@neurocreate.co.uk>,Shama%20Rahman%20(CEO)<rahman.shama@gmail.com>"
			><slot>contact us</slot></a
		>`;
	}
}
