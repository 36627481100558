import JsPDF from 'jspdf';
import { startLoading } from '../ui/uiUtils';
import { PageName } from './pdfContent';

export const exportNotepad = async (page: PageName, boardName: string, doc: JsPDF) => {
	return new Promise<void>((resolve) => {
		startLoading(`Loading ${boardName}...`, true).then(() => {
			const graphMode = 'moodboard';

			const isCollaborative = !page.includes('personal');
			if (globalThis.neuroCreate.switchToMode) {
				globalThis.neuroCreate.switchToMode(graphMode, isCollaborative, true, async () => {
					await startLoading(`Exporting ${boardName}...`, true);
					document.getElementById('notepad-container')!.classList.remove('hidden');
					setTimeout(() => {
						const editorEl = document.querySelector<HTMLDivElement>('#editor')!;
						const innerEditor = editorEl.querySelector<HTMLDivElement>('.ql-editor')!;
						const PAGE_HEIGHT = 550;

						const exportNotepadColumn = (column: number) => {
							const columnOnPage = (column % 3) as 0 | 1 | 2;
							const page = Math.floor(column / 3);
							console.log(
								`Exporting column ${column} on page ${page}. Column on page: ${columnOnPage}`
							);
							if (columnOnPage === 0) {
								doc.addPage();
								doc.setFontSize(22);
								doc.text(`${boardName} ${page > 0 ? 'continued' : ''}`, 35, 20);
							}

							editorEl.classList.add('exported-graph');

							let totalHeight = 0;
							const startHeight = column * PAGE_HEIGHT;
							const endHeight = (column + 1) * PAGE_HEIGHT;
							for (const c of innerEditor.children) {
								totalHeight += c.clientHeight;
								if (totalHeight < startHeight || totalHeight > endHeight) {
									// Hide elements that are out of bounds
									c.classList.add('hidden');
								}
							}

							doc.html(editorEl, {
								callback: () => {
									editorEl.classList.remove('exported-graph');
									for (const c of innerEditor.children) {
										c.classList.remove('hidden');
									}
									if (totalHeight > endHeight) {
										exportNotepadColumn(column + 1);
									} else {
										resolve();
									}
								},
								x: 22 + 86 * columnOnPage,
								y: (doc.getNumberOfPages() - 1) * 210 + 25,
								width: 84,
								windowWidth: 350,
								html2canvas: {
									// scale: 0.3,
								},
							});
						};
						exportNotepadColumn(0);
					}, 500);
				});
			}
		});
	});
};
