import { PresentationInfo } from '../../../../../src/commonTypes';
import { createLabel, createTextField } from './utils';
import { savePresentationInfo } from '../../apiRequests';

export const getChangeSlideContent = (presentation: PresentationInfo) => {
	const changeContent = document.createElement('div');

	const changeHeading = document.createElement('h4');
	changeHeading.innerText = 'The Change We Want To Make';
	changeContent.append(changeHeading);

	changeContent.append(createLabel('Starting state (what is the current situation?)'));
	changeContent.append(
		createTextField(presentation.change?.start || '', 1, (event) => {
			if (!presentation.change) {
				presentation.change = {
					start: '',
					end: '',
				};
			}
			presentation.change.start = (event.target as HTMLTextAreaElement).value;
			savePresentationInfo(document.body.dataset.boardId!, presentation);
		})
	);

	changeContent.append(createLabel('End state (what is the desired situation?)'));
	const endState = createTextField(presentation.change?.end || '', 1);
	endState.addEventListener('change', () => {
		if (!presentation.change) {
			presentation.change = {
				start: '',
				end: '',
			};
		}
		presentation.change.end = endState.value;
		savePresentationInfo(document.body.dataset.boardId!, presentation);
	});
	changeContent.append(endState);

	return changeContent;
};
