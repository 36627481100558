import JsPDF from 'jspdf';

const imageToDataURL = async (imageUrl: string): Promise<string> => {
	const imgResponse = await fetch(imageUrl);
	const imgBlob = await imgResponse.blob();
	const bitmap = await createImageBitmap(imgBlob);
	const canvas = document.createElement('canvas');
	canvas.width = bitmap.width;
	canvas.height = bitmap.height;
	const drawingContext = canvas.getContext('2d')!;
	drawingContext.drawImage(bitmap, 0, 0, bitmap.width, bitmap.height);
	return canvas.toDataURL('image/png');
};

export const cachedDataUrls = {} as Record<string, string>;

export const preloadBoardImages = async () => {
	const chartEl = document.querySelector<HTMLElement>('#board-content .chart')!;

	// Preload any images as data URLs
	Object.keys(cachedDataUrls).forEach((key) => delete cachedDataUrls[key]);
	const promises = Array.from(chartEl.querySelectorAll<HTMLDivElement>('svg .node-image div')).map(
		(div) => {
			const rawUrl = div.style.backgroundImage.substring(5, div.style.backgroundImage.length - 2);
			return imageToDataURL(rawUrl).then((dataUrl) => {
				cachedDataUrls[rawUrl] = dataUrl;
			});
		}
	);

	return Promise.all(promises);
};

export const cleanupImageCache = () => {
	// Clean up memory
	Object.keys(cachedDataUrls).forEach((key) => {
		delete cachedDataUrls[key];
	});
};

export const exportCanvasToImage = (
	canvas: HTMLCanvasElement,
	doc: JsPDF,
	pageNumber: number,
	x = 10,
	y = 25,
	maxWidth = 260,
	maxHeight = 180
) => {
	return new Promise<boolean>((resolve) => {
		let width = maxWidth;
		let height = (canvas.height / canvas.width) * maxWidth;
		if (height > maxHeight) {
			height = maxHeight;
			width = (canvas.width / canvas.height) * maxHeight;
		}
		try {
			doc.setPage(pageNumber);
			doc.addImage(
				canvas,
				'JPEG',
				x + (maxWidth - width) / 2,
				y + (maxHeight - height) / 2,
				width,
				height,
				'',
				'FAST'
			);
			resolve(false); // Image is not empty
		} catch (e) {
			console.error('Failed to add image to document', e);
			resolve(true); // Image is empty
		}
	});
};
