import { WINDOW_BREAKPOINTS } from '../constants';
import { showAlert, componentToggle } from './uiUtils';

const timerButton = document.getElementById('timerButton');
const timer = document.querySelector<HTMLDivElement>('#timer-container .timer')!;

if (timerButton) {
	const minus = timer.querySelector<HTMLElement>('.minus')!;
	const plus = timer.querySelector<HTMLElement>('.plus')!;
	const play = timer.querySelector<HTMLElement>('.play')!;
	const reset = timer.querySelector<HTMLElement>('.reset')!;
	const min = timer.querySelector<HTMLElement>('.minutes')!;
	const sec = timer.querySelector<HTMLElement>('.seconds')!;
	let realTime = 60;
	let seconds = realTime;
	let minutes = realTime / 60;
	minus.dataset.state = realTime <= 60 ? 'unclickable' : 'clickable';
	if (realTime % 60 == 0) {
		seconds = 0;
		sec.innerText = String(seconds).padStart(2, '0');
	}
	min.innerText = String(minutes).padStart(2, '0');

	const decreaseTime = function () {
		if (realTime <= 0) {
			realTime = 0;
		} else if (realTime > 0 && realTime <= 59) {
			// Do not decrease time lower than 0
		} else {
			realTime = realTime - 60;
		}
		minutes = Math.floor(realTime / 60);
		if (minutes == 0) {
			play.classList.add('buttonDisabled');
			play.addEventListener('click', (e) => {
				e.preventDefault();
			});
			minus.dataset.state = 'unclickable';
		} else {
			play.classList.remove('buttonDisabled');
			minus.dataset.state = 'clickable';
		}
		min.innerText = String(minutes).padStart(2, '0');
	};
	minus.addEventListener('click', () => {
		if (realTime >= 61) {
			decreaseTime();
			minus.dataset.state = 'clickable';
		}
		if (realTime <= 60) {
			minus.dataset.state = 'unclickable';
		}
	});
	const increaseTime = function () {
		realTime = realTime + 60;
		if (realTime >= 3600) {
			realTime = 3600;
		}
		minutes = Math.floor(realTime / 60);
		if (minutes > 0) {
			play.classList.remove('buttonDisabled');
		} else {
			play.classList.add('buttonDisabled');
		}
		min.innerText = String(minutes).padStart(2, '0');
	};
	plus.addEventListener('click', () => {
		if (minutes >= 0) {
			minus.dataset.state = 'clickable';
		}
		increaseTime();
	});

	let secIntervalId: ReturnType<typeof setInterval>;
	let minIntervalId: ReturnType<typeof setInterval>;

	const handleTimer = function () {
		if (realTime > 0) {
			play.classList.remove('buttonDisabled');
			secIntervalId = setInterval(handleSeconds, 1000);

			handleMinutes();
			minIntervalId = setInterval(handleMinutes, 1000);

			minus.dataset.state = realTime <= 60 ? 'unclickable' : 'clickable';
			reset.classList.remove('buttonHide');
			play.classList.add('buttonHide');
		} else {
			play.classList.add('buttonDisabled');
			play.addEventListener('click', (e) => {
				e.preventDefault();
			});
		}
	};

	const handleSeconds = function () {
		if (realTime <= 0) {
			realTime = 0;
			reset.classList.add('buttonHide');
			play.classList.remove('buttonHide');

			showAlert('The timer has finished ✨');

			clearInterval(secIntervalId);
			clearInterval(minIntervalId);

			timerButton.classList.remove('buttonActive');
		} else {
			realTime--;
			seconds = realTime % 60;
			timerButton.classList.add('buttonActive');
		}
		sec.innerText = String(seconds).padStart(2, '0');
	};

	const handleMinutes = function () {
		minutes = Math.floor(realTime / 60);
		if (minutes <= 0) {
			minutes = 0;
		}
		min.innerText = String(minutes).padStart(2, '0');
	};
	play.addEventListener('click', handleTimer);

	const resetTimer = function () {
		clearInterval(secIntervalId);
		clearInterval(minIntervalId);

		realTime = 60;
		minus.dataset.state = 'unclickable';
		seconds = realTime % 60;
		minutes = realTime / 60;

		sec.innerText = String(seconds).padStart(2, '0');
		min.innerText = String(minutes).padStart(2, '0');
		reset.classList.add('buttonHide');
		play.classList.remove('buttonHide');
		play.classList.remove('buttonDisabled');
	};

	reset.addEventListener('click', resetTimer);

	const setTimerPosition = () => {
		const inspireProgress = document.querySelector('#inspire-progress');
		if (window.innerWidth < WINDOW_BREAKPOINTS.laptop) {
			if (inspireProgress?.classList.contains('hidden')) {
				// if progress bar is hidden, set timer location to next to synthesise switch
				const synthesiseSwitch = document
					.querySelector('#synthesise-switch')
					?.getBoundingClientRect();
				timer.style.top = `${synthesiseSwitch!.top}px`;
				timer.style.left = `${synthesiseSwitch!.right + 16}px`;
			} else {
				// if progress bar is visible, set timer location to the next of the progress bar
				const progressPosition = inspireProgress?.getBoundingClientRect();
				timer.style.top = `${progressPosition!.top}px`;
				timer.style.left = `${progressPosition!.right + 16}px`;
			}
		} else {
			// set timer location to the bottom of the toolbar menu
			const rect = timerButton.getBoundingClientRect();
			timer.style.top = `${rect.bottom + 16}px`;
			timer.style.left = `${rect.left}px`;
		}
	};

	timerButton.addEventListener('click', () => {
		setTimerPosition();
		if (play.classList[1] == 'buttonHide') {
			timerButton.classList.add('buttonActive');
		} else {
			timerButton.classList.toggle('buttonActive');
		}
		componentToggle(document.getElementById('timer-container')!);
	});

	window.addEventListener('resize', () => {
		setTimerPosition();
	});

	document.querySelectorAll('#board-stage-switcher li').forEach((li) => {
		li.addEventListener('click', () => {
			setTimeout(() => {
				setTimerPosition();
			}, 200);
		});
	});
}
