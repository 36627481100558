import {
	LikedItem,
	PresentationInfo,
	SelectableItem,
	SYNTHESISE_SUBTASKS,
	SynthesiseSubTask,
	TargetAudience,
} from '../../../../src/commonTypes';
import {
	centerParagraph,
	createArrow,
	createHorizontalLine,
	createImage,
	createTextBox,
	createTitleAndBodySlide,
	createTitleOnlySlide,
	createTitleSlide,
	createTwoColumnsSlide,
	insertText,
	insertTitleText,
	reduceLineSpacing,
	removeExtraLines,
	removeExtraNewLinesFromNumberedList,
	splitIntoMultiplePages,
	updateFontSize,
	updateTextToBold,
	updateTextToIncludeLink,
	updateTextToItalic,
	updateToTextColour,
} from './slideLayoutUtils';
import { capitalizeFirstLetter, formatCurrency } from '../../../../src/commonMisc';
import { getTaskReadableName } from './slideUtils';

export const addTitleSlide = (
	presentationInfo: PresentationInfo
): Array<gapi.client.slides.Request> => {
	const { titleId, subtitleId, createSlideRequest } = createTitleSlide();

	return [
		createSlideRequest,
		insertText(titleId, presentationInfo.title),
		insertText(subtitleId, `${presentationInfo.fullName || ''}\n${presentationInfo.date}`),
		updateFontSize(subtitleId, 16, (presentationInfo.fullName || '').length + 1),
	];
};

export const addSectionTitleSlide = (
	longTitle: string,
	summary: string
): Array<gapi.client.slides.Request> => {
	const { titleId, bodyId, createSlideRequest } = createTitleAndBodySlide();

	return [
		createSlideRequest,
		insertText(titleId, 'Conceptual Territory'),
		updateToTextColour(titleId),
		insertText(bodyId, `${longTitle}\n\n${summary}`),
		centerParagraph(bodyId),
		// Long title is italic and green
		updateTextToItalic(bodyId, longTitle.length),
		updateToTextColour(bodyId, 'ACCENT5', 0, longTitle.length),
		updateFontSize(bodyId, longTitle.length > 600 ? 13 : 15, 0, longTitle.length),
		// Summary is bold and orange
		updateFontSize(bodyId, 14, longTitle.length + 2),
		updateTextToBold(bodyId, longTitle.length + 2),
		updateToTextColour(bodyId, 'DARK1', longTitle.length + 2),
	];
};

export const addLogisticsSlide = (
	presentationInfo: PresentationInfo
): Array<gapi.client.slides.Request> => {
	if (!presentationInfo.logistics?.show) {
		return [];
	}

	const { pageId, titleId, createSlideRequest } = createTitleOnlySlide();

	const requests: Array<gapi.client.slides.Request> = [
		createSlideRequest,
		insertText(titleId, 'Logistics'),
	];

	let y = 100;
	const labelHeight = 20;
	const spacing = 2;
	const x = 45;
	const fullWidth = 600;
	const shortBlockHeight = 40;
	const tallBlockHeight = 70;

	requests.push(
		...createTextBox(pageId, 'DELIVERABLES', x, y, fullWidth, labelHeight),
		...createTextBox(
			pageId,
			presentationInfo.logistics.deliverables || '',
			x,
			y + labelHeight + spacing,
			fullWidth,
			tallBlockHeight,
			true,
			true,
			12
		)
	);

	y += 90;
	const budget = presentationInfo.logistics.budget
		? parseFloat(presentationInfo.logistics.budget || '0')
		: 0;
	requests.push(
		...createTextBox(pageId, 'BUDGET', x, y, fullWidth, labelHeight),
		...createTextBox(
			pageId,
			budget > 0 ? formatCurrency(budget, presentationInfo.logistics.currency) : '',
			x,
			y + labelHeight + spacing,
			fullWidth / 2 - 10,
			shortBlockHeight,
			true,
			true,
			12
		)
	);

	const deliveryDate = presentationInfo.logistics.deliveryDate;
	requests.push(
		...createTextBox(pageId, 'DELIVERY DATE', x + fullWidth / 2 + 10, y, fullWidth, labelHeight),
		...createTextBox(
			pageId,
			deliveryDate ? new Date(deliveryDate).toLocaleDateString() : '',
			x + fullWidth / 2 + 10,
			y + labelHeight + spacing,
			fullWidth / 2 - 10,
			shortBlockHeight,
			true,
			true,
			12
		)
	);

	y += 60;
	const firstReviewDate = presentationInfo.logistics.firstReviewDate;
	requests.push(
		...createTextBox(pageId, 'FIRST REVIEW DATE', x, y, fullWidth, labelHeight),
		...createTextBox(
			pageId,
			firstReviewDate ? new Date(firstReviewDate).toLocaleDateString() : '',
			x,
			y + labelHeight + spacing,
			fullWidth / 2 - 10,
			shortBlockHeight,
			true,
			true,
			12
		)
	);

	const secondReviewDate = presentationInfo.logistics.secondReviewDate;
	requests.push(
		...createTextBox(
			pageId,
			'SECOND REVIEW DATE',
			x + fullWidth / 2 + 10,
			y,
			fullWidth,
			labelHeight
		),
		...createTextBox(
			pageId,
			secondReviewDate ? new Date(secondReviewDate).toLocaleDateString() : '',
			x + fullWidth / 2 + 10,
			y + labelHeight + spacing,
			fullWidth / 2 - 10,
			shortBlockHeight,
			true,
			true,
			12
		)
	);

	y += 60;
	requests.push(
		...createTextBox(pageId, 'ASSIGNED TEAM', x, y, fullWidth, labelHeight),
		...createTextBox(
			pageId,
			presentationInfo.logistics.team || '',
			x,
			y + labelHeight + spacing,
			fullWidth,
			shortBlockHeight,
			true,
			true,
			12
		)
	);

	return requests;
};

export const addProjectVisionSlide = (
	presentationInfo: PresentationInfo
): Array<gapi.client.slides.Request> => {
	if (!presentationInfo.vision || !presentationInfo.summary) {
		return [];
	}

	const { titleId, bodyId, createSlideRequest } = createTitleAndBodySlide();

	return [
		createSlideRequest,
		insertText(titleId, 'Project Vision'),
		insertText(bodyId, presentationInfo.vision + '\n\n' + presentationInfo.summary),
		updateTextToBold(bodyId, presentationInfo.vision.length + 2),
		centerParagraph(bodyId),
		updateFontSize(bodyId, 16),
	];
};

export const addChangeSlide = (
	presentationInfo: PresentationInfo
): Array<gapi.client.slides.Request> => {
	if (!presentationInfo.change?.start || !presentationInfo.change?.end) {
		return [];
	}

	const { pageId, titleId, createSlideRequest } = createTitleOnlySlide();

	return [
		createSlideRequest,
		insertText(titleId, 'The Change We Want To Make'),
		...createTextBox(pageId, 'STARTING STATE', 45, 120, 160, 25),
		...createTextBox(pageId, presentationInfo.change.start, 50, 140, 220, 160, true, true, 12),
		...createTextBox(pageId, 'ENDING STATE', 415, 120, 160, 25),
		...createTextBox(pageId, presentationInfo.change.end, 420, 140, 220, 160, true, true, 12),
		...createArrow(pageId, 300, 195, 80, 40),
	];
};

const addTargetAudienceTextSlide = (
	audience: TargetAudience,
	prependTitle: string = ''
): Array<gapi.client.slides.Request> => {
	const { titleId, leftColumnId, rightColumnId, createSlideRequest } = createTwoColumnsSlide();

	return [
		createSlideRequest,
		...insertTitleText(titleId, `${prependTitle}Target Audience`),
		insertText(
			leftColumnId,
			`Audience name: ${audience.name.trim()}
Profession: ${audience.profession.trim()}
Needs:
${audience.needs.trim()}`
		),
		insertText(
			rightColumnId,
			`Age: ${audience.age.trim()}
Motivations:
${audience.motivations.trim()}`
		),
		updateFontSize(titleId, 20),
		updateFontSize(leftColumnId, 14),
		updateFontSize(rightColumnId, 14),
	];
};

export const addTargetAudienceSlides = (
	presentationInfo: PresentationInfo,
	fromVision: boolean // Whether to use those autogenerated from project vision (or all the others)
): Array<gapi.client.slides.Request> => {
	const batchRequests: Array<gapi.client.slides.Request> = [
		...presentationInfo.targetAudiences
			.filter(isSelectedFromVision(fromVision))
			.flatMap(({ value }) => addTargetAudienceTextSlide(value)),
	];

	return batchRequests;
};
export const addPersonasSlides = (
	presentationInfo: PresentationInfo,
	fromVision: boolean // Whether to use those autogenerated from project vision (or all the others)
): Array<gapi.client.slides.Request> => {
	const batchRequests: Array<gapi.client.slides.Request> = [];

	presentationInfo.personas
		.filter(isSelectedFromVision(fromVision))
		.forEach(({ value: persona }) => {
			const { pageId, titleId, leftColumnId, rightColumnId, createSlideRequest } =
				createTwoColumnsSlide();

			batchRequests.push(
				createSlideRequest,
				insertText(titleId, `Persona Inspiration: ${persona.name} (${persona.theme})`),
				insertText(
					leftColumnId,
					`${persona.biography.trim()}
Goals: ${persona.goals.trim()}
`
				)
			);
			if (persona.imageUrl) {
				// Draw in top right corner of slide
				batchRequests.push(createImage(pageId, persona.imageUrl, 160, 160, 90, 220));
			}
			if (persona.biography.trim().length > 0) {
				batchRequests.push(updateTextToBold(leftColumnId, 0, persona.biography.trim().length));
			}
			batchRequests.push(
				insertText(
					rightColumnId,
					`Needs & wants: ${persona.needs.trim()}
Pain points: ${persona.painPoints.trim()}
Motivations: ${persona.motivations.trim()}
Influences: ${persona.influences.trim()}`
				),
				updateFontSize(titleId, 20),
				updateFontSize(leftColumnId, 11),
				updateFontSize(rightColumnId, 10)
			);

			const textX = 550;
			const barX = 470;
			const y = 320;
			const backgroundColor = 'LIGHT2';
			const foregroundColor = 'ACCENT1';
			const multiplier = 8;
			batchRequests.push(
				...createTextBox(pageId, 'Tech knowledge', textX, y - 13, 200, 25),
				...createHorizontalLine(pageId, multiplier * 10, barX, y, backgroundColor),
				...createHorizontalLine(pageId, multiplier * persona.scores.tech, barX, y, foregroundColor),
				...createTextBox(pageId, 'Ambition', textX, y + 7, 200, 25),
				...createHorizontalLine(pageId, multiplier * 10, barX, y + 20, backgroundColor),
				...createHorizontalLine(
					pageId,
					multiplier * persona.scores.ambition,
					barX,
					y + 20,
					foregroundColor
				),
				...createTextBox(pageId, 'Happiness', textX, y + 27, 200, 25),
				...createHorizontalLine(pageId, multiplier * 10, barX, y + 40, backgroundColor),
				...createHorizontalLine(
					pageId,
					multiplier * persona.scores.happiness,
					barX,
					y + 40,
					foregroundColor
				)
			);
		});

	return batchRequests;
};

export const addMotivationsSlides = (
	presentationInfo: PresentationInfo,
	fromVision: boolean // Whether to use those autogenerated from project vision (or all the others)
): Array<gapi.client.slides.Request> => {
	const batchRequests: Array<gapi.client.slides.Request> = [];

	presentationInfo.motivations
		.filter(isSelectedFromVision(fromVision))
		.forEach(({ value: motivations }) => {
			const { pageId, titleId, createSlideRequest } = createTitleOnlySlide();

			batchRequests.push(
				createSlideRequest,
				insertText(titleId, `Motivations ${motivations.theme ? `(${motivations.theme})` : ''}`),
				updateFontSize(titleId, 12, 12) // reduce font size after "Motivations "
			);

			let x = 25;
			let y = 80;
			motivations.stakeholders.forEach((stakeholder, index) => {
				if (index > 5) {
					return;
				}

				// Display image, name, and description in two columns
				if (stakeholder.imageUrl) {
					batchRequests.push(createImage(pageId, stakeholder.imageUrl, 80, 80, x, y));
				}

				batchRequests.push(
					...createTextBox(
						pageId,
						stakeholder.name,
						x + 85,
						y - 10,
						240,
						25,
						false,
						false,
						10,
						true
					),
					...createTextBox(
						pageId,
						stakeholder.description,
						x + 85,
						y + 4,
						250,
						100,
						false,
						false,
						9
					)
				);

				if (index === 2) {
					x = 370;
					y = 80;
				} else {
					y += 110;
				}
			});
		});

	return batchRequests;
};

const createImageSlides = (
	pageTitle: string,
	images: PresentationInfo['images']
): Array<gapi.client.slides.Request> => {
	const batchRequests: Array<gapi.client.slides.Request> = [];

	let imagesPerPage = 9;
	let itemsPerRow = 3;
	let imageWidth = 180;
	let imageHeight = 100;

	if (images.length === 1) {
		imagesPerPage = 1;
		itemsPerRow = 1;
		imageWidth = 540;
		imageHeight = 300;
	} else if (images.length == 2) {
		imagesPerPage = 2;
		itemsPerRow = 2;
		imageWidth = 270;
		imageHeight = 270;
	} else if (images.length <= 4) {
		imagesPerPage = 4;
		itemsPerRow = 2;
		imageWidth = 270;
		imageHeight = 150;
	}

	if (images.length > 0) {
		let displayIndex = 0;
		let currentPageId: string;

		images.forEach(({ value: { url } }, index) => {
			if (index % imagesPerPage === 0) {
				// New page
				const { pageId, titleId, createSlideRequest } = createTitleOnlySlide();
				currentPageId = pageId;

				batchRequests.push(createSlideRequest, ...insertTitleText(titleId, pageTitle));

				displayIndex = 0;
			}

			const row = Math.floor(displayIndex / itemsPerRow);
			const col = displayIndex % itemsPerRow;

			batchRequests.push(
				createImage(
					currentPageId,
					url,
					imageWidth,
					imageHeight,
					50 + col * (imageWidth * 1.1),
					80 + row * (imageHeight * 1.06)
				)
			);
			displayIndex++;
		});
	}

	return batchRequests;
};

const createLinkSlides = (
	pageTitle: string,
	selectedLinks: PresentationInfo['links']
): Array<gapi.client.slides.Request> => {
	const batchRequests: Array<gapi.client.slides.Request> = [];

	if (selectedLinks.length > 0) {
		let currentBodyId: string;

		const LINKS_PER_PAGE = 5;
		selectedLinks.forEach((selectedItem, index) => {
			if (index % LINKS_PER_PAGE === 0) {
				// New page
				const { titleId, bodyId, createSlideRequest } = createTitleAndBodySlide();
				currentBodyId = bodyId;

				batchRequests.push(createSlideRequest, insertText(titleId, pageTitle));

				const currentPageLinks = selectedLinks.slice(
					index,
					Math.min(selectedLinks.length, index + LINKS_PER_PAGE)
				);

				const linksText = currentPageLinks.map(({ value: { text } }) => text);

				batchRequests.push(
					insertText(currentBodyId, linksText.join('\n')),
					...currentPageLinks.reduce(
						(accumulator, { value: { url, text } }) => {
							accumulator.requests.push(
								updateTextToIncludeLink(
									currentBodyId,
									url,
									accumulator.lastIndex,
									accumulator.lastIndex + text.length
								)
							);
							accumulator.lastIndex += text.length + 1;
							return accumulator;
						},
						{ requests: [], lastIndex: 0 } as {
							requests: Array<gapi.client.slides.Request>;
							lastIndex: number;
						}
					).requests
				);
			}
		});
	}

	return batchRequests;
};

const createTextSlides = (
	pageTitle: string,
	selectedTexts: PresentationInfo['taglines'],
	itemsPerPage = 5,
	centered = true
): Array<gapi.client.slides.Request> => {
	const batchRequests: Array<gapi.client.slides.Request> = [];

	if (selectedTexts.length > 0) {
		let currentBodyId: string;

		selectedTexts.forEach((selectedItem, index) => {
			if (index % itemsPerPage === 0) {
				// New page
				const { titleId, bodyId, createSlideRequest } = createTitleAndBodySlide();
				currentBodyId = bodyId;

				batchRequests.push(createSlideRequest, insertText(titleId, pageTitle));

				const currentPageLinks = selectedTexts.slice(
					index,
					Math.min(selectedTexts.length, index + itemsPerPage)
				);

				const linksText = currentPageLinks.map(({ value: { text } }) => text);

				batchRequests.push(insertText(currentBodyId, linksText.join('\n')));
				if (centered) {
					batchRequests.push(centerParagraph(currentBodyId));
				}
			}
		});
	}

	return batchRequests;
};

const createTextGridSlides = (
	pageTitle: string,
	selectedTexts: PresentationInfo['taglines'],
	itemsPerPage: 9 | 6 | 4 | 3 | 2 = 9,
	centered = true,
	fontSize = 14
): Array<gapi.client.slides.Request> => {
	const batchRequests: Array<gapi.client.slides.Request> = [];

	let itemsPerRow = 3;
	let itemWidth = 200;
	let itemHeight = 100;

	if (itemsPerPage === 6) {
		itemHeight = 120;
	} else if (itemsPerPage === 4) {
		itemsPerRow = 2;
		itemWidth = 300;
		itemHeight = 120;
	} else if (itemsPerPage === 3) {
		itemHeight = 240;
	} else if (itemsPerPage === 2) {
		itemsPerRow = 2;
		itemWidth = 300;
		itemHeight = 240;
	}

	if (selectedTexts.length > 0) {
		let displayIndex = 0;
		let currentPageId: string;

		selectedTexts.forEach(({ value: { text } }, index) => {
			if (index % itemsPerPage === 0) {
				// New page
				const { pageId, titleId, createSlideRequest } = createTitleOnlySlide();
				currentPageId = pageId;

				batchRequests.push(createSlideRequest, insertText(titleId, pageTitle));

				displayIndex = 0;
			}

			const row = Math.floor(displayIndex / itemsPerRow);
			const col = displayIndex % itemsPerRow;

			batchRequests.push(
				...createTextBox(
					currentPageId,
					text,
					40 + col * (itemWidth * 1.1),
					80 + row * (itemHeight * 1.06),
					itemWidth,
					itemHeight,
					false,
					centered,
					fontSize
				)
			);
			displayIndex++;
		});
	}

	return batchRequests;
};

export const addInspirationSlides = (
	presentationInfo: PresentationInfo
): Array<gapi.client.slides.Request> => {
	const selectedFilter = (selectable: SelectableItem<unknown>) => selectable.selected;
	const selectedImages = presentationInfo.images.filter(selectedFilter);
	const selectedLinks = presentationInfo.links.filter(selectedFilter);
	const selectedTaglines = presentationInfo.taglines.filter(selectedFilter);
	const selectedHaikus = presentationInfo.haikus.filter(selectedFilter);
	const selectedBrainstorming = presentationInfo.brainstorming.filter(selectedFilter);
	const selectedCommonIdeas = presentationInfo.commonIdeas.filter(selectedFilter);

	const batchRequests: Array<gapi.client.slides.Request> = [];

	batchRequests.push(...createImageSlides('Inspiration (images)', selectedImages));
	batchRequests.push(...createLinkSlides('Inspiration (links)', selectedLinks));
	batchRequests.push(
		...createTextSlides(
			'Inspiration (taglines)',
			selectedTaglines.map((item) => ({
				...item,
				value: { ...item.value, text: item.value.text.replace(/\n/g, '. ') },
			}))
		)
	);
	batchRequests.push(...createTextGridSlides('Inspiration (haikus)', selectedHaikus, 6, true, 14));
	batchRequests.push(
		...createTextGridSlides('Inspiration (brainstorming)', selectedBrainstorming, 3, true, 12)
	);
	batchRequests.push(
		...createTextGridSlides('Inspiration (common ideas)', selectedCommonIdeas, 3, true, 12)
	);

	return batchRequests;
};

export const addAssetsSlides = (
	presentationInfo: PresentationInfo
): Array<gapi.client.slides.Request> => {
	const selectedPhotos = presentationInfo.photos.filter((selectable) => selectable.selected);

	const batchRequests: Array<gapi.client.slides.Request> = [];

	batchRequests.push(...createImageSlides('Assets (photorealistic images)', selectedPhotos));

	return batchRequests;
};

const addBrandStrategyTextSlide = (
	strategyText: string,
	prependTitle: string = ''
): Array<gapi.client.slides.Request> => {
	if (!strategyText) {
		return [];
	}

	const { titleId, bodyId, createSlideRequest } = createTitleAndBodySlide();

	return [
		createSlideRequest,
		...insertTitleText(titleId, `${prependTitle}Brand Strategy`),
		insertText(bodyId, strategyText),
		updateFontSize(bodyId, 14),
	];
};

const isSelectedFromVision =
	(fromVision: boolean) =>
	({ selected, autogenerated }: SelectableItem<unknown>) =>
		fromVision === Boolean(autogenerated) && selected;

export const addBrandStrategySlides = (
	presentationInfo: PresentationInfo,
	fromVision: boolean // Whether to use those autogenerated from project vision (or all the others)
): Array<gapi.client.slides.Request> => {
	return presentationInfo.brandStrategies
		.filter(isSelectedFromVision(fromVision))
		.flatMap(({ value }) => addBrandStrategyTextSlide(value.text));
};

const addMultiPageText = (
	productIdeaText: string,
	title = '',
	numberOfLines = 8,
	lineSpacing?: number
): Array<gapi.client.slides.Request> => {
	const requests: Array<gapi.client.slides.Request> = [];

	splitIntoMultiplePages(productIdeaText, numberOfLines).forEach(
		(pageText, index, allPageTexts) => {
			const { titleId, bodyId, createSlideRequest } = createTitleAndBodySlide();

			const titleText =
				allPageTexts.length === 1 ? title : `${title} (${index + 1} of ${allPageTexts.length})`;

			requests.push(
				createSlideRequest,
				...insertTitleText(titleId, titleText),
				insertText(bodyId, pageText),
				updateFontSize(bodyId, 11)
			);
			if (lineSpacing) {
				requests.push(reduceLineSpacing(bodyId, lineSpacing));
			}
		}
	);

	return requests;
};
const addProductBriefTextSlide = (
	briefText?: string,
	prependTitle: string = ''
): Array<gapi.client.slides.Request> => {
	if (!briefText) {
		return [];
	}

	const requests: Array<gapi.client.slides.Request> = [];

	const [firstPart, secondPart] = briefText.split(/Requirements.*:\n/);

	if (firstPart && secondPart) {
		{
			const { titleId, bodyId, createSlideRequest } = createTitleAndBodySlide();

			requests.push(
				createSlideRequest,
				...insertTitleText(titleId, `${prependTitle}Product Brief (1 of 2)`),
				insertText(bodyId, removeExtraNewLinesFromNumberedList(firstPart)),
				updateFontSize(bodyId, 8),
				reduceLineSpacing(bodyId)
			);
		}

		{
			const { titleId, bodyId, createSlideRequest } = createTitleAndBodySlide();

			requests.push(
				createSlideRequest,
				...insertTitleText(titleId, `${prependTitle}Product Brief (2 of 2)`),
				insertText(bodyId, removeExtraNewLinesFromNumberedList(`Requirements:\n\n${secondPart}`)),
				updateFontSize(bodyId, 8),
				reduceLineSpacing(bodyId)
			);
		}
	}

	return requests;
};

export const addProductStrategySlides = (
	presentationInfo: PresentationInfo,
	fromVision: boolean // Whether to use those autogenerated from project vision (or all the others)
): Array<gapi.client.slides.Request> => {
	const requests: Array<gapi.client.slides.Request> = [];

	requests.push(
		...presentationInfo.productIdeas
			.filter(isSelectedFromVision(fromVision))
			.flatMap(({ value: strategy }) =>
				addMultiPageText(removeExtraLines(strategy.text), 'Product Idea', 7)
			),
		...presentationInfo.productBriefs
			.filter(isSelectedFromVision(fromVision))
			.flatMap(({ value: strategy }) => addProductBriefTextSlide(strategy.text))
	);

	return requests;
};

const addConceptTextSlide = (
	result: LikedItem,
	phrase: string,
	fontSize = 14
): Array<gapi.client.slides.Request> => {
	if (!result.text) {
		return [];
	}

	const { titleId, bodyId, createSlideRequest } = createTitleAndBodySlide();

	return [
		createSlideRequest,
		...insertTitleText(
			titleId,
			`${capitalizeFirstLetter(phrase)} - ${getTaskReadableName(result.type)}`
		),
		insertText(bodyId, removeExtraNewLinesFromNumberedList(result.text)),
		updateFontSize(bodyId, fontSize),
		reduceLineSpacing(bodyId, 120),
	];
};

export const addConceptualTerritorySlides = (
	presentationInfo: PresentationInfo
): Array<gapi.client.slides.Request> => {
	const requests: Array<gapi.client.slides.Request> = [];

	presentationInfo.conceptualTerritories.forEach(({ value: territory }) => {
		const selectedResults = territory.results.filter((result) => result.selected);

		if (territory.summary && selectedResults.length > 0) {
			// Add title slide
			requests.push(...addSectionTitleSlide(`“${territory.phrase}”`, territory.summary));
		}

		const territoryName = territory.summary || territory.phrase;

		const imageResults = selectedResults
			.map((item) => item.value)
			.filter(
				(value): value is LikedItem & { url: string } =>
					!!(value.url && value.type === 'image-synthesise')
			);
		if (imageResults.length > 0) {
			requests.push(
				...createImageSlides(
					`${capitalizeFirstLetter(territoryName)}  - Images`,
					imageResults.map(({ url }) => ({
						value: { url },
						selected: true,
					}))
				)
			);
		}

		selectedResults.forEach(({ value }) => {
			if (
				value.type === 'summary' ||
				value.type === 'extract' ||
				value.type === 'unite' ||
				value.type === 'brainstorm' ||
				value.type === 'taglines'
			) {
				requests.push(...addConceptTextSlide(value, territoryName, 18));
			} else if (value.type === 'synopsis' && value.text) {
				requests.push(
					...addMultiPageText(
						removeExtraLines(value.text),
						`${capitalizeFirstLetter(territoryName)} - ${getTaskReadableName(value.type)}`,
						5
					)
				);
			} else if (value.type === 'game' && value.text) {
				requests.push(
					...addMultiPageText(
						value.text,
						`${capitalizeFirstLetter(territoryName)} - ${getTaskReadableName(value.type)}`,
						7,
						150
					)
				);
			} else if (value.type === 'brand-strategy' && value.text) {
				requests.push(
					...addBrandStrategyTextSlide(value.text, `${capitalizeFirstLetter(territoryName)} - `)
				);
			} else if (value.type === 'product-brief') {
				requests.push(
					...addProductBriefTextSlide(value.text, `${capitalizeFirstLetter(territoryName)} - `)
				);
			} else if (value.type === 'product-idea' && value.text) {
				requests.push(
					...addMultiPageText(
						removeExtraLines(value.text),
						`${capitalizeFirstLetter(territoryName)} - ${getTaskReadableName(value.type)}`,
						8
					)
				);
			} else if (value.type === 'audience' && value.text) {
				const audience = JSON.parse(value.text) as TargetAudience;
				requests.push(
					...addTargetAudienceTextSlide(audience, `${capitalizeFirstLetter(territoryName)} - `)
				);
			} else if (SYNTHESISE_SUBTASKS.includes(value.type as SynthesiseSubTask) && value.text) {
				requests.push(
					...addConceptTextSlide(value, territoryName, value.type.startsWith('swot-') ? 11 : 14)
				);
			} else if (value.type === 'image-synthesise' && value.url) {
				// Ignore for now, this will be handled separately
			} else {
				console.log('Unrecognised conceptual territory result', territoryName, value);
			}
		});
	});

	return requests;
};
