import * as ncApi from '../apiRequests';
import { refreshHeartedList } from './ideaBox';
import { removeChildren, showAlert, showNonBlockingMessage } from './uiUtils';
import * as tutorialEvents from '../tutorials/tutorialEvents';
import { measureText } from '../misc';
import { NCNode, GraphMode, LikedItem } from '../../../../src/commonTypes';
import Graph from '../graphs/Graph';
import { getLabels } from '../graphs/graphUtils';
import { addToolTipWithTitle } from './uiUtils';

export const getHeartIcon = (
	type: LikedItem['type'],
	getText: null | (() => string | null) = null,
	url: null | string = null,
	title: null | string = null
) => {
	const heart = document.createElement('div');
	heart.className = 'heart';
	const heartImage = document.createElement('img');
	heartImage.src = '/assets/icon/board/heart-empty.png';
	heart.append(heartImage);

	heart.onmouseover = () => {
		if (heart.classList.contains('liked')) return;
		heartImage.src = heartImage.src.replace('empty', 'full');
	};

	heart.onmouseleave = () => {
		if (heart.classList.contains('liked')) return;
		heartImage.src = heartImage.src.replace('full', 'empty');
	};

	heart.onclick = () => {
		heart.classList.toggle('liked');
		const remove = Boolean(!heart.classList.contains('liked'));

		ncApi
			.like(
				document.body.dataset.boardId!,
				type,
				getText ? getText() : null,
				url,
				remove,
				title || undefined
			)
			.then(() => {
				heartImage.src = `/assets/icon/board/heart-${remove ? 'empty' : 'full'}.png`;
				tutorialEvents.likedResult();
				refreshHeartedList();
			});
	};

	return heart;
};

export const showCreateGroupPrompt = () => {
	const graph = globalThis.neuroCreate.graph;
	if (graph) {
		const selectedNodes: Array<NCNode> =
			graph.selections.groupingNodes || (graph.activeNode ? [graph.activeNode] : []);
		const activeGroup = graph.activeGroup;

		graph.startSelectingNodes('groupingNodes', selectedNodes, 'group');

		showNonBlockingMessage(
			`Select the concepts you'd like to group together (minimum 2). Current selection: ${
				selectedNodes.length > 0
					? selectedNodes.map((n) => (n.src ? 'image' : n.label)).join(', ')
					: '<em>none</em>'
			}`,
			[
				{
					label: 'Cancel',
					onClick: () => {
						graph.stopSelectingNodes('group');
					},
				},
				{
					label: activeGroup ? 'Save' : 'Group concepts',
					onClick: () => {
						if (activeGroup) {
							graph.updateNodesInGroup(activeGroup, selectedNodes);
						} else {
							graph.createGroup(selectedNodes);
						}
						graph.stopSelectingNodes('group');
					},
					disabled: selectedNodes.length < 2,
					primary: true,
				},
			]
		);
	}
};

const getSelectedWordsHtml = (selectedNodes: Array<NCNode>) => {
	return `Selected words: ${selectedNodes.map((n) => `<em>${n.label && n.label.length > 20 ? `${n.label?.substring(0, 20).trim()}...` : n.label || ''}</em>`).join(', ')}${
		selectedNodes.length === 0 ? 'None' : ''
	}`;
};

export const showLinkingNodesPrompt = (linkingNodes: Array<NCNode>) => {
	const graph = globalThis.neuroCreate.graph;
	if (graph) {
		showNonBlockingMessage(
			`${getSelectedWordsHtml(linkingNodes)}
<small><br><br>Add more words or tap <strong>Link concepts</strong> to find a linking concept.</small>`,
			[
				{
					primary: true,
					label: 'Link concepts',
					onClick: () => {
						ncApi.linker(graph.boardId, getLabels(linkingNodes)).then((suggestion) => {
							graph.stopSelectingNodes();
							if (suggestion) {
								showAlert(
									`The suggested word is: <em>${suggestion}</em><br><br>It has been added to the board.`
								);
								const firstLinkedNode = linkingNodes.shift()!;
								const newNode = graph.addSingleNode(
									firstLinkedNode,
									suggestion,
									'LINK',
									linkingNodes
								);
								graph.updateActive(newNode);
							} else {
								showAlert(
									`No linking concept could be found. This may be because you have non-dictionary words.`
								);
							}
						});
					},
				},
				{
					label: 'Cancel',
					onClick: () => {
						graph.stopSelectingNodes();
					},
				},
			]
		);
	}
};

// Also used for brainstorm
export const updateSelectedCommonIdeasNodes = (selectedNodes: Array<NCNode>) => {
	const graph = globalThis.neuroCreate.graph;
	if (graph) {
		const commonIdeasContainer = document.querySelector('.common-ideas-input')!;
		const brainstormContainer = document.querySelector('.brainstorm-input')!;
		const container = commonIdeasContainer.classList.contains('hidden')
			? brainstormContainer
			: commonIdeasContainer;
		container.classList.remove('hidden');

		const selectedNodesList = container.querySelector<HTMLUListElement>('ul.selected-nodes')!;
		removeChildren(selectedNodesList);
		if (selectedNodes && selectedNodes.length > 0) {
			for (const node of selectedNodes) {
				if (node.label) {
					const listItem = document.createElement('li');
					listItem.innerText = node.label;
					listItem.dataset.nodeId = node.uid;
					selectedNodesList.append(listItem);
				}
			}
		}

		const minimumNodes = container === brainstormContainer ? 1 : 2;

		const submit = container.querySelector<HTMLButtonElement>('button.submit')!;
		submit.disabled = selectedNodes.length < minimumNodes;

		const notice = container.querySelector<HTMLParagraphElement>('.system-notice')!;
		notice.classList.toggle('hidden', selectedNodes.length >= minimumNodes);
	}
};

const updateSelectedSynthesiseNodes = (synthesiseNodes: Array<NCNode>) => {
	const selectedItemsContainer = document.querySelector<HTMLDivElement>('.selected-input')!;
	const selectedItemsWrapper = document.querySelector<HTMLDivElement>(
		'.selected-input .selected-items'
	)!;
	removeChildren(selectedItemsWrapper);

	for (const item of synthesiseNodes) {
		if (item.label) {
			const itemElement = document.createElement('li');
			const deleteButton = document.createElement('button');
			itemElement.classList.add('selected-item');
			deleteButton.classList.add('delete');
			deleteButton.innerText = 'x';
			deleteButton.setAttribute('title', 'Remove item');
			itemElement.innerText = item.label;
			selectedItemsWrapper.appendChild(itemElement);
			itemElement.appendChild(deleteButton);
		}
	}

	// add tooltips to delete buttons
	addToolTipWithTitle('button.delete');
	globalThis.addNumberOfNodeItems!(selectedItemsContainer);

	const noLikedItem = document.querySelector<HTMLParagraphElement>(
		'.selected-item-wrapper .no-item'
	)!;

	if (synthesiseNodes.length === 0) {
		noLikedItem.classList.remove('hidden');
		// selectedItemsContainer.querySelector('input')!.disabled = true;
	} else {
		noLikedItem.classList.add('hidden');
		// selectedItemsContainer.querySelector('input')!.disabled = false;
	}

	const selectedItemContainer = document.querySelector<HTMLDivElement>('.selected-input')!;

	const deleteButtons = selectedItemContainer.querySelectorAll('button.delete');
	deleteButtons.forEach((button) => {
		button.addEventListener('click', (event) => {
			const { target } = event;
			const selectedItem = (target as HTMLElement).closest('.selected-item');
			const itemLabel = selectedItem?.textContent?.slice(0, -1);
			const item = synthesiseNodes.filter((node) => node.label === itemLabel);
			synthesiseNodes.indexOf(item[0]);

			// remove deleted item from synthesiseNodes array
			synthesiseNodes.splice(synthesiseNodes.indexOf(item[0]), 1);
			selectedItem?.remove();

			globalThis.updateTaskOptionsInPanel!();
			showSynthesiseNodesPrompt(synthesiseNodes);
		});
	});

	globalThis.updateTaskOptionsInPanel!();
};

export const showSynthesiseNodesPrompt = (synthesiseNodes: Array<NCNode>) => {
	const graph = globalThis.neuroCreate.graph;
	updateSelectedSynthesiseNodes(synthesiseNodes);
	if (graph) {
		showNonBlockingMessage(
			`${getSelectedWordsHtml(synthesiseNodes)}
<small><br><br>${
				synthesiseNodes.length === 0 ? 'Tap a word or' : 'Add more words or tap'
			} <strong>Done</strong> to finish.</small>`,
			[
				{
					label: 'Done',
					primary: true,
					onClick: () => {
						graph.stopSelectingNodes();
						updateSelectedSynthesiseNodes(synthesiseNodes);
					},
				},
			]
		);
	}
};

// Also used for brainstorm
export const showCommonIdeasNodesPrompt = (selectedNodes: Array<NCNode>) => {
	const graph = globalThis.neuroCreate.graph;
	updateSelectedCommonIdeasNodes(selectedNodes);
	if (graph) {
		showNonBlockingMessage(
			`${getSelectedWordsHtml(selectedNodes)}
<small><br><br>${
				selectedNodes.length === 0 ? 'Tap a word or' : 'Add more words or tap'
			} <strong>Done</strong> to finish.</small>`,
			[
				{
					label: 'Done',
					primary: true,
					onClick: () => {
						graph.stopSelectingNodes();
						updateSelectedCommonIdeasNodes(selectedNodes);
					},
				},
			]
		);
	}
};

export const resizeLongSuggestionsToFit = () => {
	const results = document.querySelectorAll<HTMLLIElement>(
		'#empathise-container .pairs-list li, #spark-container .pairs-list li span'
	);
	results.forEach((result) => {
		const textWidth = measureText(result.innerText);
		if (textWidth > 80) {
			result.classList.add('long-word');
		} else if (textWidth > 100) {
			result.classList.add('longer-word');
		} else {
			// Leave as full size
		}
	});
};

export const closeAiContainers = (aiName: undefined | string = undefined): void => {
	// Close any AI other tools that may be open
	const containerNames = ['spark', 'empathise', 'generate', 'search', 'hearted'];
	containerNames
		.filter((n) => n !== aiName)
		.forEach((n) => {
			document.querySelector(`#${n}-container`)?.classList.add('hidden');
		});
};

/*
 * Opens tool panel, if not already open.
 */
export const toggleToolContainer = (aiName: string, node: NCNode): void => {
	const containerEl = document.querySelector(`#${aiName}-container`)!;
	if (node.label || node.src) {
		containerEl.querySelector('.breadcrumbs li.node-title')!.innerHTML = node.label
			? `“${node.label}”`
			: node.src
				? 'Image'
				: '';
		const engineImg = containerEl.querySelector<HTMLImageElement>('.breadcrumbs .engine img');
		if (engineImg) {
			engineImg.src = `/assets/icon/dashboard/${document.body.dataset.engine!.toLowerCase()}.png`;
		}
	}
	const didOpen = Boolean(
		containerEl &&
			containerEl.classList.contains('hidden') &&
			!containerEl.classList.toggle('hidden')
	);

	if (didOpen) {
		closeAiContainers(aiName);
	}
};

export const setActiveStage = (graphMode: GraphMode) => {
	document.querySelector('#board-stage-switcher li.selected')!.classList.remove('selected');
	document
		.querySelector(`#board-stage-switcher li[data-mode='${graphMode}']`)!
		.classList.add('selected');
};

export const setStageCompletionStatuses = () => {
	document.querySelectorAll<HTMLUListElement>('#board-stage-switcher li').forEach((li) => {
		const graphMode = li.dataset.mode! as GraphMode;

		const graph = globalThis.neuroCreate.graph;
		if (graph) {
			if (graphMode === 'inspire') {
				li.classList.toggle('completed', Boolean(graph.currentUser.settings.shown_inspire));
			} else if (graphMode === 'ideate') {
				li.classList.toggle('completed', Boolean(graph.currentUser.settings.shown_ideate));
			} else if (graphMode === 'moodboard') {
				li.classList.toggle('completed', Boolean(graph.currentUser.settings.shown_synthesise));
			}
		}
	});
};

const eliminateDuplicatesFilter = (
	value: string | undefined,
	index: number,
	self: Array<string | undefined>
) => {
	return self.indexOf(value) === index;
};

const getDepth = (graph: Graph) => {
	const nonAiNodes = graph.nodes.filter((n) => !n.parent);
	const nodesWithParent = graph.nodes.filter((n) => n.parent);
	const numberOfGrandchildNodesToExplore = Math.ceil(nonAiNodes.length * 0.4) || 1; // 40% of nodes not created by AI
	const depth = Math.min(
		numberOfGrandchildNodesToExplore,
		nodesWithParent
			.filter((n, index1) => {
				return nodesWithParent.some(
					(n2, index2) =>
						index1 !== index2 &&
						n2.parent?.uid === n.uid &&
						nodesWithParent.some((n3, index3) => index2 !== index3 && n2.parent?.uid === n3.uid)
				);
			})
			.map((n) => n.parent?.uid)
			.filter(eliminateDuplicatesFilter).length
	);

	return {
		score: depth / numberOfGrandchildNodesToExplore,
		number: depth,
		max: numberOfGrandchildNodesToExplore,
	};
};

const getExploration = (graph: Graph) => {
	const nonAiNodes = graph.nodes.filter((n) => !n.parent);
	const numberToExplore = Math.ceil(nonAiNodes.length * 0.7) || 1; // 70% of nodes not created by AI
	const parentUids = graph.nodes
		.filter((n) => n.parent) // Nodes with a parent
		//.filter((n) => nonAiNodes.some((n2) => n2.uid === n.parent?.uid)) // Parent matches one of the non-AI nodes
		.map((n) => n.parent?.uid)
		.filter(eliminateDuplicatesFilter);
	return {
		score: Math.min(parentUids.length / numberToExplore, 1),
		number: parentUids.length,
		max: numberToExplore,
	};
};

export const getInspireProgressFactors = (graph: Graph) => {
	const numberNodes = graph.nodes.length;
	const numConnections = graph.links.length;
	const maxNodes = 20;

	const nodesScore = Math.min(1, numberNodes / maxNodes);

	const connectedness = Math.min(1, numConnections > 0 ? numConnections / numberNodes : 0);
	const maxToolsUsed = 4;

	const numberToolsUsed = Object.entries(graph.currentUser.settings.used || {}).filter(
		([key, value]) => value && key !== 'search' // ignore 'search' as it is not considered an AI tool
	).length;
	const depth = getDepth(graph);
	const exploration = getExploration(graph);

	// Now calculate total score
	const factors = [Math.min(nodesScore, 1)];
	// Connectedness progress should be in proportion with number of nodes
	factors.push(connectedness * factors[0]);
	factors.push(depth.score);
	factors.push(exploration.score);
	factors.push(numberToolsUsed / maxToolsUsed);

	const sum = factors.reduce((a, b) => a + b, 0);
	const total = sum / factors.length || 0;

	return {
		nodes: {
			score: nodesScore,
			number: numberNodes,
			max: maxNodes,
		},
		connectedness: {
			score: connectedness,
			number: numConnections,
			max: numberNodes,
		},
		depth,
		exploration,
		toolsUsed: {
			score: numberToolsUsed / maxToolsUsed,
			number: numberToolsUsed,
			max: maxToolsUsed,
		},
		total,
	};
};

export const updateInspireProgress = (graph: Graph) => {
	const { nodes, connectedness, depth, exploration, toolsUsed, total } =
		getInspireProgressFactors(graph);

	document.querySelector<HTMLElement>('#nodes-count')!.innerHTML = `${Math.floor(
		100 * nodes.score
	)} <span class="hidden">${nodes.number} / ${nodes.max} nodes</span>`;
	document.querySelector<HTMLElement>('#connectedness')!.innerHTML = `${Math.floor(
		100 * connectedness.score
	)} <span class="hidden">${connectedness.number} / ${connectedness.max} connections</span>`;
	document.querySelector<HTMLElement>('#exploration')!.innerHTML = `${Math.floor(
		100 * exploration.score
	)} <span class="hidden">${exploration.number} / ${exploration.max} explorations</span>`;
	document.querySelector<HTMLElement>('#depth')!.innerHTML = `${Math.floor(
		100 * depth.score
	)} <span class="hidden">${depth.number} / ${depth.max} deep explorations</span>`;
	document.querySelector<HTMLElement>('#ai-tools-used')!.innerHTML = `${Math.floor(
		100 * toolsUsed.score
	)} <span class="hidden">${toolsUsed.number} / ${toolsUsed.max} tools</span>`;

	const percent = Math.ceil(total * 100);

	document.querySelector<HTMLProgressElement>('#inspire-progress progress')!.value = percent;
	document.querySelector('#inspire-percent')!.innerHTML = `${percent}%`;
};

export const isBoardOwner = () => {
	return document.body.dataset.boardOwner === 'true';
};

export const toggleGroupEditControls = (graph: Graph) => {
	if (graph.activeGroup) {
		document.querySelector('#remove-group')!.classList.remove('disabled');
		document.querySelector('#edit-group')!.classList.remove('disabled');
	} else {
		document.querySelector('#remove-group')!.classList.add('disabled');
		document.querySelector('#edit-group')!.classList.add('disabled');
	}
};
