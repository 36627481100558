// Client ID and API key from the Developer Console
import { updateGoogleToken } from '../apiRequests';
import { showPresentationPreview } from '../ui/slidesUI';
import { PresentationInfo } from '../../../../src/commonTypes';
import { createDialog, showAlert } from '../ui/uiUtils';
import {
	addAssetsSlides,
	addChangeSlide,
	addInspirationSlides,
	addLogisticsSlide,
	addMotivationsSlides,
	addPersonasSlides,
	addProjectVisionSlide,
	addBrandStrategySlides,
	addProductStrategySlides,
	addTargetAudienceSlides,
	addTitleSlide,
	addConceptualTerritorySlides,
} from './slideContent';
import { setupFlowCreateTheme } from './slideTheme';

const CLIENT_ID = document.body.dataset.googleClientId!;
const API_KEY = document.body.dataset.googleApiKey!;

// Discovery doc URL for APIs used by the quickstart
const DISCOVERY_DOC = 'https://slides.googleapis.com/$discovery/rest?version=v1';

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
const SCOPES = 'https://www.googleapis.com/auth/presentations';

let tokenClient: google.accounts.oauth2.TokenClient;

const loadingIndicator = document.querySelector<HTMLParagraphElement>('#slides-loading')!;
const loginButton = document.querySelector<HTMLButtonElement>('#google-slides-login')!;
const syncButton = document.querySelector<HTMLButtonElement>('#google-slides-sync')!;
const googleSlidesOutput = document.querySelector<HTMLParagraphElement>('#google-slides-output')!;
const permissionInfo = document.querySelector<HTMLParagraphElement>(
	'.export-options .permission-info'
)!;

permissionInfo.addEventListener('click', () => {
	const whatIsGoogleAccountP = document.createElement('p');
	whatIsGoogleAccountP.classList.add('minimal-message');
	whatIsGoogleAccountP.innerHTML =
		'Google accounts are used to sign in to Google services including Google Slides. If your email address ends in <strong>@gmail.com</strong>, you already have a Google account. Otherwise, you can <a href="https://support.google.com/accounts/answer/27441?hl=en" target="_blank">create a Google account</a> linked to your email address and then use it to access Google Slides.';
	const firstP = document.createElement('p');
	firstP.classList.add('minimal-message');
	firstP.innerHTML =
		'When you use the button <small><strong>Grant access to Google&nbsp;Slides</strong></small>, you will be asked to grant access to <small><em>"See, edit, create, and delete all your Google Slides presentations"</em></small>:';
	const image = document.createElement('img');
	image.src = '/assets/google-slides-access.png';
	image.alt = 'Google Slides access request';
	image.width = 400;
	image.height = 127;
	image.classList.add('screenshot');
	const secondP = document.createElement('p');
	secondP.classList.add('minimal-message');
	secondP.append(image);
	const thirdP = document.createElement('p');
	thirdP.classList.add('minimal-message');
	thirdP.innerHTML =
		'If you grant access, FlowCreate will use this to generate new presentations based on your FlowCreate boards, but <strong>will never read or edit any presentations that were not created by FlowCreate</strong>. FlowCreate will also <strong>never delete</strong> presentations.<br><br>';

	createDialog('About Google Slides access', [whatIsGoogleAccountP, firstP, secondP, thirdP], []);
});

/**
 * Callback after api.js is loaded.
 */
const gapiLoaded = () => {
	if (loginButton) {
		gapi.load('client', initializeGapiClient);
	}
};
neuroCreate.gapiLoaded = gapiLoaded;

/**
 * Callback after the API client is loaded. Loads the
 * discovery doc to initialize the API.
 */
function initializeGapiClient() {
	gapi.client
		.init({
			apiKey: API_KEY,
			discoveryDocs: [DISCOVERY_DOC],
		})
		.then(() => {
			const token = loadToken();
			if (token !== undefined) {
				gapi.client.setToken(token);
			}

			loadingIndicator.classList.add('hidden');
		});
}

const loadToken = (): gapi.client.TokenObject | undefined => {
	const accessToken = document.body.dataset.googleToken;
	if (accessToken) {
		return {
			access_token: accessToken,
		};
	}

	return undefined;
};

let loginOnly = true;
let loggedIn = false;

/**
 * Callback after Google Identity Services are loaded.
 */
const gisLoaded = () => {
	if (loginButton) {
		tokenClient = google.accounts.oauth2.initTokenClient({
			client_id: CLIENT_ID,
			scope: SCOPES,
			callback: (resp) => {
				if (resp.error !== undefined) {
					throw resp;
				}

				console.log(resp);
				updateGoogleToken(document.body.dataset.userId!, resp.access_token);
				setPermissionGranted();
				loggedIn = true;

				if (!loginOnly) {
					createOrLoadPresentation();
				}
			},
		});
	}
};
neuroCreate.gisLoaded = gisLoaded;

/**
 *  Sign in the user upon button click.
 */
function startGoogleSlideAuthentication() {
	loadingIndicator.classList.remove('hidden');
	if (gapi.client.getToken() === null) {
		// Prompt the user to select a Google Account and ask for consent to share their data
		// when establishing a new session.
		tokenClient.requestAccessToken({ prompt: 'consent' });
	} else {
		// Skip display of account chooser and consent dialog for an existing session.
		tokenClient.requestAccessToken({ prompt: '' });
	}
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function googleSignOut() {
	const token = gapi.client.getToken();
	if (token !== null) {
		google.accounts.oauth2.revoke(token.access_token, () => {
			console.log('User signed out from Google.');
		});
		gapi.client.setToken(null);
	}
}

const getPresentation = async (): Promise<
	gapi.client.Response<gapi.client.slides.Presentation>
> => {
	const graph = globalThis.neuroCreate.graph!;

	const presentationId = graph.currentUser.settings.presentationId;
	if (presentationId) {
		return gapi.client.slides.presentations
			.get({
				presentationId: presentationId,
			})
			.then((response) => {
				return response;
			})
			.catch(() => {
				// If the presentation doesn't exist, create it
				graph.currentUser.settings.presentationId = undefined;
				return getPresentation();
			});
	} else {
		const presentationTitle = graph.boardTitle;

		return gapi.client.slides.presentations.create(
			{},
			{
				title: `${presentationTitle} [FlowCreate]`,
				// Only title can be specified here, other properties (like masters) appear to be read-only (not clear from docs)
			}
		);
	}
};

let latestPresentationInfo: PresentationInfo | undefined;

const recreateSlides = (presentation: gapi.client.slides.Presentation) => {
	if (!latestPresentationInfo) {
		throw new Error('Missing presentation info');
	}

	const requests: Array<gapi.client.slides.Request> = [];

	requests.push(...setupFlowCreateTheme(presentation));

	if (presentation.slides) {
		requests.push(
			...presentation.slides.map(
				(slide): gapi.client.slides.Request => ({
					deleteObject: {
						objectId: slide.objectId,
					},
				})
			)
		);
	}

	requests.push(...addTitleSlide(latestPresentationInfo));
	requests.push(...addLogisticsSlide(latestPresentationInfo));
	requests.push(...addProjectVisionSlide(latestPresentationInfo));
	requests.push(...addChangeSlide(latestPresentationInfo));

	requests.push(...addBrandStrategySlides(latestPresentationInfo, true));
	requests.push(...addTargetAudienceSlides(latestPresentationInfo, true));
	requests.push(...addPersonasSlides(latestPresentationInfo, true));
	requests.push(...addMotivationsSlides(latestPresentationInfo, true));
	requests.push(...addProductStrategySlides(latestPresentationInfo, true));

	requests.push(...addConceptualTerritorySlides(latestPresentationInfo));

	requests.push(...addBrandStrategySlides(latestPresentationInfo, false));
	requests.push(...addTargetAudienceSlides(latestPresentationInfo, false));
	requests.push(...addPersonasSlides(latestPresentationInfo, false));
	requests.push(...addMotivationsSlides(latestPresentationInfo, false));
	requests.push(...addProductStrategySlides(latestPresentationInfo, false));

	requests.push(...addInspirationSlides(latestPresentationInfo));
	requests.push(...addAssetsSlides(latestPresentationInfo));

	return requests;
};

const createOrLoadPresentation = () => {
	const graph = globalThis.neuroCreate.graph!;
	googleSlidesOutput.classList.add('hidden');
	loadingIndicator.classList.remove('hidden');

	try {
		getPresentation().then((response) => {
			const presentation = response.result;
			const presentationId = presentation.presentationId!;
			console.log(presentation.masters);
			console.log(`Loaded presentation with ID: ${presentationId}`);
			const googleSlidesPresentation = response.result;
			console.log(googleSlidesPresentation);
			graph.setPresentationId(presentationId);

			const requests = recreateSlides(googleSlidesPresentation);
			console.log(requests);
			gapi.client.slides.presentations
				.batchUpdate(
					{
						presentationId,
					},
					{
						requests,
					}
				)
				.then(() => {
					console.log('created slides');
					window.open(
						`https://docs.google.com/presentation/d/${presentationId}/edit`,
						`presentation_${presentationId}`
					);
					// const replies = createSlideResponse.result.replies;
					// const objectId = replies && replies[0].createSlide?.objectId;
					// console.log(`Created slide with ID: ${objectId}`);
				})
				.catch((err: { result: { error: { message: string } } }) => {
					console.error(err.result.error.message);
					showAlert(
						'Unable to generate slides. Please try again or contact us if it continues to fail.'
					);
				});
		});
	} catch (err) {
		console.error(err);
		return;
	}
};

const setPermissionGranted = () => {
	loginButton.classList.add('hidden');
	permissionInfo.classList.add('hidden');
	document.querySelector('#google-slides-loggedin')!.classList.remove('hidden');
	if (document.body.dataset.canGenerateSlides === 'true') {
		syncButton.querySelector('.preview-only')?.classList.add('hidden');
	}
	loadingIndicator.classList.add('hidden');
};

if (loginButton && document.body.dataset.googleToken) {
	setPermissionGranted();
}

if (loginButton) {
	loginButton.addEventListener('click', (event) => {
		event.preventDefault();

		startGoogleSlideAuthentication();
	});
}

if (syncButton) {
	syncButton.addEventListener('click', (event) => {
		event.preventDefault();

		showPresentationPreview((presentationInfo) => {
			latestPresentationInfo = presentationInfo;
			if (!loggedIn) {
				loginOnly = false;
				startGoogleSlideAuthentication();
			} else {
				createOrLoadPresentation();
			}
		});
	});
}
