import { NCLink, NCNode } from '../../../../src/commonTypes';
import {
	getEquationForLine,
	isInRangeOfLink,
	intersection,
	getDistanceFromLine,
	addForce,
} from './geometryUtils';
import { areLinksCrossing, getLinksInvolvingNode, isNodeInLink } from './linkUtils';

const CROSSOVER_THRESHOLD = 510;
const IS_NEAR_THRESHOLD = 90;

const isNodeNearLink = (
	node: NCNode,
	link: NCLink
): {
	isInCrossoverRange: boolean;
	isNear: boolean;
	xDistanceFromLine?: number;
	yDistanceFromLine?: number;
	distanceFromLine?: number;
} => {
	const { source, target } = link;
	if (isNodeInLink(node, link)) {
		// If the node is an end-point of the line, ignore it
		return { isNear: false, isInCrossoverRange: false };
	}

	if (!isInRangeOfLink(node, { from: source, to: target })) {
		// If beyond the bounds of the link then this node is not too near
		return {
			isNear: false,
			isInCrossoverRange: false,
		};
	}

	const { xDistanceFromLine, yDistanceFromLine, distanceFromLine } = getDistanceFromLine(
		node,
		getEquationForLine({ from: source, to: target })
	);

	const absDistanceFromLine = Math.abs(distanceFromLine);

	return {
		isInCrossoverRange: absDistanceFromLine < CROSSOVER_THRESHOLD,
		isNear: absDistanceFromLine < IS_NEAR_THRESHOLD,
		distanceFromLine,
		yDistanceFromLine,
		xDistanceFromLine,
	};
};

// used for debugging
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const maybeLog = (logLine: string, node: NCNode) => {
	if (node.label === 'one') {
		console.log(logLine);
	}
};

const isNodeOnTheBestSideOfTheLine = (
	node: NCNode,
	link: NCLink,
	xDistanceFromLine: number,
	yDistanceFromLine: number,
	linksForNode: Array<NCLink>
): { isOnBestSide: boolean; crossingLinks: Array<NCLink> } => {
	// for each link that the node is involved in, how many would cross the current link if the node was on the other side of the line?
	// Pick the direct with the least crossovers
	const currentlyCrossingLinks = linksForNode.filter((l) => areLinksCrossing(link, l));

	const nodePlacedOnOtherSideOfLine = {
		...node,
		x: node.x + 10 * Math.sign(xDistanceFromLine) + xDistanceFromLine,
		y: node.y + 10 * Math.sign(yDistanceFromLine) + yDistanceFromLine,
	};

	const otherSideCrossingLinks = linksForNode.filter((l) => {
		const linkIfOnOtherSide =
			l.source.uid === node.uid
				? { ...l, source: nodePlacedOnOtherSideOfLine }
				: { ...l, target: nodePlacedOnOtherSideOfLine };
		const isCrossing = areLinksCrossing(link, linkIfOnOtherSide);
		return isCrossing;
	});

	return {
		isOnBestSide: currentlyCrossingLinks.length <= otherSideCrossingLinks.length,
		crossingLinks: currentlyCrossingLinks,
	};
};

const SHIFT_CONSTANT = 5; // A constant found to work well via experimentation

// Calculate a shift in towards or away from a line
export const getShiftFromDistanceToLine = (
	direction: 1 | -1, // direction determines if we need to inverse the shift
	xDistanceFromLine: number, // Typically a number from 0 to CROSSOVER_THRESHOLD
	yDistanceFromLine: number, // Typically a number from 0 to CROSSOVER_THRESHOLD
	alpha: number
) => {
	// We want a stronger force if we are moving across the line
	const directionStrengthMultiplier = direction < 0 ? 5 : 1;

	const distanceFromLine = Math.hypot(xDistanceFromLine, yDistanceFromLine);

	/*
	 * We want to convert the distanceFromLine to a number between 1 and 2.
	 * We do so using distanceFromLine / CROSSOVER_THRESHOLD.
	 * `Math.max` ensures that when the distance is less than 1 we get use 1 instead, to ensure that 1/distanceFromLine gives a value between 0 - 1
	 * `Math.log2(2 - result)` changes the curve for smoother movement (range 1-2 instead of 0-1)
	 */
	const distanceToShift =
		directionStrengthMultiplier *
		SHIFT_CONSTANT *
		Math.log2(2 - Math.min(1, distanceFromLine / CROSSOVER_THRESHOLD)) *
		alpha;

	const angleOfLine = Math.atan2(yDistanceFromLine, xDistanceFromLine);
	const xDistanceToShift = distanceToShift * Math.cos(angleOfLine);
	const yDistanceToShift = distanceToShift * Math.sin(angleOfLine);

	return {
		xShift: xDistanceToShift,
		yShift: yDistanceToShift,
	};
};

export default function (allLinks: Array<NCLink>) {
	let nodes: Array<NCNode>,
		links: Array<NCLink> = allLinks,
		strength = 1;

	function force(alpha: number) {
		for (const node of nodes) {
			const linksForNode = getLinksInvolvingNode(node, links);

			for (const link of links) {
				const { isNear, isInCrossoverRange, xDistanceFromLine, yDistanceFromLine } = isNodeNearLink(
					node,
					link
				);

				if (isInCrossoverRange) {
					const { isOnBestSide, crossingLinks } = isNodeOnTheBestSideOfTheLine(
						node,
						link,
						xDistanceFromLine!,
						yDistanceFromLine!,
						linksForNode
					);
					const shouldInvertTransformation = !isOnBestSide;

					const direction = shouldInvertTransformation ? -1 : 1;

					let xDistanceToTraverse = -xDistanceFromLine!;
					let yDistanceToTraverse = -yDistanceFromLine!;
					if (shouldInvertTransformation && crossingLinks.length === 1) {
						const crossPoint = intersection(
							link.source,
							link.target,
							crossingLinks[0].source,
							crossingLinks[0].target
						);
						if (crossPoint) {
							xDistanceToTraverse = crossPoint.x - node.x;
							yDistanceToTraverse = crossPoint.y - node.y;
						}
					}

					if (isNear || direction < 0) {
						const { xShift, yShift } = getShiftFromDistanceToLine(
							direction,
							xDistanceToTraverse,
							yDistanceToTraverse,
							alpha
						);

						// maybeLog(
						// 	`${link.source.label || '-'}<->${
						// 		link.target.label || '-'
						// 	}, direction: ${direction}, xTraverse: ${xDistanceToTraverse!}, xShift: ${xShift.toPrecision(
						// 		5
						// 	)}, yShift: ${yShift.toPrecision(5)}, yTraverse: ${yDistanceToTraverse!}`,
						// 	node
						// );
						// Shift node one way
						addForce(node, { x: xShift, y: yShift });

						// Shift the line the other way by a smaller amount
						const relativeShiftFactor = 0.2;
						addForce(link.source, {
							x: -xShift * relativeShiftFactor,
							y: -yShift * relativeShiftFactor,
						});
						addForce(link.target, {
							x: -xShift * relativeShiftFactor,
							y: -yShift * relativeShiftFactor,
						});
					}
				}
			}
		}
	}

	force.initialize = function (_: Array<NCNode>) {
		nodes = _;
	};

	force.links = function (_: Array<NCLink>) {
		links = _;
	};

	force.strength = function (_: number) {
		return arguments.length ? ((strength = +_), force) : strength;
	};

	return force;
}
