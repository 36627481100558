import Graph from './Graph';
import * as d3 from 'd3';
import avoidLinkForce from './avoidLinkForce';
import linkLengthForce from './linkLengthForce';
import groupForce from './groupForce';

export const initPhysics = (graph: Graph) => {
	if (graph.isPhysicsActive()) {
		const simulation = d3
			.forceSimulation(graph.nodes)
			.force(
				'collision',
				d3
					.forceCollide()
					.radius(
						(node) => graph.nodeRadius * (graph.nodes[node.index!]?.style === 'rect' ? 2.5 : 1.5)
					)
					.strength(0.3)
			)
			.force('linkCollision', avoidLinkForce(graph.links))
			.force('linkLength', linkLengthForce(graph.links))
			.force('group', groupForce(graph.nodeGroups))
			.on('tick', graph.onTick.bind(graph))
			.on('end', () => {
				graph.lockAllNodesInPlace();
			});
		//.alphaDecay(0.05).velocityDecay(0.2);
		return simulation;
	}
	return undefined;
};

export const resetPhysicsMovement = (graph: Graph) => {
	if (graph.simulation) {
		graph.simulation.alpha(1).restart();
	}
};

export const ensurePhysicsAlphaIsHighEnough = (graph: Graph) => {
	if (graph.simulation) {
		if (graph.simulation.alpha() < 0.5) {
			graph.simulation.alpha(0.5);
		}
	}
};

export const updatePhysics = (graph: Graph) => {
	if (graph.isPhysicsActive() && graph.simulation) {
		graph.simulation.nodes(graph.nodes);
		const linkCollision = graph.simulation.force('linkCollision') as ReturnType<
			typeof avoidLinkForce
		>;
		linkCollision?.links(graph.links);
		const linkLength = graph.simulation.force('linkLength') as ReturnType<typeof linkLengthForce>;
		linkLength?.links(graph.links);
		const group = graph.simulation.force('group') as ReturnType<typeof groupForce>;
		group?.groups(graph.nodeGroups);
		graph.simulation.alpha(1).restart();
	}
};
