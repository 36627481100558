import { LineSegment, NCLink, NCNode, Point, VelocityObject } from '../../../../src/commonTypes';
import { numberOfCrossingLinks } from './linkUtils';

export const getDistanceBetweenPoints = (point1: Point, point2: Point) => {
	return Math.hypot(point1.x - point2.x, point1.y - point2.y);
};

export const getCentralPoint = (points: Array<Point>): Point => {
	const minX = Math.min(...points.map((p) => p.x));
	const maxX = Math.max(...points.map((p) => p.x));
	const minY = Math.min(...points.map((p) => p.y));
	const maxY = Math.max(...points.map((p) => p.y));

	const centerX = minX + (maxX - minX) / 2;
	const centerY = minY + (maxY - minY) / 2;

	return { x: centerX, y: centerY };
};

export const getPointsSortedByClosestToCenter = <T extends Point>(points: Array<T>): Array<T> => {
	const centralPoint = getCentralPoint(points);

	const sortedPoints = [...points].sort((pointA, pointB) => {
		const distanceA = getDistanceBetweenPoints(pointA, centralPoint);
		const distanceB = getDistanceBetweenPoints(pointB, centralPoint);

		return distanceA - distanceB;
	});

	return sortedPoints;
};

export const isInRangeOfLink = (point: Point, { from, to }: LineSegment) => {
	// See if point is within bounds of the segment of line.

	const minY = Math.min(from.y, to.y);
	const minX = Math.min(from.x, to.x);
	const maxY = Math.max(from.y, to.y);
	const maxX = Math.max(from.x, to.x);

	return !((point.y > maxY || point.y < minY) && (point.x > maxX || point.x < minX));
};

export const isPointTooClose = (points: Array<Point>, point: Point, radius = 64): boolean => {
	for (const otherPoint of points) {
		const distance = getDistanceBetweenPoints(point, otherPoint);
		if (distance <= radius) {
			return true;
		}
	}
	return false;
};

export const findFreeSpace = (
	points: Array<Point>,
	proposedPoint: Point,
	nodeRadius: number,
	requiresMoreSpace = false
): Point => {
	let point = { x: proposedPoint.x, y: proposedPoint.y };
	while (
		isPointTooClose(points, point, (requiresMoreSpace ? 4 * nodeRadius : 2 * nodeRadius) + 64)
	) {
		point = { x: point.x + 10, y: point.y };

		if (point.x > proposedPoint.x + 1200) {
			point = { x: proposedPoint.x + 10, y: point.y + 10 };
		}
	}
	return point;
};

function getProposedLink(
	linkedNode: NCNode | undefined,
	point: {
		x: number;
		y: number;
	}
) {
	if (linkedNode) {
		return {
			source: linkedNode,
			target: { ...point, uid: 'new' },
		};
	}
	return undefined;
}

const MAX_ATTEMPTS = 500;

export const findFreeSpaceRadial = (
	points: Array<Point>,
	links: Array<NCLink>,
	proposedPoint: Point,
	linkedNode: NCNode | undefined,
	nodeRadius: number,
	requiresMoreSpace = false
): Point => {
	let point = { x: proposedPoint.x, y: proposedPoint.y };
	let link: { source: Point & { uid: string }; target: Point & { uid: string } } | undefined =
		getProposedLink(linkedNode, point);
	let attempts = 0;

	let alternativePoint: { crossing: number; point: Point } | undefined = undefined;

	// We work our way around the circle, starting at 0 degrees and moving clockwise
	// We move in a spiral pattern, starting at the proposed point and moving outwards
	let distance = 10;
	let angle = 0;
	while (
		isPointTooClose(points, point, (requiresMoreSpace ? 4 * nodeRadius : 2 * nodeRadius) + 64) ||
		(link && numberOfCrossingLinks(link, links) > 0)
	) {
		distance += 5;
		angle = (angle + 15) % 360;
		const radians = (angle * Math.PI) / 180;
		point = {
			x: proposedPoint.x + distance * Math.cos(radians),
			y: proposedPoint.y + distance * Math.sin(radians),
		};
		link = getProposedLink(linkedNode, point);

		if (link) {
			const isTooClose = isPointTooClose(
				points,
				point,
				(requiresMoreSpace ? 4 * nodeRadius : 2 * nodeRadius) + 64
			);
			if (!isTooClose) {
				const crossing = numberOfCrossingLinks(link, links);

				if (alternativePoint === undefined || crossing < alternativePoint.crossing) {
					alternativePoint = { crossing, point };
				}
			}
		}

		attempts++;
		// Ensure we don't get stuck in an infinite loop
		if (attempts > MAX_ATTEMPTS * Math.max(0.5, Math.random())) {
			// Return the best alternative point we found which crosses a minimal number of links
			return alternativePoint ? alternativePoint.point : point;
		}
	}
	return point;
};

// Equation of form y = mx + c where m is gradient and c is yIntercept
type LinearEquation = { gradient: number; yIntercept: number };

export const getEquationForLine = ({ from, to }: LineSegment): LinearEquation => {
	// Work out an equation for the line:
	const yDelta = to.y - from.y;
	const xDelta = to.x - from.x;
	const gradient = yDelta / xDelta;
	const yIntercept = gradient * (0 - from.x) + from.y;
	// Now equation is: y = gradient * x + yIntercept
	// Or: x = (y - yIntercept) / gradient

	return { gradient, yIntercept };
};

export const getPerpendicularLineThroughPoint = (
	equation: LinearEquation,
	point: Point
): LinearEquation => {
	const perpendicularGradient = -1 / equation.gradient;

	// Find perpendicular line through the point
	// y − y1 = m(x − x1)
	// y − point.x = m(x − point.y)
	// y = mx − (m * point.x) + point.y
	const yIntercept = -(perpendicularGradient * point.x) + point.y;

	// console.log(`gradient: ${equation.gradient}, perpendicularGradient: ${perpendicularGradient}`);

	return { gradient: perpendicularGradient, yIntercept };
};

export const findYForXValue = (equation: LinearEquation, xValue: number): number => {
	return equation.gradient * xValue + equation.yIntercept;
};

export const findXForYValue = (equation: LinearEquation, yValue: number): number => {
	return (yValue - equation.yIntercept) / equation.gradient;
};

export const getDistanceFromLine = (
	point: Point,
	equation: LinearEquation
): { yDistanceFromLine: number; xDistanceFromLine: number; distanceFromLine: number } => {
	const perpendicularEquation = getPerpendicularLineThroughPoint(equation, point);
	const segment: LineSegment = {
		from: { x: -1000, y: findYForXValue(equation, -1000) },
		to: { x: 1000, y: findYForXValue(equation, 1000) },
	};
	const perpendicularSegment: LineSegment = {
		from: { x: -1000, y: findYForXValue(perpendicularEquation, -1000) },
		to: { x: 1000, y: findYForXValue(perpendicularEquation, 1000) },
	};
	const closestPointOnLine = intersection(
		segment.from,
		segment.to,
		perpendicularSegment.from,
		perpendicularSegment.to,
		false
	);

	if (closestPointOnLine) {
		const yDistanceFromLine = closestPointOnLine.y - point.y;
		const xDistanceFromLine = closestPointOnLine.x - point.x;

		const distanceFromLine = getDistanceBetweenPoints(point, closestPointOnLine);

		return { yDistanceFromLine, xDistanceFromLine, distanceFromLine };
	} else {
		throw new Error(
			`Impossible state: line [${equation.gradient}x + ${equation.yIntercept}] should intersect with [${perpendicularEquation.gradient}x + ${perpendicularEquation.yIntercept}]`
		);
	}
};

// See https://stackoverflow.com/a/58657254/174979
export const intersection = (
	from1: Point,
	to1: Point,
	from2: Point,
	to2: Point,
	asSegments = true
): undefined | Point => {
	const dX: number = to1.x - from1.x;
	const dY: number = to1.y - from1.y;

	const determinant: number = dX * (to2.y - from2.y) - (to2.x - from2.x) * dY;
	if (determinant === 0) {
		// parallel lines
		return undefined;
	}

	const lambda: number =
		((to2.y - from2.y) * (to2.x - from1.x) + (from2.x - to2.x) * (to2.y - from1.y)) / determinant;
	const gamma: number =
		((from1.y - to1.y) * (to2.x - from1.x) + dX * (to2.y - from1.y)) / determinant;

	// check if there is an intersection
	if (asSegments && (!(0 <= lambda && lambda <= 1) || !(0 <= gamma && gamma <= 1))) {
		return undefined;
	}

	return {
		x: from1.x + lambda * dX,
		y: from1.y + lambda * dY,
	};
};

export const isPointInEllipse = (
	point: Point,
	centralPoint: Point,
	radiusX: number,
	radiusY: number
) => {
	const result =
		Math.pow(point.x - centralPoint.x, 2) / Math.pow(radiusX, 2) +
		Math.pow(point.y - centralPoint.y, 2) / Math.pow(radiusY, 2);
	return result <= 1;
};

export const addForce = (node: VelocityObject, force: Point) => {
	// Ensure vx and vy are not undefined
	node.vx = node.vx || 0;
	node.vy = node.vy || 0;

	// Add the force to the node's velocity
	node.vx += force.x;
	node.vy += force.y;
};
