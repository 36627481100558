import JsPDF from 'jspdf';
import { startLoading } from '../ui/uiUtils';
import { getUserLikes } from '../apiRequests';
import { filterLikesToType } from '../../../../src/commonMisc';
import { NCCluster } from '../../../../src/commonTypes';

export const exportIdeabox = async (doc: JsPDF, pageName: string) => {
	await startLoading(`Exporting ${pageName}...`, true);

	return new Promise<void>((resolve) => {
		let blockNumber = 0;
		let x = 55;
		const maxWidth = 200;
		let y = 20;
		const subheadingOffset = 24;

		const addNewBlock = (doc: JsPDF, name: string, height: number) => {
			if (y > 150) {
				doc.addPage();
				y = 25;
			}
			blockNumber++;
			doc.setFontSize(12);
			y += 4;
			if (height) {
				if (blockNumber % 2 === 0) {
					doc.setFillColor(245, 245, 245);
				} else {
					doc.setFillColor(255, 255, 255);
				}
				doc.rect(24, y - 5, 250, height + 3, 'F');
			}
			doc.text(name, x - subheadingOffset, y);
			doc.setFontSize(10);
		};

		doc.addPage();
		doc.setFontSize(22);
		doc.text(pageName, x - subheadingOffset, y);
		y += 4;

		getUserLikes(document.body.dataset.boardId!).then((likes) => {
			const likedImages = filterLikesToType(likes, 'search-image');
			if (likedImages.length > 0) {
				addNewBlock(doc, 'Image', 0); // Nil height for first block rect
				let maxImageHeight = 0;
				likedImages.forEach((l, i) => {
					if (l.url) {
						if (i > 0 && i % 5 === 0) {
							// New line if required
							y += maxImageHeight;
							x = 55;
							maxImageHeight = 0;
						}

						const imgProps = doc.getImageProperties(l.url);
						const height = (imgProps.height * 40) / imgProps.width;
						maxImageHeight = Math.max(maxImageHeight, height);
						doc.addImage({ imageData: l.url, x, y: y - 5, width: 40, height });
						x += 42;
					}
				});
				x = 55;
				y += maxImageHeight;
			}

			const likedLinks = [
				...filterLikesToType(likes, 'search-page'),
				...filterLikesToType(likes, 'article'),
			];
			if (likedLinks.length > 0) {
				addNewBlock(doc, 'Links', 5 * likedLinks.length);
				likedLinks.forEach((l) => {
					if (l.url) {
						doc.textWithLink(l.text || l.url, x, y, { url: l.url, maxWidth });
						y += 5;
					}
				});
			}

			const likedNodes = filterLikesToType(likes, 'node');
			if (likedNodes.length > 0) {
				const numberOfLines = Math.ceil(likedNodes.length / 10);
				addNewBlock(doc, 'Text', 4 * numberOfLines);
				doc.text(likedNodes.map((l) => l.text || '').join('    '), x, y, { maxWidth });
				y += 4 * numberOfLines;
			}

			const likedClusters = filterLikesToType(likes, 'cluster');
			if (likedClusters.length > 0) {
				addNewBlock(doc, 'Clusters', 5 * likedClusters.length);
				likedClusters.forEach((l) => {
					if (l.text) {
						const { group, values } = JSON.parse(l.text) as NCCluster;
						doc.text(`${group} - ${values.join(', ')}`, x, y, { maxWidth });
						y += 5;
					}
				});
			}

			const likedHaikus = filterLikesToType(likes, 'haiku');
			if (likedHaikus.length > 0) {
				const numberOfLines = Math.ceil(likedHaikus.length / 5);
				addNewBlock(doc, 'Haiku', 15 * numberOfLines);
				likedHaikus.forEach((l, i) => {
					if (l.text) {
						if (i > 0 && i % 4 === 0) {
							y += 15;
							x = 55;
						}
						doc.text(l.text, x, y, { maxWidth });
						x += 50;
					}
				});
				y += 15;
				x = 55;
			}

			const likedTaglines = filterLikesToType(likes, 'proverb');
			if (likedTaglines.length > 0) {
				addNewBlock(doc, 'Tagline', 5 * likedTaglines.length);
				doc.setFontSize(10);
				likedTaglines.forEach((l) => {
					if (l.text) {
						doc.text(l.text, x, y, { maxWidth });
						y += 5;
					}
				});
			}

			resolve();
		});
	});
};
