import { PresentationInfo } from '../../../../../src/commonTypes';
import {
	createAutogeneratedLabel,
	createDetails,
	createGenerateIdeateLabel,
	createImage,
	createSelectableCheckbox,
} from './utils';
import { finishLoading, startLoading } from '../uiUtils';
import { like, motivationsStakeholderImage, savePresentationInfo } from '../../apiRequests';
import { createStakeholderDiv } from '../generateUi';

const getMotivation = (
	item: PresentationInfo['motivations'][0],
	presentation: PresentationInfo
) => {
	const { value: motivations } = item;
	const [motivationsDetails, motivationsHeading] = createDetails(
		`Motivations ${motivations.theme ? `: ${motivations.theme}` : ''}`
	);

	if (!item.autogenerated) {
		motivationsHeading.append(createGenerateIdeateLabel());
	} else if (item.autogenerated) {
		motivationsHeading.append(createAutogeneratedLabel());
	}

	const personaCheckbox = createSelectableCheckbox(item, presentation)[0];
	motivationsHeading.prepend(personaCheckbox);

	const hasImages = motivations.stakeholders.find((stakeholder) => stakeholder.imageUrl);

	if (hasImages) {
		for (const s of motivations.stakeholders) {
			if (s.imageUrl) {
				motivationsHeading.append(createImage(s.imageUrl, 50, 'multiple'));
			}
		}
	} else {
		const generateImageButton = document.createElement('image-generator-with-quota');
		generateImageButton.hideBackground = true;
		generateImageButton.cost = motivations.stakeholders.length;
		generateImageButton.onClick = () => {
			const boardId = document.body.dataset.boardId!;
			startLoading('Generating motivations images...');

			// Generate image for each stakeholder
			const promises = motivations.stakeholders.map((stakeholder, index) => {
				return motivationsStakeholderImage(
					boardId,
					stakeholder.name,
					stakeholder.description,
					motivations.theme
				).then((imageResult) => {
					const stakeholderDiv = motivationsDetails.querySelector<HTMLDivElement>(
						`.stakeholder:nth-child(${index + 2})`
					)!;
					stakeholderDiv.prepend(createImage(imageResult.imageUrl, 140));
					motivationsHeading.append(createImage(imageResult.imageUrl, 50, 'multiple'));
					stakeholder.imageUrl = imageResult.imageUrl;
				});
			});

			Promise.all(promises).then(() => {
				generateImageButton.remove();
				finishLoading();
				like(boardId, 'motivations', JSON.stringify(motivations), null, false, motivations.theme);
				savePresentationInfo(document.body.dataset.boardId!, presentation);
			});
		};

		motivationsHeading.append(generateImageButton);
	}

	for (const stakeholder of motivations.stakeholders) {
		motivationsDetails.append(createStakeholderDiv(stakeholder));
	}

	return motivationsDetails;
};

export const getMotivationsSlideContent = (presentation: PresentationInfo) => {
	const motivationsContent = document.createElement('div');

	const introP = document.createElement('p');
	introP.innerHTML =
		'<small>Some motivations were <strong class="inspire-key">autogenerated</strong> from your project vision. You can create more with <strong class="ideate-key">Ideate > Generate > Motivations</strong>.</small>';
	motivationsContent.append(introP);

	presentation.motivations?.forEach((item) => {
		motivationsContent.append(getMotivation(item, presentation));
	});

	return motivationsContent;
};
