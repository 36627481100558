import { finishLoading, getBoardIdFromUrl, showAlert } from '../lib/ui/uiUtils';
import { requestAccessToBoard } from '../lib/apiRequests';

const requestAccessButton = document.querySelector<HTMLButtonElement>('#request-access-to-board')!;
requestAccessButton.addEventListener('click', () => {
	const boardId = getBoardIdFromUrl();
	const userId = document.body.dataset.userId!;
	requestAccessToBoard(boardId, userId).then(() => {
		showAlert("Your request has been sent. You'll be notified if the board owner grants access.");
		requestAccessButton.disabled = true;
		requestAccessButton.innerText = 'Access requested';
	});
});

finishLoading();
