const TEXT_WIDTHS = [
	0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
	0.2796875, 0.2765625, 0.3546875, 0.5546875, 0.5546875, 0.8890625, 0.665625, 0.190625, 0.3328125,
	0.3328125, 0.3890625, 0.5828125, 0.2765625, 0.3328125, 0.2765625, 0.3015625, 0.5546875, 0.5546875,
	0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.2765625,
	0.2765625, 0.584375, 0.5828125, 0.584375, 0.5546875, 1.0140625, 0.665625, 0.665625, 0.721875,
	0.721875, 0.665625, 0.609375, 0.7765625, 0.721875, 0.2765625, 0.5, 0.665625, 0.5546875, 0.8328125,
	0.721875, 0.7765625, 0.665625, 0.7765625, 0.721875, 0.665625, 0.609375, 0.721875, 0.665625,
	0.94375, 0.665625, 0.665625, 0.609375, 0.2765625, 0.3546875, 0.2765625, 0.4765625, 0.5546875,
	0.3328125, 0.5546875, 0.5546875, 0.5, 0.5546875, 0.5546875, 0.2765625, 0.5546875, 0.5546875,
	0.221875, 0.240625, 0.5, 0.221875, 0.8328125, 0.5546875, 0.5546875, 0.5546875, 0.5546875,
	0.3328125, 0.5, 0.2765625, 0.5546875, 0.5, 0.721875, 0.5, 0.5, 0.5, 0.3546875, 0.259375, 0.353125,
	0.5890625,
];
const TEXT_AVG = 0.5279276315789471;

export function measureText(str: string) {
	return (
		Array.from(str).reduce((acc, cur) => acc + (TEXT_WIDTHS[cur.charCodeAt(0)] ?? TEXT_AVG), 0) * 14
	);
}

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

/* eslint-disable */
// From https://stackoverflow.com/a/13348618
export const isChromiumBrowser = (): boolean => {
	// @ts-expect-error
	var isChromium = window.chrome;
	var winNav = window.navigator;
	var vendorName = winNav.vendor;
	// @ts-expect-error
	var isOpera = typeof window.opr !== 'undefined';
	var isIEedge = winNav.userAgent.indexOf('Edg') > -1;
	var isIOSChrome = winNav.userAgent.match('CriOS');

	if (isIOSChrome) {
		// is Google Chrome on IOS
		return true;
	} else if (
		isChromium !== null &&
		typeof isChromium !== 'undefined' &&
		vendorName === 'Google Inc.' &&
		isOpera === false &&
		isIEedge === false
	) {
		// is Google Chrome
		return true;
	} else {
		// not Google Chrome
		return false;
	}
};
/* eslint-enable */

export const tableToString = (tableContent: HTMLTableElement): string => {
	const headers = tableContent.querySelectorAll('th');
	const rows = tableContent.querySelectorAll('tr');
	const textLines = [] as string[];

	for (let i = 0; i < rows.length; i++) {
		const row = rows[i];
		const columns = row.querySelectorAll('td');

		for (let j = 0; j < columns.length; j++) {
			const header = headers[j];
			const column = columns[j];
			textLines.push(`${header.innerText.toUpperCase()}: ${column.innerText.trim()}\n`);
		}

		textLines.push('\n');
	}
	const tableToString = textLines.join('');
	return tableToString;
};
