import {
	addMemberToTeam,
	addNonSignedUpMemberToTeam,
	removeMemberFromTeam,
	removeMemberInviteFromTeam,
	updateCompanyName,
} from '../../lib/apiRequests';
import { finishLoading, showAlert, showNonBlockingMessage } from '../../lib/ui/uiUtils';
import { createUserSearch } from '../../lib/ui/inviteUser';

const teamSelect = document.querySelector('#team-select');
teamSelect?.addEventListener('change', (e) => {
	console.log(e.target);
	window.location.href = window.location.pathname + `?id=${(e.target as HTMLSelectElement).value}`;
});

document.querySelector('.save-team-name')?.addEventListener('click', () => {
	const newName = document.querySelector<HTMLInputElement>('input.company-name')!.value;
	updateCompanyName(document.body.dataset.companyId!, newName).then(() => {
		showNonBlockingMessage(`Saved team name: ${newName}`, undefined, true);
	});
});

document.querySelector('.add-member')?.addEventListener('click', () => {
	const invitePanel = document.querySelector('#invite-container')!;
	invitePanel.classList.remove('hidden');

	const userSearchInput = document.querySelector<HTMLInputElement>('#user-search-input')!;
	createUserSearch(userSearchInput);

	const userInviteButton = document.querySelector<HTMLButtonElement>(
		'#invite-container button[type="submit"]'
	)!;
	userInviteButton.addEventListener('click', () => {
		const companyId = document.body.dataset.companyId!;

		userSearchInput.disabled = true;
		const userQuery = userSearchInput.value;
		const isEmail = userQuery.includes('@');
		const addMemberPromise = isEmail
			? addNonSignedUpMemberToTeam(companyId, userQuery)
			: addMemberToTeam(companyId, userQuery);
		addMemberPromise.then(({ success, message }) => {
			if (success) {
				window.location.reload();
			} else {
				const inviteResult = document.querySelector<HTMLDivElement>('.invite-result')!;
				inviteResult.innerText = message;
				inviteResult.classList.remove('success');
				inviteResult.classList.remove('copied');
				inviteResult.classList.add('warning');
			}
		});
	});
});

document.querySelectorAll<HTMLButtonElement>('.remove-member').forEach((removeButton) => {
	removeButton.addEventListener('click', () => {
		const companyId = document.body.dataset.companyId!;
		const { username, userId, inviteId, email } = removeButton.dataset;
		showAlert(
			`Are you sure you want to remove <em>${username || email || ''}</em> from the team?`,
			[
				{
					label: 'Cancel',
				},
				{
					primary: true,
					label: 'Remove',
					onClick: () => {
						if (inviteId) {
							removeMemberInviteFromTeam(companyId, inviteId).then(() => {
								window.location.reload();
							});
						} else {
							removeMemberFromTeam(companyId, userId!).then(() => {
								window.location.reload();
							});
						}
					},
				},
			]
		);
	});
});

finishLoading();
