import Graph from './graphs/Graph';
import { UserFieldName } from '../types';
import * as sharedb from 'sharedb/lib/client';
import {
	BoardUser,
	EngineId,
	NCCluster,
	NCGraphData,
	NCGroupMinimal,
	NCLink,
	NCNode,
} from '../../../src/commonTypes';
import { updatedBoardEngine, updatedBoardTitle } from './ui/uiUtils';
import { ListReplaceOp } from 'sharedb';
import { getGroupsInvolvingNode } from './graphs/groupUtils';
import { MIN_MOVE_DISTANCE } from './graphs/dragUtils';

const debugLog = (messageFn: () => string) => {
	if (window.location.hostname === 'localhost') {
		console.log(`%c[ShareDBListener] ${messageFn()}`, 'color: #00f');
	}
};

const hasNodeMoved = (oldNode: NCNode | undefined, newNode: NCNode): boolean => {
	if (!oldNode) {
		// New node, so we consider it moved
		return true;
	}
	const moveDistance = Math.hypot(newNode.x - oldNode.x, newNode.y - oldNode.y);
	if (oldNode.label && newNode.label && oldNode.label !== newNode.label) {
		// When label was updated the user is probably typing, so allow for larger move distance without triggering physics reset
		return moveDistance > MIN_MOVE_DISTANCE * 2;
	}
	return moveDistance > MIN_MOVE_DISTANCE;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function diff<T extends Record<string, any>>(obj1: T, obj2: T) {
	const result: Record<string, T> = {};
	if (Object.is(obj1, obj2)) {
		return undefined;
	}
	if (!obj2 || typeof obj2 !== 'object') {
		return obj2;
	}
	Object.keys(obj1 || {})
		.concat(Object.keys(obj2 || {}))
		.forEach((key) => {
			if (obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				result[key] = obj2[key];
			}
			if (typeof obj2[key] === 'object' && typeof obj1[key] === 'object') {
				const value = diff(obj1[key], obj2[key]);
				if (value !== undefined) {
					// @ts-expect-error - this works
					result[key] = value;
				}
			}
		});
	return result;
}

const getNodeDiff = (op: ListReplaceOp): string => {
	const oldNode = op.ld as NCNode | undefined;
	const newNode = op.li as NCNode;
	if (oldNode) {
		return JSON.stringify({ uid: newNode.uid, ...diff(oldNode, newNode) });
	}
	return JSON.stringify(newNode);
};

export const makeOpListener = (
	graph: Graph,
	userFieldName: UserFieldName,
	isCollaborative: boolean
) => {
	return (ops: Array<sharedb.ObjectReplaceOp | sharedb.ListReplaceOp>): void => {
		const graphData = graph.graphData;
		let hasGraphDataChanged = false;
		let resetPhysics = false;

		const isPersonalPathValid = (personalPath: string | number): boolean => {
			if (graph.graphMode === 'ideate') {
				return isCollaborative ? personalPath === `ideateTeam` : personalPath === `ideate`;
			} else {
				return personalPath === graph.graphMode;
			}
		};

		const isDataPathForCurrentGraph = (path: sharedb.Path): boolean => {
			return isCollaborative && graph.graphMode !== 'ideate'
				? path[0] === graph.graphMode
				: path[0] === userFieldName && isPersonalPathValid(path[1]);
		};

		const unlockGroupsContainingNode = (node: NCNode) => {
			const newGroups: NCGroupMinimal[] = [...(graphData.groups || [])];
			const changedGroups = getGroupsInvolvingNode(node, newGroups);
			// Unlock groups that contain the node
			changedGroups.forEach((g) => {
				g.lock = false;
			});
			graphData.groups = newGroups;
		};

		for (const theOp of ops) {
			if (theOp && theOp.p) {
				const path = theOp.p;

				if ((theOp as sharedb.ListReplaceOp).li) {
					const listReplace = theOp as sharedb.ListReplaceOp;
					const index = path.at(-1) as number;
					if (path.length === 2 && path[0] === 'users') {
						// Insert or replace user
						const newBoardUsers = [...graph.boardUsers];
						newBoardUsers.splice(index, listReplace.ld ? 1 : 0, listReplace.li as BoardUser);
						debugLog(() => `Update user ${JSON.stringify(listReplace.li)} at index: ${index}`);
						graph.loadNewUserData(newBoardUsers);
					} else if (
						path.length > 3 &&
						path.at(-3) === 'data' &&
						path.at(-2) === 'nodes' &&
						isDataPathForCurrentGraph(path)
					) {
						const newNodes = [...graphData.nodes];
						debugLog(
							() =>
								`${listReplace.ld ? 'Update' : 'Add'} node ${getNodeDiff(listReplace)} at index: ${index}`
						);
						newNodes.splice(index, listReplace.ld ? 1 : 0, listReplace.li as NCNode);
						hasGraphDataChanged = true;
						if (hasNodeMoved(listReplace.ld as NCNode | undefined, listReplace.li as NCNode)) {
							resetPhysics = true;
						}
						graphData.nodes = newNodes;
						unlockGroupsContainingNode(listReplace.li as NCNode);
					} else if (
						path.length > 3 &&
						path.at(-3) === 'data' &&
						path.at(-2) === 'links' &&
						isDataPathForCurrentGraph(path)
					) {
						const newLinks = [...graphData.links];
						debugLog(
							() =>
								`${listReplace.ld ? 'Update' : 'Add'} link ${JSON.stringify(listReplace.li)} at index: ${index}`
						);
						newLinks.splice(index, listReplace.ld ? 1 : 0, listReplace.li as NCLink);
						hasGraphDataChanged = true;
						resetPhysics = true;
						graphData.links = newLinks;
					} else if (
						path.length > 3 &&
						path.at(-3) === 'data' &&
						path.at(-2) === 'groups' &&
						isDataPathForCurrentGraph(path)
					) {
						const newGroups: NCGroupMinimal[] = [...(graphData.groups || [])];
						debugLog(
							() =>
								`${listReplace.ld ? 'Update' : 'Add'} group ${JSON.stringify(listReplace.li)} at index: ${index}`
						);
						newGroups.splice(index, listReplace.ld ? 1 : 0, {
							...listReplace.li,
							lock: false,
						} as NCGroupMinimal);
						hasGraphDataChanged = true;
						resetPhysics = true;
						graphData.groups = newGroups;
					} else if (
						path.length > 3 &&
						path.at(-3) === 'data' &&
						path.at(-2) === 'clusters' &&
						isDataPathForCurrentGraph(path)
					) {
						const newClusters: Array<NCCluster> = [...(graphData.clusters || [])];
						debugLog(
							() =>
								`${listReplace.ld ? 'Update' : 'Add'} cluster ${JSON.stringify(listReplace.li)} at index: ${index}`
						);
						newClusters.splice(index, listReplace.ld ? 1 : 0, listReplace.li as NCCluster);
						hasGraphDataChanged = true;
						graphData.clusters = newClusters;
					} else {
						console.log(
							`Unhandled sharedb ${
								listReplace.ld ? 'ListReplaceOp' : 'ListInsertOp'
							} update for path: ${path.join(' -> ')}`
						);
					}
				} else if ((theOp as sharedb.ListDeleteOp).ld) {
					const listDelete = theOp as sharedb.ListDeleteOp;
					const index = path.at(-1) as number;

					if (path.length === 2 && path[0] === 'users') {
						// Delete user
						const newBoardUsers = [...graph.boardUsers];
						newBoardUsers.splice(index, 1);
						debugLog(() => `Deleting user ${JSON.stringify(listDelete.ld)} at index: ${index}`);
						graph.loadNewUserData(newBoardUsers);
					} else if (
						path.length > 3 &&
						path.at(-3) === 'data' &&
						path.at(-2) === 'nodes' &&
						isDataPathForCurrentGraph(path)
					) {
						const newNodes = [...graphData.nodes];
						debugLog(() => `Deleting node ${JSON.stringify(listDelete.ld)} at index: ${index}`);
						newNodes.splice(index, 1);
						hasGraphDataChanged = true;
						resetPhysics = true;
						graphData.nodes = newNodes;
						unlockGroupsContainingNode(listDelete.ld as NCNode);
					} else if (
						path.length > 3 &&
						path.at(-3) === 'data' &&
						path.at(-2) === 'links' &&
						isDataPathForCurrentGraph(path)
					) {
						const newLinks = [...graphData.links];
						debugLog(() => `Deleting link ${JSON.stringify(listDelete.ld)} at index: ${index}`);
						newLinks.splice(index, 1);
						hasGraphDataChanged = true;
						resetPhysics = true;
						graphData.links = newLinks;
					} else if (
						path.length > 3 &&
						path.at(-3) === 'data' &&
						path.at(-2) === 'groups' &&
						isDataPathForCurrentGraph(path)
					) {
						const newGroups = [...graph.nodeGroups];
						debugLog(() => `Deleting group ${JSON.stringify(listDelete.ld)} at index: ${index}`);
						newGroups.splice(index, 1);
						hasGraphDataChanged = true;
						resetPhysics = true;
						graphData.groups = newGroups;
					} else if (
						path.length > 3 &&
						path.at(-3) === 'data' &&
						path.at(-2) === 'clusters' &&
						isDataPathForCurrentGraph(path)
					) {
						const newClusters = [...(graphData.clusters || [])];
						debugLog(() => `Deleting cluster ${JSON.stringify(listDelete.ld)} at index: ${index}`);
						newClusters.splice(index, 1);
						hasGraphDataChanged = true;
						graphData.clusters = newClusters;
					} else {
						console.log(`Unhandled sharedb ListDeleteOp update for path: ${path.join(' -> ')}`);
					}
				} else if ((theOp as sharedb.ObjectReplaceOp).oi) {
					// Replace graph data
					const objectReplace = theOp as sharedb.ObjectReplaceOp;

					if (
						path.length === 3 &&
						path.at(-2) === 'data' &&
						path.at(-1) === 'groups' &&
						isDataPathForCurrentGraph(path)
					) {
						// Adding groups array (not necessary to update the graph, just a data structure update)
					} else if (
						path.length === 3 &&
						path.at(-2) === 'data' &&
						path.at(-1) === 'clusters' &&
						isDataPathForCurrentGraph(path)
					) {
						// Adding clusters array (not necessary to update the graph, just a data structure update)
					} else if (path.at(-1) === 'data' && isDataPathForCurrentGraph(path)) {
						debugLog(() => `Updating all graph data: ${path.join(' -> ')}`);
						graph.loadNewGraphData(objectReplace.oi as NCGraphData);
					} else if (path.length === 1 && path[0] === 'title') {
						debugLog(() => `Updating board title: ${objectReplace.oi}`);
						updatedBoardTitle(objectReplace.oi as string);
					} else if (path.length === 1 && path[0] === 'engine') {
						debugLog(() => `Updating board engine: ${objectReplace.oi}`);
						updatedBoardEngine(objectReplace.oi as EngineId);
					} else if (path.length === 1 && path[0] === 'users') {
						debugLog(() => `Updating all users: ${JSON.stringify(objectReplace.oi)}`);
						// replace all users (should no longer be used as this can lead to clashes with other users' changes)
						graph.loadNewUserData(objectReplace.oi as Array<BoardUser>);
					} else {
						console.log(
							`Unhandled sharedb ${
								objectReplace.od ? 'ObjectReplaceOp' : 'ObjectInsertOp'
							} update for path: ${path.join(' -> ')}`
						);
					}
				}
			}
		}

		if (hasGraphDataChanged) {
			graph.loadNewGraphData(graphData, null, resetPhysics);
		}
	};
};
