import {
	LikedItem,
	PresentationInfo,
	SelectableItem,
	TargetAudience,
} from '../../../../../src/commonTypes';
import {
	createAutogeneratedLabel,
	createConceptualTerritoryLabel,
	createDetails,
	createLabel,
	createPreviewParagraph,
	createSelectableCheckbox,
	getConceptualTerritoryResultsOfType,
	getUncachedItems,
} from './utils';

export const appendTargetAudienceInfo = (
	element: HTMLElement,
	audience: TargetAudience,
	includeName = false
) => {
	if (includeName) {
		element.append(createLabel('Name'));
		element.append(createPreviewParagraph(audience.name));
	}

	element.append(createLabel('Profession'));
	element.append(createPreviewParagraph(audience.profession));

	element.append(createLabel('Age'));
	element.append(createPreviewParagraph(audience.age));

	element.append(createLabel('Needs'));
	element.append(createPreviewParagraph(audience.needs));

	element.append(createLabel('Motivations'));
	element.append(createPreviewParagraph(audience.motivations));
};

function getTargetAudience(
	audience: TargetAudience,
	targetAudience: SelectableItem<TargetAudience | LikedItem>,
	presentation: PresentationInfo,
	conceptualTerritorySummary?: string
) {
	const [audienceDetails, audienceSummary] = createDetails(`Target audience: ${audience.name}`);

	const audienceCheckbox = createSelectableCheckbox(targetAudience, presentation)[0];
	audienceSummary.prepend(audienceCheckbox);

	if (conceptualTerritorySummary) {
		audienceSummary.append(createConceptualTerritoryLabel(conceptualTerritorySummary));
	} else if (targetAudience.autogenerated) {
		audienceSummary.append(createAutogeneratedLabel());
	}

	appendTargetAudienceInfo(audienceDetails, audience);

	return audienceDetails;
}

export const getTargetAudiencesSlideContent = (presentation: PresentationInfo) => {
	const targetAudiencesContent = document.createElement('div');

	const introP = document.createElement('p');
	introP.innerHTML = presentation.vision
		? '<small>Some target audiences were <strong class="inspire-key">autogenerated</strong> from your project vision. You can create more from your own <strong class="synthesise-key">concepts</strong> in <strong>Synthesise</strong>.</small>'
		: `<small>You can create target audiences in <strong>Synthesise</strong>.</small>`;
	targetAudiencesContent.append(introP);

	getUncachedItems(presentation.targetAudiences).forEach((targetAudience) => {
		targetAudiencesContent.append(
			getTargetAudience(targetAudience.value, targetAudience, presentation)
		);
	});
	getConceptualTerritoryResultsOfType(presentation, 'audience').forEach(([concept, result]) => {
		const audience = JSON.parse(result.value.text!) as TargetAudience;
		targetAudiencesContent.append(
			getTargetAudience(audience, result, presentation, concept.value.summary)
		);
	});

	return targetAudiencesContent;
};
