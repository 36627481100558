import { NCGroup, NCGroupMinimal, NCNode } from '../../../../src/commonTypes';
import { getCentralPoint, isPointInEllipse } from './geometryUtils';
import { ADDITIONAL_GROUP_RADIUS, getRadiusX, getRadiusY } from './graphGroupUtils';

export const getGroupsInvolvingNode = <T extends NCGroup | NCGroupMinimal>(
	node: NCNode,
	groups: T[]
): T[] => {
	return groups.filter((g) => {
		return g.nodes.some((n) => n.uid === node.uid);
	});
};

export const getGroupsWhereNodeIsOnlyNode = (node: NCNode, groups: NCGroup[]): NCGroup[] => {
	return groups.filter((g) => {
		return g.nodes.length === 1 && g.nodes[0].uid === node.uid;
	});
};

export const minimizeGroups = (groups: NCGroup[]): NCGroupMinimal[] => {
	return groups.map((g) => {
		return {
			uid: g.uid,
			nodes: g.nodes.map((n) => {
				return { uid: n.uid };
			}),
			colour: g.colour,
			overrideColour: g.overrideColour,
			active: g.active,
			lock: g.lock,
		};
	});
};

export const getNodesWithinGroupArea = (group: NCGroup, nodes: NCNode[]): NCNode[] => {
	const centralPoint = getCentralPoint(group.nodes);
	const radiusX = getRadiusX(group) + 1.5 * ADDITIONAL_GROUP_RADIUS;
	const radiusY = getRadiusY(group) + 1.5 * ADDITIONAL_GROUP_RADIUS;

	return nodes.filter((node) => {
		return isPointInEllipse(node, centralPoint, radiusX, radiusY);
	});
};
