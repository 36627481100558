import { AvailableColour, MergedNode, NCLink, NCNode } from '../../src/commonTypes';
import { generateNodeId } from './lib/graphs/graphNodeUtils';
import { getCentralPoint } from './lib/graphs/geometryUtils';
import {
	dedupeLinks,
	getLinksInvolvingNode,
	isNodeInLink,
	isSameLink,
	isSameLinkUnidirectional,
} from './lib/graphs/linkUtils';
import { showNonBlockingMessage } from './lib/ui/uiUtils';
import { splitWords } from '../../src/commonMisc';
import { splitTextIntoWords } from './lib/apiRequests';

const getMergedNode = (
	mergedNodes: Array<MergedNode> | undefined,
	label: string
): MergedNode | undefined => {
	if (mergedNodes) {
		for (const mergedNode of mergedNodes) {
			const previouslyMergedNode = getMergedNode(mergedNode.mergedFrom, label);
			if (previouslyMergedNode) {
				return previouslyMergedNode;
			}
		}
	}
	return mergedNodes?.find((n) => n.label === label);
};

// Get any links that were connected prior to merging
const getRevivedLinks = (
	node: NCNode,
	mergedNode: MergedNode | undefined,
	allNodes: Array<NCNode>
): Array<NCLink> => {
	const links: Array<NCLink> = [];
	if (mergedNode?.linkedNodeIds) {
		mergedNode.linkedNodeIds.forEach((linkedNodeId) => {
			const linkedNode = allNodes.find((n) => n.uid === linkedNodeId);
			if (linkedNode) {
				links.push({
					source: node,
					target: linkedNode,
					colour: node.colour,
				});
			}
		});
	}
	return links;
};

/**
 * Splits node, returning changes to nodes and links
 */
export const splitNode = async (
	node: NCNode,
	allLinks: Array<NCLink>,
	allNodes: Array<NCNode>,
	userColourClass: AvailableColour,
	force = false,
	splitKeyPhrases = false
): Promise<{
	nodesToUpdate: NCNode[];
	nodesToAdd: NCNode[];
	nodesToDelete: NCNode[];
	linksToAdd: NCLink[];
	linksToDelete: NCLink[];
}> => {
	const returnObj = {
		nodesToUpdate: [] as NCNode[],
		nodesToAdd: [] as NCNode[],
		nodesToDelete: [] as NCNode[],
		linksToAdd: [] as NCLink[],
		linksToDelete: [] as NCLink[],
	};

	if (node.label && (!node.merged || force)) {
		const words = splitKeyPhrases
			? await splitTextIntoWords(document.body.dataset.boardId!, node.label, 'topics')
			: splitWords(node.label);
		const linksInvolvingNode = getLinksInvolvingNode(node, allLinks);
		if (words.length === 0) {
			// No words exist after removing stopwords, so delete the node:
			showNonBlockingMessage(
				'Removed the node as it only contained commonplace words. <strong>Try to use nouns, verbs, or adjectives that convey meaning.</strong>',
				[],
				true
			);
			returnObj.nodesToDelete.push(node);
			returnObj.linksToDelete.push(...linksInvolvingNode);
			return returnObj;
		} else {
			// Keep the existing node and use for the first word:
			const replacementNode: NCNode = { ...node };
			const firstWord = words.shift();
			const mergedFrom = node.mergedFrom;
			const mergedNode = firstWord ? getMergedNode(mergedFrom, firstWord) : undefined;
			Object.assign(replacementNode, {
				label: firstWord,
				likes: firstWord === node.label ? node.likes : {},
				nodeColour: mergedNode?.nodeColour,
			});
			delete replacementNode.merged;
			delete replacementNode.mergedFrom;
			returnObj.nodesToUpdate.push(replacementNode);

			const revivedLinks: Array<NCLink> = getRevivedLinks(replacementNode, mergedNode, allNodes);

			// Create an additional node for each word after the first:
			const additionalNodes = words.map((w, i): NCNode => {
				const { x, y } = node;
				const mergedNode = w ? getMergedNode(mergedFrom, w) : undefined;
				//const { x, y } = linkedNodes.length > 0 ? getCentralPoint(linkedNodes) : node;
				const n = {
					...node,
					uid: generateNodeId(),
					created: new Date().getTime(),
					x:
						x +
						(words.length === 1
							? 50 + Math.random()
							: -words.length * words.length + words.length * (2 * (i + 0.5))),
					y: y + (words.length === 1 ? 0 : (i + 1) * (1 + ((i + 1) % 2)) + Math.random()),
					label: w,
					nodeColour: mergedNode?.nodeColour,
					likes: {},
				};
				revivedLinks.push(...getRevivedLinks(n, mergedNode, allNodes));
				delete n.merged;
				delete n.mergedFrom;
				delete n.fx;
				delete n.fy;
				return n;
			});
			returnObj.nodesToAdd.push(...additionalNodes);
			const replacementNodes = [replacementNode, ...additionalNodes];

			const replacementLinks: Array<NCLink> = [];
			// Link each of the new nodes to the first node
			additionalNodes.forEach((n) => {
				replacementLinks.push({
					source: replacementNode,
					target: n,
					colour: userColourClass,
				});
			});
			// Link other nodes to each other only if there is a memory of them being connected previously:
			replacementLinks.push(
				...linksInvolvingNode.flatMap((l) => {
					const otherNode = l.source.uid === node.uid ? l.target : l.source;
					const otherNodeLabels = otherNode.parent?.label
						? splitWords(otherNode.parent?.label)
						: [];
					const links: Array<NCLink> = [];

					// We must reuse the link object if it will still exist between the same nodes
					const indexOfLink = revivedLinks.findIndex((l2) => isSameLink(l, l2));
					if (indexOfLink !== -1) {
						links.push(l);
						revivedLinks.splice(indexOfLink, 1);
					}

					let nodesToLink = [] as NCNode[];
					const hasMatchingParent = replacementNodes.some(
						(n) => n.label && otherNode.parent?.label && otherNodeLabels.includes(n.label)
					);
					if (hasMatchingParent) {
						nodesToLink.push(...additionalNodes);
						// Only link to the node matching the label
						if (otherNode.parent?.label && node.label) {
							if (otherNodeLabels.includes(node.label)) {
								links.push(l);
							}
							nodesToLink = additionalNodes.filter(
								(n) => n.label && otherNodeLabels.includes(n.label)
							);
							if (otherNodeLabels.length === 2) {
								// If node is connected to two other nodes, place it between them
								otherNode.x = getCentralPoint(additionalNodes).x;
							}
						} else {
							// Don't link any nodes
							nodesToLink = [];
						}
					} else if (replacementLinks.length === 0) {
						links.push(l);
					}

					links.push(
						...nodesToLink.map((n) => ({ source: n, target: otherNode, colour: userColourClass }))
					);
					return links;
				})
			);

			replacementLinks.push(...revivedLinks);

			const dedupedLinks = dedupeLinks(replacementLinks).filter(
				(l) => !allLinks.some((l2) => isSameLink(l, l2))
			);

			returnObj.linksToAdd.push(...dedupedLinks);
		}
	}
	return returnObj;
};

export const splitNodes = async (
	nodeIdsToSplit: Array<string>,
	allNodes: Array<NCNode>,
	allLinks: Array<NCLink>,
	userColourClass: AvailableColour,
	force: boolean,
	splitKeyPhrases: boolean
) => {
	const returnObj = {
		nodesToUpdate: [] as NCNode[],
		nodesToAdd: [] as NCNode[],
		nodesToDelete: [] as NCNode[],
		linksToAdd: [] as NCLink[],
		linksToDelete: [] as NCLink[],
	};

	const replacementNodes: Array<NCNode> = [];
	const replacementLinks: Array<NCLink> = [];
	for (const n of allNodes) {
		if (nodeIdsToSplit.includes(n.uid)) {
			// Determine the current state of nodes and links so that next split will take changes into account
			const allNodesNew = [...replacementNodes];
			allNodesNew.push(...allNodes.filter((n) => !allNodesNew.some((n2) => n.uid === n2.uid)));
			const allLinksNew = [...replacementLinks];
			allLinksNew.push(
				...allLinks.filter((l) => !allLinksNew.some((l2) => isSameLinkUnidirectional(l, l2)))
			);

			const { nodesToAdd, nodesToUpdate, nodesToDelete, linksToAdd, linksToDelete } =
				await splitNode(n, allLinksNew, allNodesNew, userColourClass, force, splitKeyPhrases);

			returnObj.nodesToAdd.push(...nodesToAdd);
			returnObj.nodesToUpdate.push(...nodesToUpdate);
			returnObj.nodesToDelete.push(...nodesToDelete);
			returnObj.linksToAdd.push(...linksToAdd);
			returnObj.linksToDelete.push(...linksToDelete);

			replacementNodes.push(...nodesToAdd, ...nodesToUpdate);
			replacementLinks.push(...linksToAdd);
		}
	}

	return returnObj;
};

const getLinksForNodeBeingMerged = (
	node: NCNode,
	otherNode: NCNode,
	allLinks: Array<NCLink>
): Array<NCLink> => {
	return (
		allLinks
			.filter((l) => isNodeInLink(node, l))
			// If other node not already connected to first node (or first node itself)
			.filter((l) => {
				const linkedNode = l.source.uid === node.uid ? l.target : l.source;
				if (linkedNode.uid === otherNode.uid) {
					// This is a link to the first node, ignore it
					return false;
				}
				return allLinks
					.filter((l) => isNodeInLink(linkedNode, l))
					.some((l) => l.source.uid !== otherNode.uid && l.target.uid !== otherNode.uid);
			})
	);
};

const getOtherNodeIds = (node: NCNode, links: Array<NCLink>): Array<string> => {
	return links.map((l) => (l.source.uid === node.uid ? l.target.uid : l.source.uid));
};

/** @deprecated */
export const mergeNodes = (
	mergeFirstNode: NCNode,
	mergeSecondNode: NCNode,
	allLinks: Array<NCLink>,
	userColourClass: AvailableColour
) => {
	const returnObj = {
		nodesToUpdate: [] as NCNode[],
		nodesToDelete: [] as NCNode[],
		linksToAdd: [] as NCLink[],
		linksToDelete: [] as NCLink[],
	};

	const mergedFrom = [] as Array<MergedNode>;
	const mergeFirstNodeLinks = getLinksForNodeBeingMerged(mergeFirstNode, mergeSecondNode, allLinks);
	const mergeSecondNodeLinks = getLinksForNodeBeingMerged(
		mergeSecondNode,
		mergeFirstNode,
		allLinks
	);
	mergedFrom.push({
		label: mergeFirstNode.label,
		nodeColour: mergeFirstNode.nodeColour,
		mergedFrom: mergeFirstNode.mergedFrom,
		linkedNodeIds: getOtherNodeIds(mergeFirstNode, mergeFirstNodeLinks),
	});
	mergedFrom.push({
		label: mergeSecondNode.label,
		nodeColour: mergeSecondNode.nodeColour,
		mergedFrom: mergeSecondNode.mergedFrom,
		linkedNodeIds: getOtherNodeIds(mergeSecondNode, mergeSecondNodeLinks),
	});
	mergeFirstNode.mergedFrom = mergedFrom;

	// Combine label for these nodes (and set as the first node label)
	mergeFirstNode.label = `${mergeFirstNode.label || ''} ${mergeSecondNode.label || ''}`.trim();
	mergeFirstNode.merged = true;

	returnObj.nodesToUpdate.push(mergeFirstNode);
	returnObj.nodesToDelete.push(mergeSecondNode);

	// Delete all links involving the second node
	returnObj.linksToDelete.push(...getLinksInvolvingNode(mergeSecondNode, allLinks));
	// Ensure that links involving the second node are moved to the first node
	returnObj.linksToAdd.push(
		...mergeSecondNodeLinks
			.map((l) => ({
				source: l.source.uid === mergeSecondNode.uid ? mergeFirstNode : l.source,
				target: l.target.uid === mergeSecondNode.uid ? mergeFirstNode : l.target,
				colour: userColourClass,
			}))
			// But do not add any links that are already present on the first node
			.filter((l) => mergeFirstNodeLinks.every((l2) => !isSameLink(l, l2)))
	);

	return returnObj;
};
