import { EmpathiseColour, EngineId } from '../../../../src/commonTypes';
import { removeChildren } from './uiUtils';
import { resizeLongSuggestionsToFit } from './toolsUI';

const ENGINE_FILTERS: Record<EngineId, Array<{ key: string; label: string }>> = {
	Creative: [
		{ key: 'Filter0', label: 'None' },
		{ key: 'Filter1', label: 'Advertising' },
		{ key: 'Filter2', label: 'Brand' },
		{ key: 'Filter3', label: 'Media' },
	],
	Games: [
		{ key: 'Filter0', label: 'None' },
		{ key: 'Filter1', label: 'Mechanics' },
		{ key: 'Filter2', label: 'Interaction' },
	],
	Innovative: [
		{ key: 'Filter0', label: 'Product Design' },
		{ key: 'Filter1', label: 'Values' },
		{ key: 'Filter2', label: 'Customer Strategy' },
		{ key: 'Filter3', label: 'Innovation' },
		{ key: 'Filter4', label: 'Ethics' },
	],
};

export const setupEmpathiseResults = (
	addSingleNode: (word: string, colour: EmpathiseColour) => void,
	results: Array<{ color: EmpathiseColour; values: string[] }>
) => {
	const container = document.querySelector('#empathise-container')!;
	const loadingIndicator = container.querySelector('.loading')!;

	loadingIndicator.classList.add('hidden');

	const lateralThinkingSlider = container.querySelector<HTMLInputElement>("input[type='range']")!;

	showEmpathiseResults(addSingleNode, results, parseInt(lateralThinkingSlider.value));

	lateralThinkingSlider.oninput = () => {
		showEmpathiseResults(addSingleNode, results, parseInt(lateralThinkingSlider.value));
	};
};

const getWordLi = (
	colour: EmpathiseColour,
	addSingleNode: (word: string, colour: EmpathiseColour) => void,
	word: string,
	empathiseResults: Array<{ color: EmpathiseColour; values: string[] }>
) => {
	const wordLi = document.createElement('li');
	wordLi.innerHTML = word;
	wordLi.className = colour;
	wordLi.dataset.word = word;
	wordLi.onclick = reviseEmpathiseSuggestionsOnClick(
		wordLi,
		addSingleNode,
		word,
		colour,
		empathiseResults
	);
	return wordLi;
};

const showEmpathiseResults = (
	addSingleNode: (word: string, colour: EmpathiseColour) => void,
	empathiseResults: Array<{ color: EmpathiseColour; values: string[] }>,
	empathiseSliderValue: number
) => {
	const pairsList = document.querySelector('#empathise-container .pairs-list')!;
	removeChildren(pairsList); // remove any earlier pairs

	if (!empathiseResults.some(({ values }) => values.length)) {
		pairsList.innerHTML = '<p><em>No results</em></p>';
	}

	for (const results of empathiseResults) {
		const { color, values } = results;

		const index = Math.floor((values.length - 2) * (empathiseSliderValue / 100));
		const selectionOfValues = values.slice(index, index + 2);

		if (selectionOfValues.length > 0) {
			pairsList.append(getWordLi(color, addSingleNode, selectionOfValues[0], empathiseResults));

			if (selectionOfValues.length > 1) {
				pairsList.append(getWordLi(color, addSingleNode, selectionOfValues[1], empathiseResults));
			}
		}
	}
	resizeLongSuggestionsToFit();
};

const reviseEmpathiseSuggestionsOnClick = (
	wordLi: HTMLLIElement,
	addSingleNode: (word: string, colour: EmpathiseColour) => void,
	word: string,
	colour: EmpathiseColour,
	empathiseResults: Array<{ color: EmpathiseColour; values: string[] }>
) => {
	return () => {
		addSingleNode(word, colour);

		const empathiseWordsForColour = empathiseResults.find((results) => results.color === colour)!;
		// Remove the placed word from the list
		const indexOfWord = empathiseWordsForColour.values.indexOf(word);
		empathiseWordsForColour.values.splice(indexOfWord, 1);

		let countAddedWords = 0;
		const targetNumberOfWords = 2;
		const wordsThatCouldBeAdded = [...empathiseWordsForColour.values];
		for (const w of wordsThatCouldBeAdded) {
			const currentSuggestions = Array.from(
				document.querySelectorAll<HTMLLIElement>('.pairs-list li')
			);
			if (!currentSuggestions.some((li) => li.innerText === w)) {
				wordLi.after(getWordLi(colour, addSingleNode, w, empathiseResults));
				countAddedWords += 1;
				if (countAddedWords === targetNumberOfWords) {
					break;
				}
			}
		}
		wordLi.remove();
	};
};

export const resetEmpathise = (fetchEmpathiseResults: () => Promise<void>) => {
	const container = document.querySelector('#empathise-container')!;
	const loadingIndicator = container.querySelector('.loading')!;
	const pairsList = container.querySelector('.pairs-list')!;
	const filterSelect = container.querySelector<HTMLSelectElement>('.filter')!;

	removeChildren(pairsList); // remove any earlier pairs
	removeChildren(filterSelect);

	const { engine } = document.body.dataset;
	const filterOptions = ENGINE_FILTERS[engine as EngineId];
	if (!filterOptions) {
		alert('Error: unknown engine type used for this board. Please contact us');
	}
	filterSelect.append(
		...filterOptions.map((filter) => {
			const selectOption = document.createElement('option');
			selectOption.value = filter.key;
			selectOption.innerText = filter.label;
			return selectOption;
		})
	);
	filterSelect.onchange = () => {
		fetchEmpathiseResults();
	};

	loadingIndicator.classList.remove('hidden');
};
